import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';


import Form, { Item, Label, GroupItem } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { AutoType, Records, Sto } from '~/api';
import { TireBrand } from '~/api/tirebrand';
import { Vehicle } from '~/api/vehicle';
import { mileageAdorment, priceAdorment, validationRules } from '~/routes/Records/services';
import { useTranslation } from 'react-i18next';
import { LoadIndicator, ScrollView } from 'devextreme-react';
import { bemHelper } from '~/libs';
import './styles.scss'
import { AuthContext, PopupContext } from '~/context';
import { colSpanItem } from '../Edit/service';
import { useWindowSize } from '~/hooks/useWindowSize';
import { SelectBoxAutoTypeItem } from '../SelectBoxAutoTypeItem';
import SelectBox from 'devextreme-react/select-box';
import { LabelAutoTypeTemplate } from '../LabelAutoTypeTemplate';
import { Model } from '~/api/autotype';
import { tireBrandsDataSource } from '~/custom-data-sources';

type Props = {

}

export const AddRecordPopup: React.FC<Props> = memo(({}) => {

    const { t } = useTranslation('records');
    const cn = bemHelper('add-record-popup');

    const { popupState: {
            addPopup: {
                visible,
                cellData, 
                dataRecord,
                updateData,
                resign
            },
        },
        addPopupClose,
    } = useContext(PopupContext);

    let currentDate = new Date();
    const coeff = 1000 * 60 * 10;
    currentDate = new Date(Math.round(currentDate.getTime() / coeff) * coeff)


    const { authState } = useContext(AuthContext);
    const size = useWindowSize()
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ data, setData ] = useState<any>({
        dateReceiptStart:  currentDate,
        lineNumber: 1,
        stoId: authState.userData?.sto.id,
        dkAutoType: 1,
        noRegNumber: false,
    })

    const [models, setModels] = useState<any>([])
    const [brands, setBrands] = useState<any>([])
    const [form, setForm] = useState<any>(null)
    const [autoTypes, setAutoTypes] = useState<Model[]>([])
    const [ isDataChanged, setIsDataChanged ] = useState(false)

    const [ modelsDisabled, setModelsDisabled ] = useState(true)
    const [ individual, setIndividual ] = useState(true)
    const [ other, setOther ] = useState(false)

    const getPriorities = (tsLimit: number) => {
        const priorities = []
        for (let i = 1; i <= tsLimit; i++) {
            priorities.push({
                id: i,
                title: `Линия ${i}`,
              })
        }
    
        return priorities
      }

    const getModels = async (brand: string) => {
        const result = await Vehicle.controller.getModelsByBrand(brand);
        setModels(result)
    }
    const getBrands = async () => {
        const result = await Vehicle.controller.getAllBrands();
        setBrands(result)
    }

    const getAutoTypes = async () => {
        let result = await AutoType.controller.getAll()
        const newResult = result.map(i => {
            return {
                ...i,
                displayName: `${i.name} (${i.vehicleType})`
            }
        })
        setAutoTypes(newResult)
    }

    const getPrice = async (value:number) => {
        const result: {
            dkAutoTypeId: number,
            dkAutoTypeName: string,
            price: number
        } = await Records.controller.getPriceSto({
            stoId: authState.userData?.sto.id,
            agentId: 0,
            dkAutoTypeId: value
        })
        return result.price
    }


    const handleSubmit = async () => {
        setIsLoading(true)
        if (isDataChanged){
            const editor = form.getEditor('clientPhone')
            const values = {
                ...data,
                individual: Number(!data.individual),
                priceClient: data.priceSto,
                clientPhone: data.clientPhone ? editor.option('text').replace(/\s/g, ""): ''
            }

            if(values.noRegNumber){
                values.regNumber = ''
            }

            delete values.noRegNumber
            delete values.date
            delete values.other

            if(data.individual){
                delete values.companyName
                delete values.inn
            }
            
            try{
    
                if(other){
                    const brandResult = await Vehicle.controller.createBrandAndModel({
                        brand: values.brand,
                        model: values.model
                    })
                }
                
                const result = await Records.controller.create(values)
                
                notify({
                    message: 'Запись успешно обновлена',
                    position: 'top center',
                    }, 'success', 3000);
                setData({
                    dkAutoType: 1,
                    dateReceiptStart:  currentDate,
                    lineNumber: 1,
                    stoId: authState.userData?.sto.id
                })

                if(updateData){
                    updateData()
                }
                setData({
                    dateReceiptStart:  currentDate,
                    lineNumber: 1,
                    stoId: authState.userData?.sto.id,
                    dkAutoType: 1,
                    noRegNumber: false,
                })
                addPopupClose()
    
            } catch(err: any) {
                const status = Number(err.message.replace('(','').split(')')[0])
                let message = `Упс... Произошла ошибка ${err.message}`

                if(status === 409){
                    message = `Время занято или указанно некорректно`
                }
 
                notify({
                    message: message,
                    position: 'top center',
                    }, 'error', 3000);
                
            } 
        } else {
            notify({
                message: `Заполните все поля правильно`,
                position: 'top center',
                }, 'error', 3000);
        }
        setIsLoading(false)
    }

    const handleChange = (e:any) => {
        setIsDataChanged(e.component.validate().isValid)
    }

    useEffect(() => {
        if(cellData){
            let cellDataStart = cellData.startDate;
            const  coeff = 1000 * 60 * 10;
            cellDataStart = new Date(Math.round(cellDataStart.getTime() / coeff) * coeff)
            setData({
                ...data,
                dateReceiptStart:  cellDataStart,
                lineNumber: cellData.groups.lineNumber,
            })
        }
    }, [cellData])

    useEffect(() => {
        if(dataRecord){
            if (dataRecord.brand){
                getModels(dataRecord.brand)
                setModelsDisabled(false)
            }

            setData({
                ...dataRecord,
                dkAutoType: dataRecord.dkAutoTypeId,
                clientPhone: dataRecord.clientPhone ? dataRecord.clientPhone.slice(1) : null,
                dateReceiptStart:  resign ? currentDate: dataRecord.dateReceiptStart,
                individual: !dataRecord.individual
            })
        }
    }, [dataRecord])
    

    useEffect(() => {

        getAutoTypes()
        getBrands()
        
    }, [])

    useEffect(() => {
        if (form && visible){
            const func  = async ()=>{
                form.updateData('priceSto', await getPrice(data.dkAutoType))
            }

            setTimeout(()=>{
                func()
            }, 500)
            
        }
    },[form, visible])

    const colCountByScreen = !cellData ? {
        lg: 3,
        sm: 3,
        xs: 1,
        md: 3,
    } : {
        lg: 2,
        sm: 2,
        xs: 1,
        md: 2,
    }

    const colCountClientGroup = {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    }

    const colCountAutoGroup = {
        lg: 4,
        md: 4,
        sm: 4,
        xs: 1,
    } 

    const EditorOptions = {

        makeButton: {
            text: "Записать",
            onClick: () => {
                handleSubmit()
            }
        },
        cancelButton: {
            text: "Отмена",
            onClick: () => {
                addPopupClose()
                setTimeout(() => {setData({
                    dkAutoType: 1,
                    dateReceiptStart:  currentDate,
                    lineNumber: 1,
                    stoId: authState.userData?.sto.id
                })}, 200)
            }
        },

        priceSto:{
            with: '100%',
            min: 0,
            placeholder: "Стоимость, рубли",
            buttons: [priceAdorment]
        },
        clientFio:{
            with: '100%',
            placeholder: 'Иванов Иван Иванович',

        },
        other:{
            text:"Иное",
            iconSize: 15,
            onValueChanged: (e: any) => {
                setOther(e.value)
            },
            value: other,

        },
        brand: {
            with: '100%',
            dataSource: brands,
            searchEnabled: true,
            value: data.brand,
            onValueChanged: (e:any) => {
                if(e.event){
                    setData({
                        ...data,
                        brand: e.value
                    })
                    getModels(e.value)
                    setModelsDisabled(false)
                    if(form){
                        form.updateData('model', null)
                    }
                }
                
            }
        },
        model: {
            
            with: '100%',
            dataSource: models,
            searchEnabled: true,
        },
        dkAutoType: {
            with: '100%',
            displayExpr: 'displayName',
            valueExpr: 'id',
            dataSource: autoTypes.filter(i => authState.userData?.sto.autoType.includes(i.id)),
            defaultValue: data.dkAutoType,
            itemRender: SelectBoxAutoTypeItem,
            dropDownOptions: {
                wrapperAttr :{
                    class: 'select-auto-type'
                }
            },
            onValueChanged: async ({value}: any) => {
                if (value){
                    form.updateData('dkAutoType', value)
                    form.updateData('priceSto', await getPrice(data.dkAutoType))
                }
            }
        },
        email: {
            placeholder: 'ivanov@mail.ru',
            with: '100%'
        },
        regNumber: {
            minLength: 8,
            maxLength: 9,
            disabled: data.noRegNumber,
            mask: 'XXXXXXXXK',
            maskRules: {
                X: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9]/,
                K: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9\s]/
            },
            maskChar: ' ',
            placeholder: 'А123МН78',
            useMaskedValue: true,
            showMaskMode: 'onFocus',
            maskInvalidMessage: "8 или 9 символов. Можно вводить только русские буквы (А,В,Е,К,М,Н,О,Р,С,Т,У,X) и цифры.",
            with: '100%',
        },
        noRegNumber:{
            text:"без номера",
            value: data.noRegNumber,
            iconSize: 15,
            onValueChanged: (e: any) => {
                const editor = form.getEditor('regNumber')
                editor.option('disabled', e.value)
                handleChange({component: form})
            },
        },
        phone: {
            with: '100%',
            maskChar: '_',
            mask: '+7 (X00) 000-00-00', 
            maskRules: { X: /[02-9]/ },
        },
        tireBrand: {
            with: '100%',
            displayExpr: 'name',
            valueExpr: 'id',
            dataSource: tireBrandsDataSource,
            searchEnabled: true,
        },
        mileage: {
            with: '100%',
            min: 0,
            max: 10000000,
            placeholder: "Пробег, км",
            buttons: [mileageAdorment]
        },
        lineNumber: {
            with: '100%',
            dataSource: getPriorities(authState.userData?.sto ? authState.userData?.sto.tsLimit : 4),
            displayExpr: 'title',
            deferRendering: false,
            valueExpr: 'id',
        },
        inn: {
            with: '100%',
        },
        companyName: {
            with: '100%',
        },
        individual: {
            value: !individual,
            onValueChanged: ({value}:any) => {
                setIndividual(!value)
            }
        },
        dateReceiptStart :{
            type: 'datetime',
            width: '100%',
            useMaskBehavior: true
        }
    }

    return (<>
            <Popup
                visible={visible}
                hideOnOutsideClick={true}
                hideOnParentScroll={true}
                dragEnabled={false}
                showTitle={false}
                onHiding={() => {addPopupClose()}}
                width={'100%'}
                maxWidth={800}
                wrapperAttr={{class: cn('')}}
                >
                <ScrollView width='100%' height='100%'>
                <form>
                    <Form
                        className={cn('form')}
                        onContentReady={(e:any) => e.component.validate().isValid}
                        onFieldDataChanged={handleChange}
                        formData={data}
                        onInitialized={(e:any ) => {setForm(e.component)}}
                        id="form-stations">

                        <GroupItem colCountByScreen={colCountClientGroup} caption={"Информация о клиенте"} cssClass='form-group form-group-individual-top'>

                            <Item 
                                dataField="individual" 
                                label={{
                                    text: `${t('entity')}:`,
                                    location: 'right'
                                }} 
                                colSpan={colSpanItem(size, colCountClientGroup)} 
                                cssClass="individual-switch"
                                editorOptions={EditorOptions.individual}
                                editorType="dxCheckBox"
                                />
                            <Item 
                                dataField="clientFio" 
                                label={{text: t('clientFio')}} 
                                editorOptions={EditorOptions.clientFio} 
                                validationRules={validationRules.clientFio}
                                />
                            
                            <Item 
                                dataField="clientPhone" 
                                label={{text: t('clientPhone')}} 
                                editorOptions={EditorOptions.phone} 
                                />
                            <Item 
                                dataField="clientEmail" 
                                label={{text: t('clientEmail')}} 
                                editorOptions={EditorOptions.email}
                                validationRules={validationRules.email}
                                />
                            {!individual && (
                                <Item 
                                    dataField="companyName" 
                                    label={{text: t('companyName')}} 
                                    editorOptions={EditorOptions.companyName} 
                                    isRequired
                                    />
                            )}
                            {!individual && (
                                <Item 
                                    dataField="inn" 
                                    label={{text: t('inn')}} 
                                    validationRules={validationRules.inn}
                                    editorOptions={EditorOptions.inn}
                                    />
                            )}
                        </GroupItem>
                        <GroupItem caption="Информация о ТС" colCountByScreen={colCountAutoGroup}  cssClass={'form-group'}>
                            {/* <Item 
                                dataField="brand" 
                                label={{text: t('brand')}} 
                                editorOptions={EditorOptions.brand} 
                                editorType="dxSelectBox"
                                cssClass='item-with-checkbox'
                                isRequired
                                >
                                <SelectBox
                                    disabled={other}
                                    isValid={!!data.brand}
                                    {...EditorOptions.brand}
                                    />
                                <CheckBox
                                    {...EditorOptions.other}
                                />
                            </Item> */}
                            { !other && (
                                <Item 
                                    dataField="brand" 
                                    colSpan={1}
                                    label={{text: t('brand')}} 
                                    editorOptions={EditorOptions.brand} 
                                    editorType="dxSelectBox"
        
                                    isRequired
                                    />
                            )}
                            { !other && (
                            <Item
                                dataField="model" 
                                disabled={modelsDisabled || other} 
                                label={{text: t('model')}} 
                                editorOptions={EditorOptions.model} 
                                editorType="dxSelectBox"
                                isRequired
                            />
                            )}
                            { other && (
                                <Item 
                                    dataField="brand" 
                                    label={{text: t('brand')}} 
                                    isRequired
                                    />
                            )}
                            { other && (
                                <Item
                                    dataField="model" 
                                    label={{text: t('model')}} 
                                    isRequired
                                />
                            )}

                            <Item 
                                dataField="other" 
                                colSpan={1}
                                cssClass={cn('checkbox-other')}
                                label={{visible: false}} 
                                editorOptions={EditorOptions.other} 
                                editorType="dxCheckBox"
                                />  

                            <Item
                                dataField="dkAutoType" 
                                isRequired
                            >
                                <Label render={() => <LabelAutoTypeTemplate dataField={'dkAutoType'} autoTypes={autoTypes}/>}/>
                                <SelectBox
                                    {...EditorOptions.dkAutoType}
                                    />
                            </Item>
                            <GroupItem
                                
                                colCount={3}
                                colSpan={4}
                                >
                            <GroupItem
                                cssClass={cn('group-regNumber')}
                                colCount={1}
                                colSpan={1}
                                >
                                <Item
                                    dataField="noRegNumber" 
                                    label={{visible: false}} 
                                    editorType="dxCheckBox"
                                    cssClass={cn('no-regNumber')}
                                    editorOptions={EditorOptions.noRegNumber}
                                />
                                <Item
                                    dataField="regNumber" 
                                    label={{text: t('regNumber')}} 
                                    editorOptions={EditorOptions.regNumber}
                                    isRequired
                                />
                            </GroupItem>
                            <Item
                                dataField="tireBrand" 
                                label={{text: t('tireBrand')}} 
                                editorOptions={EditorOptions.tireBrand} 
                                editorType="dxSelectBox"
                                />
                            <Item
                                dataField="mileage" 
                                label={{text: t('mileage')}} 
                                editorOptions={EditorOptions.mileage}
                                editorType="dxNumberBox"
                                />
                            </GroupItem>
                        </GroupItem>
                        <GroupItem 
                            colCountByScreen={colCountByScreen}
                            cssClass='form-group form-group-empty-caption'
                            >
                            <Item
                                dataField="priceSto" 
                                label={{text: t('price')}} 
                                editorOptions={EditorOptions.priceSto}
                                editorType="dxNumberBox"
                                />
                            <Item
                                dataField="dateReceiptStart" 
                                label={{text: t('dateReceiptStart')}} 
                                editorOptions={EditorOptions.dateReceiptStart}
                                editorType="dxDateBox"
                                isRequired
                                />
                            { !cellData && (
                                <Item 
                                    dataField="lineNumber" 
                                    label={{text: t('lineNumber')}} 
                                    editorType="dxSelectBox" 
                                    editorOptions={EditorOptions.lineNumber}
                                    visible={authState.userData?.sto.tsLimit > 1}
                                    isRequired
                                    />
                            )}
                        </GroupItem> 
                    </Form>
                </form>
                </ScrollView>
                
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    
                    options={EditorOptions.makeButton}
                    />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={EditorOptions.cancelButton}
                    />
            </Popup>

            { isLoading && (
                <div className={cn('loader-box')}>
                    <div className={cn('loader')}>
                        <LoadIndicator />
                    </div>
                </div>
            )}
        </>
    )
})