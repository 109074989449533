import { Common } from '~/types';
import { ky } from '~/api/kyInstance';

export type IndividualModel = {
    id?: number,
    firstname?: string,
    lastname?: string,
    fathername?: string,
    phoneMobile?: string,
    email?: string,
    comment?: string,
    individual?: number
  };
export type EntityModel = {
  id?: number,
  name?: string,
  inn?: string,
  address?: string,
  phone?: string,
  contactName?: string,
  phoneContactName?: string,
  email?: string,
  comment?: string,
  companyName?: string,
};

export namespace Client {
  
  

  class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getClientIndividualById(id: number| undefined): Promise<IndividualModel> {
        return ky.get(`${this.url}/dkClientIndividual/${id}`).json();
    }

    public getClientEntityById(id: number|undefined): Promise<EntityModel> {
      return ky.get(`${this.url}/entity/${id}`).json();
  }

    public updateIndividual(values: any): Promise<IndividualModel> {

        return ky.patch(`${this.url}/individual`, {json: values}).json();
    }

    public updateEntity(values: any): Promise<EntityModel> {

      return ky.patch(`${this.url}/entity`, {json: values}).json();
  }
    
  }

  export const controller = new Controller('api/client');
}
