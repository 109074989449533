import React, { memo } from 'react'
import PieChart, {
    Series as SeriesPie,
    Label as LabelPie,
    Connector as ConnectorPie,
    Size as SizePie,
    Export as ExportPie,
    Legend as LegendPie,
  } from 'devextreme-react/pie-chart';

import { bemHelper } from '~/libs';
import './styles.scss'

type PropsPieChart = {
    data: any[],
    type?: 'doughnut'|'pie',
    palette: "Material" | "Dark Violet" | "Bright",
    title: string,
    сustomColor?: boolean,
    percentages?: boolean,
}


const CustomPieChart: React.FC<PropsPieChart> = memo(({data, type='pie', palette, title, сustomColor=false, percentages=false}) => {

    const cn = bemHelper('pie-chart');

    const customizePoint = (pointInfo: any) => {
        return {color: pointInfo.data.color};
    };
    const customizeText = (arg: any) => {
        return `${arg.percentText}`
    }

    const props: any = {
        dataSource: data,
        type: type,
        palette: palette,
        title: title
    }

    const propsLabel: any = {

    }
    
    if (сustomColor){
        props.customizePoint = customizePoint
    }
    if(percentages){
        propsLabel.customizeText = customizeText
    }

    return (
        <div className={cn('')} >
            {data.length === 0 && (
                <div className={cn('no-data')}>
                    <div className={cn('no-data-2')}>
                        <h4>Нет данных</h4>
                    </div>
                </div>  
            )}
            <PieChart
                {...props}
                    >
                    <SeriesPie
                        argumentField="name"
                        valueField="value"
                        >
                        <LabelPie visible={true} {...propsLabel}>
                            <ConnectorPie visible={true} width={1} />
                        </LabelPie>
                    </SeriesPie>
                    <LegendPie
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        itemTextPosition="right"
                    />
                <SizePie width={350}/>

            </PieChart>
            
        </div>
    )
})

export default CustomPieChart