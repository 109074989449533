import { SelectBox } from 'devextreme-react';
import React, { FC } from 'react'
import { dkStatusDataSource } from '~/custom-data-sources';
import { bemHelper } from '~/libs';
import './style.scss'
import CustomStore from 'devextreme/data/custom_store';
import { Status } from '~/api/records';

export type ChangeEventProps = {
    previousValue: number,
    value: number
    
}

type Props = {
    cell: any,
    onValueChanged: (cell: any,e: ChangeEventProps, dkId: number) => void
}

const dataSource = new CustomStore({
    key: 'id',
    loadMode: 'raw',
    load: async () => {
        const result: any = await dkStatusDataSource.load()
        return result.filter((i: Status) => [3,5,6,7,8,2].includes(i.id))
    }
})

export const StatusSelectBox: FC<Props> = ({cell, onValueChanged }) => {
    const cn = bemHelper('status-select-box')

    const handleValueChanged = (e: ChangeEventProps) => {
        cell.setValue(e.value)
        onValueChanged(cell, e, cell.key.dkId)
    }

    return (
        <SelectBox
            defaultValue={cell.value}
            {...cell.column.lookup}
            className={cn('select')}
            dataSource={dataSource}
            onValueChanged={handleValueChanged}
            />
    )
}