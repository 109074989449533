import React, { ElementRef, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Form } from 'devextreme-react';
import { ButtonItem, Item, GroupItem, EmptyItem} from 'devextreme-react/form';
import { Sto } from '~/api';
import { useTranslation } from 'react-i18next';
import { bemHelper } from '~/libs';

import './styles.scss'
import { useIsMobile } from '../../../../../../hooks/useIsMobile';

type Data = {
    dkAutoTypeId: number,
    stoId: number,
    previousStoId: number,
}

type Props = {
    onChangeDate: (value: Date) => void,
    onCancel: (previousStoId?: number) => void
}

export type PopupChangeDataRef = {
    show: (value: Data) => void
}

export const ChangeDatePopup = forwardRef<
    PopupChangeDataRef,
    Props
>(({onChangeDate, onCancel}, ref) => {
    const form = useRef<Form>(null)
    const isMobile = useIsMobile()
    const saveButtonRef = useRef<ButtonItem>(null)
    const [visible, setVisible] = useState(false)
    const data = useRef<Data|null>(null)

    const { t } = useTranslation('records');
    const cn = bemHelper('change-date-popup');

    const getAvailableTimes = async (params: any) => {
        let result = await Sto.controller.getOrderTimes(params)
        
        const now = new Date()
        
        if (params.date === now.toISOString().split('T')[0]){
            result = result.filter((i) => {
                const time = new Date(i.timeStamp)
                if (now.getTime() < time.getTime()){
                    return true
                }
                return false
            })
        }
        const timeEditor = form.current?.instance.getEditor('dateReceiptStart')

        timeEditor?.option('dataSource', result.filter((_i: any) => _i.free === true))

        if(timeEditor?.option("disabled") === true){
            timeEditor.option("disabled", false);
        }
    }

    const editorOptions = useMemo(() => {
        return {
            date: {
                with: '100%',
                min: new Date(),
                useMaskBehavior: true,
                onValueChanged: ({value}: {value: Date|null}) => {
                    if(value){
                        getAvailableTimes({
                            date: value?.toLocaleDateString('sv'),
                            stoId: data.current?.stoId,
                            dkAutoTypeId: data.current?.dkAutoTypeId
                        })
                    }
                }
            },
            time: {
                    with: '100%',
                    displayExpr: 'name',
                    valueExpr: 'timeStamp',
                    disabled: true,
                },
            }
        }, [])

    const handleDataChange = () => {
        const button = form.current?.instance.getButton('submit')
        const formData = form.current?.instance.option('formData')
        if(button){
            if(formData.date && formData.dateReceiptStart){
                button.option('disabled', false)
            } else{
                button.option('disabled', true)
            }
        }
    }

    const handleSave = () => {
        const formData = form.current?.instance.option('formData')
        onChangeDate(new Date(formData.dateReceiptStart))
        setVisible(false)
    }

    const handleCancel = () => {
        onCancel(data.current?.previousStoId)
        setVisible(false)
    }

    const cancelButtonOptions = useMemo(() => {
        return {
            text: 'Отмена',
            type: 'normal',
            onClick: handleCancel,
          } 
    }, [handleCancel])

    const saveButtonOptions = useMemo(() => {
        return {
            text: 'Применить',
            type: 'normal',
            disabled: true,
            onClick: handleSave,
          }
    }, [handleSave])

    useImperativeHandle(ref, ()=>{
        return {
            show: (values) => {
                data.current = values
                setVisible(true)
            }
        }
    })

    return (
        <Popup
            visible={visible}
            onHidden={() => setVisible(false)}
            showCloseButton={false}
            height={'auto'}
            maxWidth={500}
            title='Выберите дату и время'
            wrapperAttr={{
                class: cn()
            }}
            >
            <Form
                ref={form}
                onFieldDataChanged={handleDataChange}
                colCount={2}
                formData={{
                    date: null
                }}
                >

                <Item 
                    dataField="date" 
                    label={{text: t('date')}}  
                    editorType="dxDateBox" 
                    editorOptions={editorOptions.date}
                    isRequired
                    />

                <Item
                    dataField="dateReceiptStart" 
                    label={{text: t('time')}} 
                    editorOptions={editorOptions.time} 
                    editorType="dxSelectBox"
                    isRequired
                    />
                
                <GroupItem
                    colCountByScreen={{
                        lg: 2,
                        md: 2,
                        sm: 2,
                        xs: 2,
                    }}
                    colSpan={2}
                    cssClass={cn('group-button')}
                    >
                    <ButtonItem
                        horizontalAlignment='left'
                        buttonOptions={cancelButtonOptions}
                        />

                    <ButtonItem
                        name='submit'
                        horizontalAlignment={isMobile ? 'left': 'right'}
                        buttonOptions={saveButtonOptions}
                        />
                </GroupItem>
            </Form>
        </Popup>
    )
})