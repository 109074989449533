import React, { memo, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { bemHelper } from '~/libs';
import { AuthContext } from '~/context';
import './styles.scss';
import { Button } from 'devextreme-react/button';
import { toast } from 'react-toastify';
import { NotificationContext } from '~/context';
import parse from "html-react-parser";
import { ModelNotification } from '~/api/notifications';
import { Howl } from 'howler';
import {Notifications as Notification} from '~/api/notifications'
import { LoadIndicator } from 'devextreme-react/load-indicator';

const cn = bemHelper('notifications');

type Props = {

    toggleNotificationsMenu(): void;
  };
  

export const Notifications: React.FC<Props> = memo((props) => {

    const { toggleNotificationsMenu } = props
    const {
        notificationState: {
            notifications,
            connection,
            connecting
        },
        addNotifications,
        setConnection,
        checkNotification,
        checkAllNotifications
      } = useContext(NotificationContext);
    const {
    authState: { authInProgress, isAuthorized, userData },
    } = useContext(AuthContext);
    
    const [ list, setList ] = useState<ModelNotification[]>(notifications.slice(0, 5))
    
    
      const playSound = new Howl({
        src: ['./sound/message.mp3'],
        html5: true
        })
    
    const handleReconnect = () => {
        if(userData){
            Notification.controller.connect({addNotifications, playSound, setConnection, token: userData.token, reconnect: true})
        } 
    }
      
    return (
        <>
        <div className={cn('layout')} onClick={() => toggleNotificationsMenu()}></div>
        <div className={cn()}>
            <Button
                width={20}
                icon="remove"
                style={{
                    position: 'absolute',
                    right: '5px',
                    top: '5px',
                }}
                onClick={() => toggleNotificationsMenu()}
                />
            <div className={cn('title')}>
                Уведомления
            </div>  
            { connection ? 
            notifications.length !== 0 ? (
                <>
                    <ul>
                        { list.map((_i) => {
                            const date = new Date(_i.date).toLocaleString('ru', {
                                hour: 'numeric',
                                minute: 'numeric',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })

                            const parsedMsg:any = parse(_i.msg)

                            return (
                                <li 
                                    key={_i.id}
                                    onClick={() => {checkNotification(_i.id)}}
                                    className={_i.checked ? cn('item--checked'): cn('item')}
                                    >
                                    <p>
                                        {parsedMsg[0]}
                                        <Link
                                            onClick={() => toggleNotificationsMenu()}
                                            to={`/app${parsedMsg[1].props.href}`}>
                                            {parsedMsg[1].props.children}
                                        </Link>
                                    </p>
                                    <div className={cn('notification-date')}>{date}</div>
                                </li>
                            )
                            
                        })}
                    </ul>
                    <div
                        className={cn('toolbar')}
                        >
                        { (notifications.length > 5 && list.length !== notifications.length) && (
                            <Button
                                width={150}
                                text="Показать все..."
                                type="normal"
                                stylingMode="text"
                                onClick={() => {
                                    setList(notifications)
                                }}
                                />
                        )}
                        <div></div>
                        <Button
                            width={100}
                            text="Очистить"
                            type="normal"
                            stylingMode="text"
                            onClick={() => {checkAllNotifications(notifications)}}
                            />
                    </div>
                </>
                ) : (
                    <div className={cn('empty')}>
                        <span>У вас нет уведомлений</span>
                    </div>
                ) : !connecting ? (
                    <div className={cn('empty')}>
                        <span>Нет соединения с сервером</span> 
                        <Button
                            text="Подключиться"
                            type='default'
                            style={{marginTop: '10px'}}
                            onClick={()=> handleReconnect()}
                            />
                        
                    </div>
                ) : (
                    <div className={cn('empty')}>
                        <div className={'loader'}>
                            <LoadIndicator />
                        </div>
                    </div>
                )
            }
        </div>
        </>
    );
});
