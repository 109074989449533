import React, { useRef, useState } from 'react'
import { bemHelper } from '~/libs';
import { Button } from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useHistory, useLocation } from 'react-router-dom'

import './styles.scss'
import { ComponentProps, ManageRef } from '~/ui/atoms/PopupEdit';


type Props = {
    Component: React.FC<ComponentProps>,
    title?: string;
}

const EditBase: React.FC<Props> = ({Component, title}) => {

    const cn = bemHelper('edit-base');
    const location = useLocation();
    const history = useHistory()

    const [ isLoading, setIsLoading ] = useState(true)
    const [ error, setError ] = useState<boolean|string>(false)

    const manageRef = useRef<ManageRef>({
        setIsLoading: setIsLoading,
        setError: setError
    })
    
    return (
        <>
        { error ? (
            <div className={cn('error')}>
                <h1>Произошла ошибка {error}</h1>
            </div>
        ) : (
            <div className={cn('paper')}>
                { location.state && (
                    <Button
                        width={30}
                        icon="back"
                        visible={Boolean(location.state.isBack)}
                        type="normal"
                        onClick={() => history.push(location.state.pathname ,{
                            popupParams: location.state.popupParams,
                            periodValues: location.state.periodValues,
                            isBacked: true
                        })}
                    />
                )}
                {title && <h1>{title}</h1>}
                <Component 
                    manageRef={manageRef}
                    handleClose={() => {}}
                    />
                { isLoading && (
                    <div className={'loader'}>
                        <LoadIndicator />
                    </div>
                )}
            </div>
        )}
        
        </>
    )
}

export default EditBase