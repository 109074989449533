import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CredentialsForm } from './components';
import { AuthContext } from '~/context';
import { bemHelper, initialLocationStorage } from '~/libs';
import { Loader } from '~/ui/atoms';
import './styles.scss';
import { LoadIndicator } from 'devextreme-react';

const cn = bemHelper({ name: 'sign-in', prefix: 'page-' });

export const SignIn: React.FC = memo(() => {
  const { t, ready: i18nReady } = useTranslation('sign-in', {
    useSuspense: false,
  });
  const history = useHistory();

  const {
    authState: { 
      authError, 
      authInProgress, 
      isAuthorized,
      isManyProfiles,
      userData
    },
    clearAuthError,
    logIn,
  } = useContext(AuthContext);
  
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const handleCredentialsFormSubmit = useCallback(
    async (credentials: { login: string; password: string }) => {
      setIsLoading(true)
      await logIn({ 
        credentials: {
          login: credentials.login.toLowerCase(),
          password: credentials.password
        }  
      });
      setIsLoading(false)
    },
    [logIn]
  );


  useEffect(() => {
    if (isManyProfiles) {
      history.push('/auth/selectprofile')
    }
  }, [isManyProfiles]);

  useEffect(() => {
    if (isAuthorized) {
      // history.push('/auth/selectprofile')
      const initialLocation = initialLocationStorage.get();
      initialLocationStorage.remove();
      history.push(initialLocation || '/app/records')
    }
  }, [isAuthorized]);

  
  useEffect(() => {
    if (authError && i18nReady) {
      toast.error(authError);
      clearAuthError();
    }
  }, [authError, t, i18nReady, clearAuthError]);

  return (
    <div className={cn()}>
      { isLoading && (
                <div className={cn('loader-box')}>
                    <div className={cn('loader')}>
                        <LoadIndicator />
                    </div>
                </div>
            )}
      {!i18nReady && <Loader size="60px" />}
      {i18nReady && (
        <>
          <div className={cn('card')}>
            <div className={cn('card-right')}>
              <h3 className={cn('form-title')}>{t('Authorization')}</h3>
              <CredentialsForm
                authInProgress={authInProgress}
                onSubmit={handleCredentialsFormSubmit}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
});
