import React, {
    createContext,
    useCallback,
    useReducer,
  } from 'react';
import { Common } from '~/types';


type Props = {
    children?: React.Component
}

export type PropsEditProps = {
    id: number,
    type: Common.TypePopup,
    activeRecord?: boolean,
    updateData: () => void
}

export type PropsOfferPopup = {
    type: 'delete' | 'resign',
    handleAgreePopup: () => void,
    handleDisagreePopup: () => void, 
    title: string,
}

export type PropsAgreeOfferPopup = {
    dataRecord: any,
    updateData: () => void
}

enum ActionType {
    ADD_POPUP_OPEN = 'ADD_POPUP_OPEN',
    ADD_POPUP_CLOSE = 'ADD_POPUP_CLOSE',
    ADD_POPUP_OPEN_RESIGN = 'ADD_POPUP_OPEN_RESIGN',
    ADD_POPUP_OPEN_UPDATE_FUNC = 'ADD_POPUP_OPEN_UPDATE_FUNC',
    EDIT_POPUP_CLOSE = 'EDIT_POPUP_CLOSE',
    EDIT_POPUP_OPEN = 'EDIT_POPUP_OPEN',
    OFFER_POPUP_CLOSE = 'OFFER_POPUP_CLOSE',
    OFFER_POPUP_OPEN = 'OFFER_POPUP_OPEN',
    OFFER_POPUP_AGREE = 'OFFER_POPUP_AGREE',

}
type ReducerState = {
    addPopup: {
        visible: boolean
        cellData: any,
        dataRecord: any,
        updateData: () => void,
        resign: boolean
    },
    offerPopup: {
        visible: boolean,
        type: 'delete' | 'resign',
        handleAgreePopup: () => void,
        handleDisagreePopup: () => void, 
        title: string,
    },
    editPopup: {
        visible: boolean,
        type?: Common.TypePopup,
        id?: number,
        activeRecord?: boolean,
        updateData: () => void
    },
};
  
type ReducerAction = {
    type: ActionType;
    payload?: any;
};

type PopupContextState = {
    popupState: ReducerState;
    addPopupOpen: (cellData?: any, dataRecord?: any)=>void;
    addPopupClose: ()=>void;
    editPopupOpen: (payload: PropsEditProps) => void,
    editPopupClose: () => void,
    offerPopupOpen: (payload: PropsOfferPopup) => void,
    offerPopupClose: () => void,
    offerPopupAgree: (payload: PropsAgreeOfferPopup) => void,
    addPopupUpdateFunc: (updateData: ()=>void) => void,
  };


  
const initReducerState = (): ReducerState => {
    return {
        addPopup: {
            visible: false,
            cellData: null,
            dataRecord: null,
            updateData: () => null,
            resign: true
        },
        offerPopup: {
            visible: false,
            type: 'resign',
            handleAgreePopup: () => null,
            handleDisagreePopup: () => null, 
            title: '',
        },
        editPopup: {
            visible: false,
            updateData: () => null
        }
    }
}

const reducer = (state: ReducerState, { type, payload }: ReducerAction) => {
    switch (type) {
        case ActionType.ADD_POPUP_OPEN_UPDATE_FUNC:
            return {
                ...state,
                addPopup: {
                    ...state.addPopup,
                    updateData: payload.updateData
                }
            }
        case ActionType.ADD_POPUP_OPEN:
            const newState: any = {
                ...state,
                addPopup: {
                    ...state.addPopup,
                    visible: true,
                    cellData: payload.cellData,
                    dataRecord: payload.dataRecord,
                    resign: false
                }
            }
            if(payload.updateData){
                newState.updateData = payload.updateData
            }

            return newState
        case ActionType.ADD_POPUP_OPEN_RESIGN:

            const res: any = {
                ...state,
                addPopup: {
                    ...state.addPopup,
                    visible: true,
                    cellData: payload.cellData,
                    dataRecord: payload.dataRecord,
                    updateData: payload.updateData,
                    resign: true
                }
            }
            if(payload.updateData){
                res.updateData = payload.updateData
            }
            
            return res
        case ActionType.ADD_POPUP_CLOSE:
            return {
                ...state,
                addPopup: {
                    visible: false,
                    cellData: null,
                    dataRecord: null,
                    updateData: () => null,
                    resign: false
                }
            }
        case ActionType.EDIT_POPUP_OPEN:
            return {
                ...state,
                editPopup: {
                    visible: true,
                    type: payload.type,
                    id: payload.id,
                    activeRecord: !!payload.activeRecord,
                    updateData: payload.updateData ? payload.updateData : () => null,
                }
        }
        case ActionType.EDIT_POPUP_CLOSE:
            return {
                ...state,
                editPopup: {
                    updateData: () => null,
                    visible: false,
                }
            }

        case ActionType.OFFER_POPUP_OPEN:
            return {
                ...state,
                offerPopup: {
                    visible: true,
                    type: payload.type,
                    handleAgreePopup: payload.handleAgreePopup,
                    handleDisagreePopup: payload.handleDisagreePopup, 
                    title: payload.title,
                }
            }
        case ActionType.OFFER_POPUP_CLOSE:
            return {
                ...state,
                offerPopup: {
                    visible: false,
                    type: 'resign',
                    handleAgreePopup: () => null,
                    handleDisagreePopup: () => null, 
                    title: '',
                }
            }

        default:
            throw new Error('Invalid reducer action name at auth');
    }
};
  
export const PopupContext = createContext<PopupContextState>({
    popupState: initReducerState(),
    addPopupOpen: (values?: any) => null,
    addPopupClose: () => null,
    editPopupOpen: (payload: PropsEditProps) => null,
    editPopupClose: () => null,
    offerPopupOpen: (payload: PropsOfferPopup) => null,
    offerPopupClose: () => null,
    offerPopupAgree: (payload: PropsAgreeOfferPopup) => null,
    addPopupUpdateFunc: (updateData: ()=>void) => null,
    
    // addNotifications: (data: ModelNotification[], isList?: boolean) => null,
    // checkNotification: (id: number) => null,
    // checkAllNotifications: (notifications: ModelNotification[]) => null,
    // setFirstLoad: (value: boolean) => null,
    // setConnection: (
    //     value: boolean
    // ) => null,
});
  
export const PopupProvider: React.FC = (props) => {
    const { children } = props;
  
    const [state, dispatch] = useReducer(
      reducer,
      null,
      initReducerState
    );

    const addPopupOpen = useCallback((props: any) => {
        const payload: any = {}
        if (props){
            payload.cellData = props.cellData
            payload.updateData = props.updateData
            payload.dataRecord = props.dataRecord
        }else {
            payload.cellData = null
            payload.updateData = ()=>null
            payload.dataRecord = null
        }
        dispatch({ type: ActionType.ADD_POPUP_OPEN , payload: payload});
    }, []);

    const addPopupClose= useCallback(() => {
        dispatch({ type: ActionType.ADD_POPUP_CLOSE });
    }, []);

    const addPopupUpdateFunc = useCallback((
        updateData: () => void
    ) => {
        dispatch({ type: ActionType.ADD_POPUP_OPEN_UPDATE_FUNC, payload: {updateData} });
    }, []);

    const editPopupOpen = useCallback(( payload: PropsEditProps) => {
        dispatch({ type: ActionType.EDIT_POPUP_OPEN , payload: payload});
    }, []);

    const editPopupClose = useCallback(() => {
        dispatch({ type: ActionType.EDIT_POPUP_CLOSE });
    }, []);

    const offerPopupOpen = useCallback(( payload: PropsOfferPopup) => {
        dispatch({ type: ActionType.OFFER_POPUP_OPEN , payload: payload});
    }, []);

    const offerPopupClose = useCallback(() => {
        dispatch({ type: ActionType.OFFER_POPUP_CLOSE });
    }, []);

    const offerPopupAgree= useCallback(({dataRecord, updateData}: PropsAgreeOfferPopup) => {

        const payload = {
            dataRecord,
            updateData,
            cellData: null
        }

        dispatch({ type: ActionType.ADD_POPUP_OPEN_RESIGN , payload: payload});
        dispatch({ type: ActionType.OFFER_POPUP_CLOSE});
    }, []);

    return (
      <PopupContext.Provider
        value={{
            popupState: state,
            addPopupOpen,
            addPopupClose,
            addPopupUpdateFunc,
            editPopupOpen,
            editPopupClose,
            offerPopupOpen,
            offerPopupClose,
            offerPopupAgree,
        }}
      >
        {children}
      </PopupContext.Provider>
    );
  };
