import { Stomp } from '@stomp/stompjs';
import { toast } from 'react-toastify';
import { Common } from '~/types';
import { PropsSetConnection } from '~/context/NotificationContext';



export type ModelNotification = {
    id: number,
    date: string,
    msg: string,
    checked?: boolean
}

export type ModelNotificationWs = {
    type: string,
    wsMessage: ModelNotification[],
}

type PropsConnect = {
    addNotifications: (
    data: ModelNotification[], isList?: boolean) => void, 
    playSound: any, 
    setConnection: (value: PropsSetConnection) => void,
    token: string,
    reconnect?: boolean
}


export namespace Notifications {

    class Controller {
        readonly url: string;
        stompClient: any;
        token: string;
    
        constructor(url: string) { 
            this.stompClient = null;
            this.url = url;
            this.token = ''
        }

        public sendReadNotify(id: number) {
            this.stompClient.send(`${this.url}/readNotify`, {token: this.token}, JSON.stringify({'id':id}));
        }

        public logout() {
            this.stompClient.send(`${this.url}/logout`, {token: this.token}, '');
        }

        public sendMessage(stompClient: any, token: string) {
            stompClient.send(`/api/notification/getAll`, {token}, "");
        }

        public connect({
            addNotifications,
            playSound,
            setConnection,
            token,
            reconnect = false
        } : PropsConnect) {
            //get credentials

            if(reconnect){
                this.stompClient.reconnect_delay = 0;
                try{
                    this.disconnect()
                } catch(err:any) {
                    console.log('Соединение отсутсвует')
                }
                
            }

            this.token = token

            const socket = new WebSocket(`wss://${process.env.REACT_APP_HOST}${this.url}`)
            this.stompClient = Stomp.over(socket);
            
            this.stompClient.reconnect_delay = 5000;
            const sendMessage = this.sendMessage
            const stompClient = this.stompClient
            setConnection({
                connection: false,
                connecting: true
            })
            this.stompClient.connect({
                token: `${token}`
            },
            //Success
                (frame: any) => {
                    console.log('Connected: ' + frame);
                    
                    this.stompClient.subscribe('/user/private/messages', (messageOutput: any) => {
                        const data: ModelNotificationWs = JSON.parse(messageOutput.body)
                        if (data.type === Common.TypeWsMessage.FOR_ALL){
                            addNotifications(data.wsMessage,true)
                        } else {
                            addNotifications(data.wsMessage)
                        }
                        
                        if (data.type === Common.TypeWsMessage.PRIVATE){
                            toast.success(data.wsMessage[0].msg.replace(/<\/?[^>]+(>|$)/g, ""), {
                                className: 'notifications__push',
                                autoClose: 5000,
                                position: toast.POSITION.TOP_RIGHT,
                                hideProgressBar: true,
                                draggable: true,
                                draggablePercent: 40
                            });
                            playSound.play()   
                        }
                    });
                    setConnection({
                        connection: true,
                        connecting: false
                    })
                    sendMessage(stompClient, token)
                },
            //Error
                (err:any) => {
                    console.log(err)
                },
            //onClose
                () => {
                    setConnection({
                        connection: false,
                        connecting: false
                    })
                },

            );
        }

        public disconnect = () => {
            if(this.stompClient != null) {
                this.logout()
                this.stompClient.disconnect();

                // const xhr = new XMLHttpRequest();
                // xhr.open("GET", 'https://api.infobrox.ru:54321/api/auth', true, 'none', 'none');
                // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                // xhr.onerror = function(err) {
                // console.error(err);
                // };
                // xhr.onload = function () {
                //     window.location.assign('http://localhost:8080/auth');
                // };
                // xhr.send()

            }
            console.log("Disconnected");
        }
      
    }
  
    export const controller = new Controller('/api/notification');
}
