import React, { ElementRef, FC, forwardRef, useContext } from "react"
import DataGrid, { Column, Editing, FilterRow, Paging, Scrolling, Sorting } from "devextreme-react/data-grid"
import { useMemo } from "react"
import { Act } from "~/api/acts"
import { Common } from "~/types"
import { onDbClick } from "~/routes/Reports/service"
import { CellRenderLink, cellRenderIndex } from "~/utils/reportCellLink"
import { PopupContext } from "~/context"


type Props = {
}


export const CreateActTable = forwardRef<
    Common.DataGridRef,
    Props
>(({
}, ref) => {

    const saleAmountFormat = useMemo(() => {
        return {
            style: 'currency', 
            currency: 'RUB', 
            useGrouping: true, 
            minimumSignificantDigits: 1
        }
    },[])

    const { 
        editPopupOpen
    } = useContext(PopupContext)


    return (
        <DataGrid
            ref={ref}
            allowColumnResizing={true}
            columnAutoWidth={true}
            showBorders
            hoverStateEnabled={true}
            showColumnLines
            allowColumnReordering={true}
            onCellDblClick={(e) => {onDbClick({e: e, editPopupOpen, activeRecord: true})}}
            noDataText="Нет записей"
        >
            <Sorting mode="multiple" />
            <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
            <Paging enabled={false} />
            <FilterRow visible={true}
                applyFilter='auto' />
            <Editing 
                allowDeleting={true}
                />

            <Column
                caption={'№'}
                width={50}
                cellRender={(data:any) => cellRenderIndex({cellData: data, editPopupOpen, activeRecord: true})}
                alignment="left"
                name="number"
                />

            <Column
                caption='Адрес СТО (ПТО)'
                alignment="center"
                dataField="stoAddress"
                cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen, activeRecord: true})}
                />

            <Column
                caption="Дата, Время"
                dataType="datetime"
                dataField='dateReceiptStart'
                defaultSortOrder='desc'
                alignment="center"
                />

            <Column caption='TC' alignment="center">
                <Column
                    dataField="regNumber"
                    caption='ГРЗ'
                    alignment="center"
                    cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen, activeRecord: true})}
                    />
                <Column
                    dataField="brand"
                    caption='Марка'
                    alignment="center"
                    />
                <Column
                    dataField="model"
                    caption='Модель'
                    alignment="center"
                    />
            </Column>

            
            <Column
                dataField="priceSto"
                caption='Стоимость'
                dataType="number"
                format={saleAmountFormat}
                alignment="center"
                />

            <Column
                dataField="comment"
                caption='Комментарий'
                alignment="center"
                />

        </DataGrid>
    )
})