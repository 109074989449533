import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import './styles.scss'
import { bemHelper } from '~/libs';
import { Button } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { RightElementTitle, RightElementTitleRef } from './atoms/RightElementTitle';
import { Loader } from './atoms/Loader';
import { LoaderRef } from './atoms/Loader';
import { ManageRef } from '../PopupEdit';

type Props = {
  title: string,
  visible: boolean,
  handleClose: () => void,
  children: React.ReactNode
}


export enum ToolbarButton {
  CANCEL='items[1]',
  SUBMIT='items[2]',
  OK='items[3]',
  DELETE='items[0]',
}

export const CustomPopup = forwardRef<
  ManageRef,
  Props
>
(({
  title, 
  visible, 
  handleClose, 
  children, 
}, ref) => {

  const cn = bemHelper('popup');
  const [error, setError] = useState<string>()
  const toolbarRef = useRef<Toolbar>(null)
  const rightElementTitleRef = useRef<RightElementTitleRef>(null)
  const loaderRef = useRef<LoaderRef>(null)

  const optionsButton = useMemo(() => {
    return {
      deleteButton: {
        type: 'danger',
        text: 'Удалить',
      },
      submitButton: {
        type: 'normal',
        text: 'Сохранить',
      },
      cancelButton:{
        type: 'normal',
        text: 'Отмена',
        onClick: () => handleClose()
      },
      okButton: {
        type: 'normal',
        text: 'Ок',
        onClick: () => handleClose()
      }
    }
  },[ handleClose])

  useImperativeHandle(ref, ()=>{
    return {
      toolbar: toolbarRef.current?.instance,
      setRightElementTitle: (value) => rightElementTitleRef.current?.setElement(value),
      setIsLoading: (value) => loaderRef.current?.setIsVisible(value),
      setError: (value) => {
        toolbarRef.current?.instance.option(`${ToolbarButton.DELETE}.visible`, false)
        toolbarRef.current?.instance.option(`${ToolbarButton.SUBMIT}.visible`, false)
        setError(value)
      }
    }
  })
  
  return (
    <>
    <div className={(visible) ? cn('layout') + ' ' + cn('layout-active'): cn('layout')}></div>
    { visible && (
      <div className={cn('wrapper')}>
      <div onClick={handleClose} style={{height: '100vh', width: '100vw', position: 'fixed', top:0, left: 0,}}></div>
        <div className={cn('modal')}>
            <div className={cn('header')}>
                <div className={cn('header-title')}>
                  <h2>{title}</h2>
                  <RightElementTitle
                    ref={rightElementTitleRef}
                    />
                </div>
                <Button
                  type="normal"
                  icon='close'
                  stylingMode="text"
                  onClick={handleClose}
                />
            </div>
            <hr />
            <div className={cn('content')}>
                {error ? (
                    <div 
                      className={cn('error')}
                      >
                      <h1>Произошла ошибка {error}</h1>
                  </div>
                ) : children}
            </div>
            <Toolbar 
              ref={toolbarRef}
              style={{padding: '0 20px', zIndex: 1}}
              >
                <Item
                  widget="dxButton"
                  location={'before'}
                  options={optionsButton.deleteButton}
                  />
                <Item
                  widget="dxButton"
                  location={'after'}
                  options={optionsButton.cancelButton}
                  />
                <Item
                  widget="dxButton"
                  location={'after'}
                  options={optionsButton.submitButton}
                  />
                <Item
                  widget="dxButton"
                  location={'after'}
                  
                  options={optionsButton.okButton}
                  />

            </Toolbar>
            
            <Loader
              ref={loaderRef}
              />
            
        </div>
      </div>
    )}
    </>
  )
})
