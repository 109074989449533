import React, { useContext, useState } from 'react';
import Router from './Router';

import { AuthContext } from '~/context';
import { NotificationContext } from './context/NotificationContext/index';
import { Notifications } from './api';
import { Howl } from 'howler'



export const App: React.FC = () => {
  const {
    authState: { authInProgress, isAuthorized, userData },
  } = useContext(AuthContext);

  const {
    addNotifications,
    setFirstLoad,
    setConnection,
    notificationState: {
      firstLoad,
    }
  } = useContext(NotificationContext);


  const playSound = new Howl({
    src: ['./sound/message.mp3'],
    html5: true
    })

  if (isAuthorized && !authInProgress && userData && firstLoad){
    Notifications.controller.connect({addNotifications, playSound, setConnection, token: userData.token})
  
    setFirstLoad(false)
  }
  
  return <Router 
    authInProgress={authInProgress} 
    isAuthorized={isAuthorized} 
    permission={userData?.roles}
    />
};
