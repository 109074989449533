import { DataGrid, LoadIndicator } from "devextreme-react";
import { Column, FilterRow, Paging, Scrolling, Sorting, Button, Export } from "devextreme-react/data-grid";
import React, { memo, useContext, useEffect, useState } from "react";
import { Records } from "~/api";
import { ModelPrice } from "~/api/records";
import { AuthContext } from "~/context";
import { onExporting } from "~/routes/Reports/service";
import { cellRenderIndex } from "~/utils/reportCellLink";

type Props = {
    selectStation: (stoId: number) => void
    addresses: {
        name: string,
        id: number
    }[]
}
type dataType = ModelPrice & {address?: string}

type dataFieldType = keyof ModelPrice

export const TablePrices: React.FC<Props> = memo(({
    addresses,
    selectStation
}) => {

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ data, setData ] = useState<dataType[]>()
    const { authState:{
        userData
    }} = useContext(AuthContext);

    const getPrices = async() => {

        const stoListId = addresses.filter(i => i.id !== null).map(i => i.id)
        
        const result = await Records.controller.getPricesAllSto(stoListId)

        const newResult = result.map(i => {

            return {
                address: addresses.find(_i => _i.id === i.sto_id)?.name,
                ...i
            }
        })

        setData(newResult)
        setIsLoading(false)
    }

    const generateFuncCellValue = (dataField: dataFieldType) => {

        return (rowData:ModelPrice) => {
            if(rowData[dataField]){
                return rowData[dataField]
            }

            return '-'
        }
    }

    const handleSelectStation = (e:any) => {
        selectStation(e.row.data.sto_id)
    }

    const onExport = (e: any) => {
        const staff = userData?.staff
        const fileName = `${staff?.lastname} ${staff?.firstname} ${staff?.fathername} цены на техосмотр`
        onExporting(e, fileName)
    }

    useEffect(() => {
        getPrices()
    }, [addresses])

    return (
        <div>
        <DataGrid
                dataSource={data}
                allowColumnResizing={true}
                columnAutoWidth={true}
                showBorders
                onExporting={onExport}
                hoverStateEnabled={true}
                className='data-grid-prices'
                showColumnLines
                allowColumnReordering={true}
                noDataText="Нет записей"
            >
            <Sorting mode="multiple" />
            <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
            <Paging enabled={false} />
            <FilterRow visible={false}/>

            <Column type="buttons" width={110} caption='Записать' alignment="center">
                <Button
                  hint="Записать" 
                  icon="fas fa-plus" 
                  onClick={handleSelectStation} 
                  />
            </Column>
            <Column
                dataField="address"
                caption='Адрес СТО'
                alignment="center"
                allowEditing={false}
                />
            <Column caption='Стоимость' alignment="center">
            
                <Column
                    dataField="l"
                    caption='L(A)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('l')(rowData)}
                    /> 
                <Column
                    dataField="m1"
                    caption='M1(B)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('m1')(rowData)}
                    /> 
                <Column
                    dataField="m2"
                    caption='M2(D)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('m2')(rowData)}
                    /> 
                <Column
                    dataField="m3"
                    caption='M3(D)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('m3')(rowData)}
                    /> 
                <Column
                    dataField="n1"
                    caption='N1(B)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('n1')(rowData)}
                    /> 
                <Column
                    dataField="n2"
                    caption='N2(C)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('n2')(rowData)}
                    /> 
                <Column
                    dataField="n3"
                    caption='N3(C)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('n3')(rowData)}
                    /> 
                <Column
                    dataField="o1"
                    caption='O1(В/ЕкВ)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('o1')(rowData)}
                    /> 
                <Column
                    dataField="o2"
                    caption='O2(ЕкВ/Е)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('o2')(rowData)}
                    /> 
                <Column
                    dataField="o3"
                    caption='O3(ЕкВ/Е)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('o3')(rowData)}
                    /> 
                <Column
                    dataField="o4"
                    caption='O4(Е/ЕкС)'
                    alignment="center"
                    dataType="number"
                    allowEditing={false}
                    calculateCellValue={(rowData: ModelPrice) => generateFuncCellValue('o4')(rowData)}
                    /> 
                    
            </Column>

            <Export
                enabled={true}
                />
        </DataGrid>
        { isLoading && (
            <div className={'loader'}>
                <LoadIndicator />
            </div>
        )}
    </div>
    )
})