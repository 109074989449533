import { LoadIndicator } from "devextreme-react"
import React, { FC } from "react"



type Props = {
    classNames?: {
        root?: string,
        loaderBox?: string
    }
}

export const PageLoader: FC<Props> = ({classNames}) => {
    return (
        <div
            className={"paper-tab "+ classNames?.root}
        >
            <div className={'loader ' + classNames?.loaderBox}>
                <LoadIndicator />
            </div>
        </div>
    )
    
}