import { ActRecord } from "~/api/acts";
import { ChangeDataStatus, ChangedData } from "./ActDetail";

type Result = {
    current: string[]
}

const handleStatus = (result: Result, values: ChangeDataStatus) => {
    Object.keys(values).map(id => {
        const item = values[Number(id)]
        result.current.push(`ДК №${id}: статус изменен с "${item.from.name}" на "${item.to.name}"`)
    })
}

const handleDeletedDk = (result: Result, values: number[]) => {
    values.map(id => {
        result.current.push(`ДК №${id}: удалена`)
    })
}

export const handleChangeToString = (changedData: any) => {
    const result: Result = {
        current: []
    }
    Object.keys(changedData).map(i => {
        if(i in changedData){
            const data = changedData[i]
            switch(i){
                case "deletedDk": 
                    handleDeletedDk(result, data)
                    break;
                case "status": 
                    handleStatus(result, data)
                    break;
            }
        }
    })

    return result.current.join('\n')
}

export const handleRecordComment = (data: ActRecord[]) => {
    const result: string[] = []
    data.map(i => {
        if(i.comment){
            result.push(`Комментарии к ДК №${i.dkId}: ` + i.comment)
        }
    })

    return result.join('\n')
}