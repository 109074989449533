import { ResponsePromise } from 'ky';
import { ky } from '~/api/kyInstance';
import { Model } from '../records';



export type ModelSto = {
  id: number,
  nameShort: string,
  name: string,
  address: string,
  agentId: number,
  phone: string,
  tsLimit: number,
  autoType: number[],
  lat: number,
  lon: number,
  region_id: number,
  district_id: number,
  comment: string

  mondayHourStart: number,
  mondayMinuteStart: number,
  mondayHourFinish: number,
  mondayMinuteFinish: number,
  tuesdayHourStart: number,
  tuesdayMinuteStart: number,
  tuesdayHourFinish: number,
  tuesdayMinuteFinish: number,
  wednesdayHourStart: number,
  wednesdayMinuteStart: number,
  wednesdayHourFinish: number,
  wednesdayMinuteFinish: number,
  thursdayHourStart: number,
  thursdayMinuteStart: number,
  thursdayHourFinish: number,
  thursdayMinuteFinish: number,
  fridayHourStart: number,
  fridayMinuteStart: number,
  fridayHourFinish: number,
  fridayMinuteFinish: number,
  saturdayHourStart: number,
  saturdayMinuteStart: number,
  saturdayHourFinish: number,
  saturdayMinuteFinish: number,
  sundayHourStart: number,
  sundayMinuteStart: number,
  sundayHourFinish: number,
  sundayMinuteFinish: number,
  
  wednesdayWorkTime: string,
  mondayWorkTime: string,
  tuesdayWorkTime: string,
  thursdayWorkTime: string,
  fridayWorkTime: string,
  saturdayWorkTime: string,
  sundayWorkTime: string

};


export namespace Sto {
 
  class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getAll(): Promise<ModelSto[]> {
      return ky.get(`${this.url}/all`).json();
    }

    public getOrderInfo(params: {
      date: string;
      days: number;
    }): Promise<Model[]> {
      
      return ky
        .get(`${this.url}/orders`, {
          searchParams: [
            ['date', params.date],
            ['days', params.days],
          ],
        })
        .json();
    }

    public getOrderToBigDick(params: {
      date: string;
      days: number;
    }): Promise<Model[]> {
      
      return ky
        .get(`${this.url}/ordersToBigDick`, {
          searchParams: [
            ['date', params.date],
            ['days', params.days],
          ],
        })
        .json();
    }

    public getOrderToMentor(params: {
      date: string;
      days: number;
    }): Promise<Model[]> {
      
      return ky
        .get(`${this.url}/ordersToMentor`, {
          searchParams: [
            ['date', params.date],
            ['days', params.days],
          ],
        })
        .json();
    }

    public getOrderTimes(params:any): Promise<any[]> {
      
      return ky.get(`${this.url}/orderTimes`, {
        searchParams: [
          ['date', params.date],
          ['stoId', params.stoId],
          ['idDkAutoType', params.dkAutoTypeId],
        ],
      })
      .json();;
    }

    public getById(id: number): Promise<ModelSto> {
      return ky.get(`api/dk/sto/${id}`).json();
    }

    public create(values: Partial<ModelSto>): ResponsePromise {

      return ky.post(`${this.url}`, { json: values });
    }

    public update(values: Partial<ModelSto>): ResponsePromise {
      return ky.patch(`${this.url}`, {
        json: values,
      });
    }

    public remove(id: string): ResponsePromise {
      return ky.delete(`${this.url}/${id}`);
    }
  }

  export const controller = new Controller('api/dk/sto');
}
