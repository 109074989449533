import { Records } from "~/api"
import notify from 'devextreme/ui/notify';
import { Model } from "~/api/records";


export const handleDownload = async (dkId: number) => {
    try {
        const blob = await Records.controller.downloadDkPdf(dkId)
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = `Бланк ДК №${dkId}.pdf`
        document.body.appendChild(link)
        link.click()
        link.remove()
    } catch (error: any) {
        notify({
            message: `Упс... Произошла ошибка ${error.message}`,
            position: 'top center',
        }, 'error', 3000);
    }
}

export const isButtonVisible = (e: any, reverse: boolean = false) => {
    const isVisible = Boolean(e.row.data.uploadDk)
    return reverse ? !isVisible : isVisible
}

export const handleDownloadReferral = async (e: any) => {
    try {
        const blob = await Records.controller.downloadReferralToSto(e.row.data.dkId) 
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = `Направление №${e.row.data.dkId}.pdf`
        document.body.appendChild(link)
        link.click()
        link.remove()
    } catch(error: any){
        notify({
            message: `Упс... Произошла ошибка ${error.message}`,
            position: 'top center',
          }, 'error', 3000);
    }
}
  
export const isReferralButtonVisible = (e: any) => {
    return e.row.data.uploadDk == false ? e.row.data.approvedStatusId === 2 : false
}