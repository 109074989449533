import { Common } from "~/types"
import { Model } from "~/api/records"
import { PropsEditProps } from "~/context/PopupContext"
import { Workbook } from "exceljs"
import { exportDataGrid as exportExcelDataGrid} from 'devextreme/excel_exporter';
import { exportDataGrid as exportPDFDataGrid} from 'devextreme/pdf_exporter'
import {saveAs} from 'file-saver';
import { jsPDF } from 'jspdf';
import {font} from '~/assets/css/fonts/Roboto-400' 

type PropsDbClick = {
  e: any, 
  editPopupOpen: (values: PropsEditProps) => void,
  activeRecord?: boolean,
  updateData?: () => void,
}

export const customizeTotalPrice = (data: any) => {
    const result = String(Math.round(data.value * 100)/100).replace('.',',')
    return `${result} ₽`
}

export const onDbClick = ({
  e,
  editPopupOpen,
  activeRecord = false,
  updateData,
}:PropsDbClick) => {

  
    if(e.rowType === "data"){
      const type = Common.TypePopup.RECORD
      const id = e.key.dkId
      editPopupOpen({
        id,
        type,
        activeRecord,
        updateData: updateData ? updateData: ()=>null
      })
    }
  
}

export const getFilteredCommon = (data: Model[]) => {

  let result: Model[] = []

  const allowedStatus: number[] = []
  const disapproveStatus: number[] = [0]

  data.map(i => {
      if( !disapproveStatus.includes(i.approvedStatusId) ){
          result.push(i)
      }
  })

  return result
}

export const getFilteredFinantial = (data: Model[]) => {

  let result: Model[] = []

  const allowedStatus: number[] = [6,7]

  data.map(i => {
      if( allowedStatus.includes(i.approvedStatusId) ){
          result.push(i)
      }
  })
  return result
}

export const handleExportExcel = (e: any, fileName: string) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Main sheet', {pageSetup: {
    orientation: 'landscape',
    scale: 99,
    margins: {
      top: 1.9,
      left: 0.6,
      bottom: 1.9,
      right: 0.6,
      header: 0.8,
      footer: 0.8,
    }
  }});

  let countIndex = 0

  exportExcelDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({gridCell, excelCell}: any) => {
          if (gridCell.rowType === 'data') {
              if (gridCell.column.name === 'number') {
                  countIndex += 1
                  excelCell.value = countIndex;
              }
          }
      },
  }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
          saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${fileName}.${e.format}`);
      });
  });
}

export const handleExportPDF = async(e: any, fileName: string) => {
  const doc = new jsPDF({
    orientation: 'landscape',
  });

  doc.addFileToVFS('Roboto-Regular.ttf', font);
  doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
  doc.setFont('Roboto');

  exportPDFDataGrid({
    jsPDFDocument: doc,
    component: e.component,
    columnWidths: [40, 40, 30, 30, 40],
  }).then(() => {
    doc.save(`${fileName}.${e.format}`);
  });
}

export const onExporting = (e: any, fileName: string) => {
  switch(e.format){
    case 'pdf':
      handleExportPDF(e, fileName)
      break;
    case 'xlsx':
      handleExportExcel(e, fileName);
      break;
  }
  e.cancel = true;
}
