import React, { useEffect, useState } from 'react'
import { bemHelper } from '~/libs'
import {
    DataGrid,
    Column,
    Sorting,
    Scrolling,
    Paging,
    FilterRow,
  } from 'devextreme-react/data-grid';
import { cellRenderIndex } from '~/utils/reportCellLink';
import { History, Records } from '~/api/records';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react';

import './styles.scss'

const cn = bemHelper('item-accordion')

const ItemAccordionHistory = ({id}: {id:number}) => {

    const [records, setRecords] = useState<History[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getData = async ( ) => {
        setIsLoading(true)

        try{
            const result = await Records.controller.getHistoryById(id)
            setIsLoading(false)
            setRecords(result)
        } catch(err: any){
            setIsLoading(false)
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        }
    }

    useEffect(() => {
        window.scrollBy(0,3000);
        getData()
    }, [])


  return (
    <div className={cn('box')}>
        <DataGrid
            dataSource={records}
            allowColumnResizing={true}
            columnAutoWidth={true}
            showBorders
            hoverStateEnabled={true}
            className='data-grid-report'
            showColumnLines
            allowColumnReordering={true}
            noDataText="Нет записей"
        >
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
        <Paging enabled={false} />
        <FilterRow visible={false}/>

        <Column
        caption={'№'}
        width={50}
        cellRender={(data:any) => cellRenderIndex({cellData: data})}
        alignment="left"
        allowEditing={false}
        name="number"
        />

        <Column
        caption="Дата, Время"
        dataType="datetime"
        dataField='actionTimestamp'
        alignment="center"
        allowEditing={false}
        defaultSortOrder='desc'
        />
        <Column
        dataField="action"
        caption='Действие'
        alignment="center"
        allowEditing={false}
        />
        <Column
        dataField="staffFio"
        caption='Инициатор'
        alignment="center"
        allowEditing={false}
        />
        </DataGrid>
        { isLoading && (
            <div className={cn('loader')}>
                <LoadIndicator />
            </div>
        )}

    </div>
  )
}

export default ItemAccordionHistory;