import React, {
    createContext,
    useCallback,
    useReducer,
  } from 'react';
import { ModelNotification } from "~/api/notifications";
import { Notifications } from '~/api';


type Props = {
    children?: React.Component
}

export type PropsSetConnection = {
    connection: boolean,
    connecting: boolean,
}

enum ActionType {
    ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
    CHECK_NOTIFICATION = 'CHECK_NOTIFICATION',
    CHECK_ALL_NOTIFICATION = 'CHECK_ALL_NOTIFICATION',
    FIRST_LOAD_NOTIFICATION = 'FIRST_LOAD_NOTIFICATION',
    SET_CONNECTION_NOTIFICATION = 'SET_CONNECTION_NOTIFICATION'
}
type ReducerState = {
    notifications: ModelNotification[],
    firstLoad: boolean,
    connection: boolean,
    connecting: boolean,
};
  
type ReducerAction = {
    type: ActionType;
    payload?: any;
};

type NotificationContextState = {
    notificationState: ReducerState;
    addNotifications: (data: ModelNotification[], isList?: boolean) => void,
    checkNotification: (id: number) => void,
    checkAllNotifications: (notifications: ModelNotification[]) => void,
    setFirstLoad: (value: boolean) => void,
    setConnection: (
        value: PropsSetConnection
    ) => void,
    
  };


  
const initReducerState = (): ReducerState => {
    return {
        notifications: [],
        firstLoad: true,
        connection: false,
        connecting: false,
    }
}

const reducer = (state: ReducerState, { type, payload }: ReducerAction) => {
    switch (type) {
        case ActionType.ADD_NOTIFICATIONS:

            if (payload.isList){
                return {
                    ...state,
                    notifications: [ ...payload.data.reverse()]
                };
            }

            return {
                ...state,
                notifications: [ ...payload.data.reverse(), ...state.notifications, ]
            };
        case ActionType.CHECK_NOTIFICATION:
            const arr = state.notifications.filter(_i => _i.id !== payload)

            return {
                ...state,
                notifications: arr
            }
        case ActionType.CHECK_ALL_NOTIFICATION:
            return {
                ...state,
                notifications: []
            }
        case ActionType.FIRST_LOAD_NOTIFICATION:
            return{
                ...state,
                firstLoad: payload
            }
        case ActionType.SET_CONNECTION_NOTIFICATION:

            if (payload === state.connection){
                return state
            } else {
                const notifications = !payload ? [] : state.notifications 
                return{
                    ...state,
                    connection: payload.connection,
                    connecting: payload.connecting,
                    notifications
                }
            }

            

        default:
        throw new Error('Invalid reducer action name at auth');
    }
};
  
export const NotificationContext = createContext<NotificationContextState>({
    notificationState: initReducerState(),
    addNotifications: (data: ModelNotification[], isList?: boolean) => null,
    checkNotification: (id: number) => null,
    checkAllNotifications: (notifications: ModelNotification[]) => null,
    setFirstLoad: (value: boolean) => null,
    setConnection: (
        value: PropsSetConnection
    ) => null,
});
  
export const NotificationProvider: React.FC = (props) => {
    const { children } = props;
  
    const [state, dispatch] = useReducer(
      reducer,
      null,
      initReducerState
    );

    const addNotifications = useCallback((data: ModelNotification[], isList: boolean = false) => {
        dispatch({ type: ActionType.ADD_NOTIFICATIONS, payload: {
            data,
            isList
        } });
        
    }, []);

    const checkNotification = useCallback((id: number) => {
        Notifications.controller.sendReadNotify(id)
        dispatch({ type: ActionType.CHECK_NOTIFICATION, payload: id });
        
    }, []);

    const checkAllNotifications = useCallback((notifications: ModelNotification[]) => {

        notifications.map(_i => {
            Notifications.controller.sendReadNotify(_i.id)
        })
        dispatch({ type: ActionType.CHECK_ALL_NOTIFICATION });
    }, []);

    const setFirstLoad = useCallback((value: boolean) => {

        dispatch({ type: ActionType.FIRST_LOAD_NOTIFICATION, payload: value });
    }, []);

    const setConnection = (
        value: PropsSetConnection
        ) => {
        dispatch({ type: ActionType.SET_CONNECTION_NOTIFICATION, payload: value });
    }

    

    return (
      <NotificationContext.Provider
        value={{
          notificationState: state,
          addNotifications,
          checkNotification,
          checkAllNotifications,
          setFirstLoad,
          setConnection
        }}
      >
        {children}
      </NotificationContext.Provider>
    );
  };
