import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { forwardRef } from "react"
import Form, { Item, ButtonItem, GroupItem, Label, CustomRule } from 'devextreme-react/form';
import { FieldDataChangedEvent } from "devextreme/ui/form"
import { Common } from "~/types";
import { colCountByScreen } from './constants';
import { partnersDataSource } from "~/custom-data-sources";
import { bemHelper } from "~/libs";
import './styles.scss'

type Props = {

}

export type GenerateActListFormData = {
    partnerId: number,
    from: Date,
    to: Date,
}

export type GenerateActListFormRef = {
    formData: GenerateActListFormData
}

export const GenerateActListForm = forwardRef<
    GenerateActListFormRef,
    Props 
>(({

}, ref) => {
    const formRef = useRef<Common.FormRef>(null)
    const cn = bemHelper('generate-act-list-form')

    const editorOptions = useMemo(() => {
        return {
            partner: {
                dataSource: partnersDataSource,
                displayExpr: 'name',
                valueExpr: 'id',
            },
            from: {
                onValueChanged: () => {
                    validateDates()
                }
            },
            to: {
                onValueChanged: () => {
                    validateDates()
                }
            }
        }
    },[])
    
    const generateButtonOptions = useMemo(() => {
        return {
            type: 'default',
            text: 'Сформировать',
            disabled: true,
            useSubmitBehavior: true,
        }
    }, [])

    const handleDataChanged = (e: FieldDataChangedEvent) => {
        const button = formRef.current?.instance.getButton('submit')
        button?.option('disabled', !e.component.validate().isValid)
    }

    const validateDates = () => {
        const formData: Partial<GenerateActListFormData> = formRef.current?.instance.option('formData')
        if(!formData.from || !formData.to){
            return false;
        }
        const days = Math.floor((formData.to.getTime() - formData.from.getTime())/ (1000 * 60 * 60 * 24) + 1)
        const isValid = days >= 0

        const editorFrom = formRef.current?.instance.getEditor('from')
        const editorTo = formRef.current?.instance.getEditor('to')
        const button = formRef.current?.instance.getButton('submit')
        
        editorFrom?.option('isValid', isValid)
        editorTo?.option('isValid', isValid)
        if(!isValid){
            button?.option('disabled', true)
        }
    }

    useImperativeHandle(ref, () => {
        return {
            formData: formRef.current?.instance.option('formData')
        }
    })
    
    return (
        <Form 
            ref={formRef}
            colCountByScreen={colCountByScreen}
            onFieldDataChanged={handleDataChanged}
            >
            <Item 
                dataField="partnerId" 
                label={{text: 'Партнер'}} 
                editorOptions={editorOptions.partner} 
                editorType="dxSelectBox"
                isRequired
            />

            <Item 
                dataField="from" 
                label={{text: 'От'}}  
                editorType="dxDateBox" 
                editorOptions={editorOptions.from}
                isRequired
                />

            <Item 
                dataField="to" 
                label={{text: 'До'}}  
                editorType="dxDateBox" 
                editorOptions={editorOptions.to}
                isRequired
                />

            <ButtonItem
                name='submit'
                cssClass={cn('button')}
                verticalAlignment={'bottom'}
                buttonOptions={generateButtonOptions}
                />
        </Form>
    )
})