import React, { useContext, useEffect, useState } from 'react'
import EditBase from '~/ui/atoms/EditBase';


import { ComponentProps } from '~/ui/atoms/PopupEdit';
import find from 'list-files'
import { Link, useHistory, useParams } from 'react-router-dom';

type ParamsProps = {
    instructionName: string
}

export const PageComponent: React.FC<ComponentProps> = ({manageRef }) => {

    manageRef?.current?.setIsLoading(false)
    const { instructionName } = useParams<ParamsProps>()
    const [ html, setHtml ] = useState<string>('')

    fetch(`data/instructions/${instructionName}/text.html`).then(async (response) => {

      const string = await response.text()

      setHtml(string)
    })

    return (

      <div dangerouslySetInnerHTML={{ __html: html }}>
        
      </div>
    
    )

}


const Page = () => {
    
    return (
        <EditBase Component={PageComponent}/>
    )
  }
  
  export default Page