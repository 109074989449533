import { Sto } from "~/api";
import { createCustomStore } from "../utils";

export const stoAllDataSource = createCustomStore({
    key: 'id',
    loadMode: 'raw',
    load: async () => {
      const data = await Sto.controller.getAll()
      return data;
    },
})