import React, { useContext, useEffect, useRef, useState } from 'react';

import {
    DataGrid,
    Column,
    Summary, 
    TotalItem,
    Sorting,
    Scrolling,
    Paging,
    Export,
    FilterRow
} from 'devextreme-react/data-grid';

import ReportBase, { PropsComponet } from '~/ui/atoms/ReportBase';
import CustomStore from 'devextreme/data/custom_store';
import { Sto } from '~/api';
import { memo } from 'react';
import { formatDate } from 'devextreme/localization';
import { AuthContext, PopupContext } from '~/context';
import { Common } from '~/types';
import {  ModelSto } from '~/api/sto';
import { Model, Model as ModelRecord } from '~/api/records';
import notify from 'devextreme/ui/notify';
import { CellRenderLink, cellRenderIndex } from '~/utils/reportCellLink';
import { customizeTotalPrice, getFilteredFinantial, onDbClick, onExporting } from '../service';
import { checkPermission } from '~/utils/checkPermission';



const OperatorComponent: React.FC<PropsComponet> = memo(({data, days, date, title, allSto, updateData}) => {

    const saleAmountFormat = { style: 'currency', currency: 'RUB', useGrouping: true, minimumSignificantDigits: 1 };

    const { authState } = useContext(AuthContext);
    
    let startDate = new Date(date.getTime())
    let endDate = new Date(date.getTime())

    endDate.setDate(endDate.getDate() + days - 1)
    const { 
        editPopupOpen
    } = useContext(PopupContext)

    const onExport = (e:any) => {
        const fileName= `с ${formatDate(startDate, 'dd.MM.yyyy')} по ${formatDate(endDate, 'dd.MM.yyyy')} ${title}` 
        onExporting(e, fileName)
    }

    return (
        <>
            <DataGrid
                dataSource={data}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                showBorders
                showColumnLines
                className='data-grid-report'
                columnAutoWidth={true}
                onCellDblClick={(e) => {onDbClick({e: e, editPopupOpen})}}
                allowColumnReordering={true}
                noDataText="Пусто"
                onExporting={onExport}
            >

                <Sorting mode="multiple" />
                <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
                <Paging enabled={false} />
                <FilterRow visible={true}
                    applyFilter='auto' />

                <Column
                    caption={'№'}
                    width={50}
                    alignment="left"
                    cellRender={(data:any) => cellRenderIndex({cellData: data, editPopupOpen})}
                    name="number"
                    />

                <Column
                    caption={'№ Заявки'}
                    alignment="center"
                    dataField='dkId'
                    cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen})}
                    name="orderNumber"
                    />

                <Column
                  caption='Адрес СТО'
                  alignment="center"
                  dataField="stoAddress"
                  visible={allSto}
                  cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen, activeRecord: true, updateData})}
                    />
                
                <Column
                    dataField="lineNumber"
                    caption={'Линия'}
                    width={90}
                    alignment="center"
                    />
                <Column
                    caption="Дата, Время"
                    dataType="datetime"
                    dataField='dateReceiptStart'
                    defaultSortOrder='desc'
                    alignment="center"
                    />
                <Column caption='TC'alignment="center">
                    <Column
                        dataField="regNumber"
                        caption='Гос.номер'
                        alignment="center"
                        cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen})}
                        />
                    <Column
                        dataField="dkAutoTypeName"
                        caption='Кат'
                        alignment="center"
                        />
                </Column>
                <Column
                    dataField="priceSto"
                    caption='Стоимость'
                    dataType="number"
                    format={saleAmountFormat}
                    alignment="center"
                    />
                
                <Column
                    dataField="agentName"
                    caption='Канал'
                    cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen, isOperator: true, agentId: authState.userData?.agent.id})}
                    alignment="center"
                    />

                <Column
                    dataField="approvedStatusName"
                    caption='Статус'
                    alignment="center"
                    />
                
                <Summary>
                    <TotalItem
                        column="priceSto"
                        summaryType="sum"
                        customizeText={customizeTotalPrice}
                        displayFormat='{0}'
                        valueFormat={saleAmountFormat}/>
                    <TotalItem
                        column="lineNumber"
                        summaryType="sum"
                        displayFormat='Итог:'
                         />

                </Summary>

                <Export 
                    enabled={true} 
                    />
            </DataGrid>
        </>
    )
})

const AgentComponent: React.FC<PropsComponet> = memo(({data, days, date, title}) => {

    const { authState } = useContext(AuthContext);
    const { 
        editPopupOpen
    } = useContext(PopupContext)

    const saleAmountFormat = { style: 'currency', currency: 'RUB', useGrouping: true, minimumSignificantDigits: 1 };

    let startDate = new Date(date.getTime())
    let endDate = new Date(date.getTime())

    endDate.setDate(endDate.getDate() + days - 1 )

    const calculateProfit = (data: ModelRecord) => {
        return data.priceClient - data.priceSto
    }

    const onExport = (e:any) => {
        const fileName= `с ${formatDate(startDate, 'dd.MM.yyyy')} по ${formatDate(endDate, 'dd.MM.yyyy')} ${title}` 
        onExporting(e, fileName)
    }

    
    return (
        <>
            <DataGrid
                dataSource={data}
                allowColumnResizing={true}
                columnAutoWidth={true}
                hoverStateEnabled={true}
                showBorders
                showColumnLines
                className='data-grid-report'
                allowColumnReordering={true}
                noDataText="Пусто"
                onCellDblClick={(e) => {onDbClick({e: e, editPopupOpen})}}
                onExporting={onExport}
            >

                <Sorting mode="multiple" />
                <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
                <Paging enabled={false} />

                <FilterRow visible
                    applyFilter='auto' />

                <Column
                    caption={'№'}
                    width={50}
                    alignment="left"
                    name="number"
                    cellRender={(data:any) => cellRenderIndex({cellData: data, editPopupOpen})}
                    />

                <Column
                    caption={'№ Заявки'}
                    alignment="center"
                    dataField='dkId'
                    cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen})}
                    name="orderNumber"
                    />

                { authState.userData?.roles.includes(Common.SubPermissions.ROLE_BROKER_DIRECTOR) && (
                    <Column
                        dataField="staffFio"
                        caption='Сотрудник'
                        alignment="center"
                        />
                )}

                <Column
                    caption='СТО'
                    alignment="center"
                    dataField="stoAddress"
                    cellRender={(data: any) => CellRenderLink({
                        cellData: data,
                        editPopupOpen,
                    })}
                    />

                <Column
                    caption="Дата, Время"
                    dataType="datetime"
                    alignment="center"
                    dataField='dateReceiptStart'
                    defaultSortOrder='desc'
                    />
                <Column caption='TC'alignment="center">
                    <Column
                        dataField="regNumber"
                        caption='Гос.номер'
                        alignment="center"
                        cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen})}
                        />
                    <Column
                        dataField="dkAutoTypeName"
                        caption='Кат'
                        alignment="center"
                        />
                </Column>
                <Column
                    dataField="priceSto"
                    caption='Стоимость'
                    dataType="number"
                    format={saleAmountFormat}
                    alignment="center"
                    />
                <Column
                    dataField="priceClient"
                    caption='Получено от клиента'
                    dataType="number"
                    format={saleAmountFormat}
                    alignment="center"
                    />
                <Column
                    name='profit'
                    caption='Прибыль'
                    dataType="number"
                    calculateCellValue={calculateProfit}
                    format={saleAmountFormat}
                    alignment="center"
                    />

                <Column
                    dataField="approvedStatusName"
                    caption='Статус'
                    alignment="center"
                    />

                <Summary>
                    <TotalItem
                        column="priceClient"
                        summaryType="sum"
                        displayFormat='{0}'
                        customizeText={customizeTotalPrice}
                        valueFormat={saleAmountFormat}/>
                    <TotalItem
                        column="priceSto"
                        summaryType="sum"
                        customizeText={customizeTotalPrice}
                        displayFormat='{0}'
                        valueFormat={saleAmountFormat}/>
                    <TotalItem
                        column="profit"
                        summaryType="sum"
                        customizeText={customizeTotalPrice}
                        displayFormat='{0}'
                        valueFormat={saleAmountFormat}/>
                    <TotalItem
                        column="stoNameShort"
                        summaryType="sum"
                        displayFormat='Итог:'
                        alignment='left'
                         />

                </Summary>

                <Export 
                    enabled={true} 
                    />
            </DataGrid>
            </>
    )
})



const FinantialReport: React.FC = () => {

    const dataSource = new CustomStore({
        key: 'dkId',
        load: async (loadOptions) => {

            let data: Model[] = []
            if(loadOptions.searchValue.withSubAgent){
                data = await Sto.controller.getOrderToMentor({
                    date: loadOptions.searchValue.date,
                    days: loadOptions.searchValue.days
                }); 
            } else {
                data = await Sto.controller.getOrderInfo({
                    date: loadOptions.searchValue.date,
                    days: loadOptions.searchValue.days
                }); 
            }

            return getFilteredFinantial(data);
        },
        errorHandler: (error: any) => {
            notify({
              message: `Упс... Произошла ошибка ${error.message}`,
              position: 'top center',
            }, 'error', 3000);
          },
        
    })

    const { authState: {
        userData
    } } = useContext(AuthContext);

    let Component = AgentComponent
    

    if (checkPermission(userData?.roles, Common.Permissions.ROLE_AGENT)){
        Component = AgentComponent
    } else {
        Component = OperatorComponent
    }


    return (
        <ReportBase title="Финансовый отчет" Component={Component} dataSource={dataSource} isFinantial/> 
    )
}


export default React.memo(FinantialReport)
