import { ky } from '~/api/kyInstance';

export type Model = {
    id?: number,
    brand?: string,
    model?: string,
    typeId?: string,
    greencardTypeId?: string,
    dkAutoTypeId?: number,
    enginesTypeId?: number,
    yearBuilder?: number,
    power?: number,
    vin?: string,
    colorId?: number,
    price?: number,
    regNumber?: string,
    clientId?: number,
    comment?: string
};

export type ModelEngineTypes = {
  id: number,
  name: string,
  comment: string
};


export namespace Vehicle {


  export class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getById(id: number|undefined): Promise<Model> {
      return ky.get(`${this.url}/${id}`).json();
    }

    public update(values: any): Promise<Model> {

      return ky.patch(`${this.url}`, {json: values}).json();
    }

    public create(values: any): Promise<Model> {
      return ky.post(`${this.url}`, {json: values}).json();
    }

    public createBrandAndModel(values: any): Promise<any> {
      return ky.post(`${this.url}/brand`, {json: values});
    }

    public getAllBrands(): Promise<string[]> {
      return ky.get(`${this.url}/brands`).json();
    }

    public getModelsByBrand(brand: string = 'Volkswagen'): Promise<string[]> {
      return ky.get(`${this.url}/models/${brand}`).json();
    }

    public getEnginesTypes(): Promise<ModelEngineTypes[]> {
      return ky.get(`${this.url}/enginesTypes`).json();
    }
  }

  export const controller = new Controller('api/vehicle');
}
