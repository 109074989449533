import { ky } from '~/api/kyInstance';

export type RegionModel = {
  id: number;
  name: string;
  comment: string;
};

export type DistrictModel = {
  id: number;
  name: string;
  region_id: number,
  comment: string;
};

export namespace Addressing {
  
  export class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getRegions(): Promise<RegionModel[]> {
      return ky.get(`${this.url}/regions`).json();
    }

    public getDistrictsByRegion(region_id: number = 1): Promise<DistrictModel[]> {
      return ky.get(`${this.url}/districts/${region_id}`).json();
    }
  }

  export const controller = new Controller('api');
}
