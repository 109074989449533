import DataGrid, { Column, FilterRow, Paging, Scrolling, Sorting } from "devextreme-react/data-grid"
import React, { FC, forwardRef, useEffect, useMemo } from "react"
import { CellDblClickEvent } from "devextreme/ui/data_grid"
import { ActTableProps, ActsDataGridRef } from "~/ui/atoms/ActsBase"
import { Act } from "~/api/acts"
import { useHistory, useLocation } from "react-router"
import { Button } from "devextreme-react"


export const ActsTable = forwardRef<
    ActsDataGridRef,
    ActTableProps
>(({
    updateData,
    pushToActDetail,
    excludeColumn=[],
    height,
}, ref) => {

    const handleDbClick = (e: CellDblClickEvent<Act[], Act>) => {
        if(e.rowType == 'data'){
            pushToActDetail(e.key.aktId)
        }
    }

    const renderNumberCell = (data: any) => {
        return (
            <a onClick={() => pushToActDetail(data.data.aktId)}>
                {data.row.loadIndex + 1}
            </a> 
          )
    }

    const saleAmountFormat = useMemo(() => {
        return {
            style: 'currency', 
            currency: 'RUB', 
            useGrouping: true, 
            minimumSignificantDigits: 1
        }
    },[])

    return (
        <DataGrid
            ref={ref}
            allowColumnResizing={true}
            columnAutoWidth={true}
            showBorders
            hoverStateEnabled={true}
            showColumnLines
            allowColumnReordering={true}
            onCellDblClick={handleDbClick}
            noDataText="Нет записей"
            height={height}
        >
            <Sorting mode="multiple" />
            <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
            <Paging enabled={false} />
            <FilterRow visible={true}
                applyFilter='auto' />

            <Column
                caption={'№'}
                width={50}
                cellRender={renderNumberCell}
                alignment="left"
                visible={!excludeColumn?.includes('number')}
                name="number"
                />

            <Column
                caption={'№ Акта'}
                alignment="center"
                width={70}
                dataField='aktId'
                name="orderNumber"
                visible={!excludeColumn?.includes('aktId')}
                />

            <Column
                caption="Дата"
                dataField='date'
                defaultSortOrder='desc'
                alignment="center"
                visible={!excludeColumn?.includes('date')}
                />
            
            <Column 
                dataField="partnerName"
                caption='Контрагент'
                alignment="center"
                visible={!excludeColumn?.includes('partnerName')}
                />
            
            <Column
                dataField="sum"
                caption='Сумма по акту'
                dataType="number"
                format={saleAmountFormat}
                alignment="center"
                visible={!excludeColumn?.includes('sum')}
            />
            
            <Column
                dataField="statusName"
                caption='Статус'
                alignment="center"
                visible={!excludeColumn?.includes('statusName')}
            />

            <Column
                dataField="comment"
                caption='Комментарий'
                alignment="center"
                visible={!excludeColumn?.includes('comment')}
            />

        </DataGrid>
    )
})