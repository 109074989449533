import { ROUTES as Authorization } from './Authorization';
import { ROUTES as Records } from './Records';
import { ROUTES as Edit } from './Edit'
import { ROUTES as Stations } from './Stations'
import { ROUTES as Reports } from './Reports';
import { ROUTES as Statistics } from './Statistics'
import { ROUTES as Profile } from './Profile'
import { ROUTES as Instructions } from './Instructions'
import { ROUTES as Acts } from './Acts'


export const authRoutes = [...Authorization];
export const internalRoutes = [
    ...Records, 
    ...Stations, 
    ...Reports, 
    ...Statistics, 
    ...Profile, 
    ...Edit, 
    ...Instructions, 
    ...Acts,
];
