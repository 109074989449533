export const weekDays = [
    {
        key: "monday",
        name: "Понедельник"
    },
    {
        key: "tuesday",
        name: "Вторник"
    },
    {
        key: "wednesday",
        name: "Среда"
    },
    {
        key: "thursday",
        name: "Четверг"
    },
    {
        key: "friday",
        name: "Пятница"
    },
    {
        key: "saturday",
        name: "Суббота"
    },
    {
        key: "sunday",
        name: "Воскресенье"
    },
]
