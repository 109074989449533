import React, { forwardRef, useImperativeHandle, useState } from "react"


type Props = {}

export type RightElementTitleRef = {
    setElement: (value: React.ReactNode) => void,

}

export const RightElementTitle = forwardRef<
    RightElementTitleRef,
    Props
>(({}, ref) => {
    const [ Element, setElement ] = useState<React.ReactNode>()

    useImperativeHandle(ref, () => ({
        setElement: setElement
    }))

    return (
        <div>
            {Element && Element}
        </div>
    )
})