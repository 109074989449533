import React, { memo, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { bemHelper } from '~/libs';
import './styles.scss'
import { ProfilesList } from './components';
import { AuthContext } from '~/context';
import { useHistory } from 'react-router';

const cn = bemHelper('page-select-profile');

export const SelectProfile: React.FC = memo((props) => {

    const { t, ready: i18nReady } = useTranslation('select-profile', {
        useSuspense: false,
    });
    const history = useHistory();
    const {
        authState: { 
          isManyProfiles,
        },
      } = useContext(AuthContext);

    useEffect(() => {
        if (!isManyProfiles) {
            history.push('/app/records')
        } 
    }, [isManyProfiles])

    return (
        <div className={cn('')}>
            <ProfilesList />
        </div>
    )
})