import { Act } from "~/api/acts"

export const filterInAndOutActs = (data: Act[]) => {
    const incoming: any[] = []
    const outgoing: any[]= []
    data.map(i => {
        if(i.owner){
            outgoing.push(i)
        } else {
            incoming.push(i)
        }
    })

    return {incoming, outgoing}
}