import React, { Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import { authRoutes, internalRoutes } from '~/routes';
import { FadeInRoute, Loader } from '~/ui/atoms';
import { ExternalLayout, InternalLayout } from '~/ui/templates';
import { ErrorBoundary } from '~/features';
import { initialLocationStorage } from './libs';
import { checkPermission } from './utils/checkPermission';



type Props = {
    authInProgress: boolean,
    isAuthorized: boolean,
    permission: string[] | undefined,
}

const Router: React.FC<Props> = ({authInProgress, isAuthorized, permission}) => {

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/app/records" />
        <Route
          path="/auth"
          render={() => (
            <ExternalLayout>
              {authRoutes.map(({ Component, exact, path }) => (
                <Route
                  key={path}
                  component={Component}
                  exact={exact}
                  path={path}
                />
              ))}
            </ExternalLayout>
          )}
        />
        <Route
          path="/app"
          render={() => {
            if (!authInProgress && !isAuthorized) {
              initialLocationStorage.store()
              return <Redirect to="/auth" />;
            }
            if (authInProgress) {
              return <Loader centered size="42px" />;
            }
  
            return (
              <InternalLayout>
                <ErrorBoundary>
                  <Suspense fallback={<Loader centered />}>
                    {internalRoutes.map(({ Component, exact, path, permissionComponent }) => {
                      
                      if (checkPermission(permission, permissionComponent)){
                        return <FadeInRoute
                          key={path}
                          Component={Component}
                          exact={exact}
                          path={path}
                        />
                      }
                    })}
                  </Suspense>
                </ErrorBoundary>
              </InternalLayout>
            );
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default React.memo(Router)