import CustomStore from 'devextreme/data/custom_store';
import { TireBrand } from "~/api/tirebrand";

export const tireBrandsDataSource = new CustomStore({
    key: 'id',
    loadMode: 'raw',
    load: async () => {
      const data = await TireBrand.controller.getAll()
      return data;
    },
})