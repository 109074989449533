import React, { MutableRefObject, useCallback, useContext, useState } from 'react';
import { Header, SideMenu } from './organisms';
import { useNavGroups } from '~/hooks';
import { bemHelper } from '~/libs';
import './styles.scss';
import { AddRecordPopup } from '~/ui/atoms/AddRecordPopup';
import PopupEdit from '~/ui/atoms/PopupEdit';
import { AuthContext, PopupContext } from '~/context';
import PopupOffer from '~/ui/atoms/PopupOffer';
import { Common } from '~/types';

const cn = bemHelper('internal-layout');

type Props = {
}

export const InternalLayout: React.FC<Props> = (props) => {
  const { children } = props;

  const [menuActive, setMenuActive] = useState(true);
  const [menuProfile, setMenuProfile] = useState(false)
  const [menuNotifications, setMenuNotifications] = useState(false)

  const { authState} = useContext(AuthContext)


  const { isNavGroupsLoaded, navLinks } = useNavGroups();

  const toggleMenu = useCallback(() => {
    setMenuActive((prevActive) => !prevActive);
  }, []);

  const toggleProfileMenu = useCallback(() => {
    setMenuProfile((prevActive) => !prevActive);
  }, []);

  const toggleNotificationsMenu = useCallback(() => {
    setMenuNotifications((prevActive) => !prevActive);
  }, []);

  return (
    <main className={cn(undefined, { 'menu-hidden': !menuActive })}>
      {!authState.authInProgress && authState.isAuthorized  &&  (
        <>
        {authState.userData?.roles.includes(Common.Permissions.ROLE_STO_ADMINISTRATOR) && (
          <AddRecordPopup /> 
        )}
        <PopupEdit/>
        <PopupOffer/>
        </>
      )}
      
      <Header
        menuActive={menuActive}
        mix={cn('header')}
        toggleMenu={toggleMenu}
        toggleProfileMenu={toggleProfileMenu}
        menuProfile={menuProfile}
        menuNotifications={menuNotifications}
        toggleNotificationsMenu={toggleNotificationsMenu}
      />
      <div className={cn('side-menu-mask', { visible: menuActive })}>
        <SideMenu
          isNavGroupsLoaded={isNavGroupsLoaded}
          mix={cn('side-menu')}
          navLinks={navLinks}
          toggleMenu={toggleMenu}
          menuActive={menuActive}
        />
      </div>
      <div className={cn('content')}>
        
        {children}
      </div>
    </main>
  );
};
