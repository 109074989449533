import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { EditVehicleComponent } from '../Edit/EditVehicle/index';
import { bemHelper } from '~/libs';

import { EditRecordComponent } from '~/ui/atoms/Edit/EditRecord';
import { EditClientIndividualComponent } from '~/ui/atoms/Edit/EditClientIndividual/index';
import { EditClientEntityComponent } from '../Edit/EditClientEntity/index';
import { Common } from '~/types';
import { CustomPopup } from '~/ui/atoms/CustomPopup';
import { EditStationComponent } from '../Edit/EditStation';
import { PropsComponet } from '../ReportBase/index';
import { usePressKey } from '~/hooks/usePressKey';
import { PopupContext } from '~/context';
import { EditAgentComponent } from '~/ui/atoms/Edit/EditAgent';
import { Toolbar } from 'devextreme-react';

type Props = {

}

export type ManageRef = {
    toolbar?: Toolbar['instance'],
    setRightElementTitle?: (value: React.ReactNode) => void,
    setIsLoading: (value: boolean) => void,
    setError: (value: string) => void
  }

export type ComponentProps = {
    handleClose: () => void,
    id?: number,
    manageRef?: React.RefObject<ManageRef>,
    fromReport?: boolean,
    activeRecord?: boolean,
    updateData?: () => void,
}


interface PopupEditContextType {
    setRightElementTitle: (value: React.ReactNode | null) => void;
    rightElementTitle: React.ReactNode | null,
  }

const defaultState = {
    rightElementTitle: null,
    setRightElementTitle: () => null,
};

export const PopupEditContext = createContext<PopupEditContextType>(defaultState);

const PopupEdit: React.FC<Props>= memo(({}) => {
    const { popupState: {
        editPopup: {
            visible,
            activeRecord,
            type,
            id,
            updateData,
        }},
        editPopupClose
     } = useContext(PopupContext);

    const customPopupRef = useRef<ManageRef>(null)

    const [ rightElementTitle, setRightElementTitle ] = useState<React.ReactNode | null>()

    const handleClose = useCallback(() => {
        editPopupClose()
    },[])

    const value = {rightElementTitle, setRightElementTitle}
    
    const isPressedEsc = usePressKey('Escape', handleClose)

    const [ title, Component ] = useMemo(() => {
        switch (type){
            case Common.TypePopup.RECORD:
                return [`Запись №${id}`, EditRecordComponent ]
            case Common.TypePopup.VEHICLE:
                return ['Сведения о ТС', EditVehicleComponent ]
            case Common.TypePopup.CLIENT_ENTITY:
                return ['Сведения о Юр. лице', EditClientEntityComponent ]
            case Common.TypePopup.CLIENT_INDIVIDUAL:
                return ['Сведения о Физ. лице', EditClientIndividualComponent ]
            case Common.TypePopup.STATION:
                return ['Сведения о СТО', EditStationComponent ]
            case Common.TypePopup.AGENT:
                return ['Сведения об Агенте', EditAgentComponent ]
            default:
                return ['', EditClientIndividualComponent]
        }
    }, [type])
    

    useEffect(() => {
        if (!type){
            customPopupRef.current?.setIsLoading(true)
            customPopupRef.current?.setError('')
        }
    }, [type])

    return (
        <PopupEditContext.Provider value={value}> 
            <CustomPopup
                ref={customPopupRef}
                handleClose={handleClose}
                visible={visible}
                title={title}
                >
                <Component 
                    manageRef={customPopupRef}
                    id={id} 
                    handleClose={handleClose} 
                    fromReport 
                    activeRecord={activeRecord}
                    updateData={updateData}
                    />        
            </CustomPopup>
            
        </PopupEditContext.Provider>
    )
})


export default PopupEdit