import React, { useContext, useEffect, useState } from 'react'
import {  useParams } from 'react-router-dom';

import Form, { Item, ButtonItem, GroupItem } from 'devextreme-react/form';

import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { Agent, Client } from '~/api';
import { validationRules } from '~/routes/Records/services';
import { EntityModel } from '../../../../api/client/index';
import { ComponentProps } from '~/ui/atoms/PopupEdit';
import { colSpanItem } from '../service';
import { useWindowSize } from '~/hooks/useWindowSize';
import { AgentModel } from '~/api/agent';
import { AuthContext } from '~/context';
import { Common } from '~/types';
import { ToolbarButton } from '../../CustomPopup';




export const EditAgentComponent: React.FC<ComponentProps> = ({
    handleClose, 
    updateData, 
    manageRef,
    id, 
    fromReport=false, 
    activeRecord=false
}) => {

    const { urlId } = useParams<any>()
    const { t } = useTranslation('agent');

    const [data, setData] = useState<AgentModel>({})
    const { authState: {userData}} = useContext(AuthContext)
    const [form, setForm] = useState<any>()
    const size = useWindowSize()

    const isBrokerDirector = userData?.roles.includes(Common.SubPermissions.ROLE_BROKER_DIRECTOR)
    const readonly = (userData?.agent.id === data.id && isBrokerDirector) ? activeRecord ? false : fromReport : true

    const colCountByScreen = fromReport ? {
        lg: 2,
        md: 2,
        sm: 2,
        xs: 1,
    } : {
        lg: 5,
        md: 4,
        sm: 3,
        xs: 1,
    }

    const EditorOptions = {

        name: {
            with: '100%',
            readOnly: true
        },
        address: {
            with: '100%',
            readOnly: readonly
        },
        email: {
            with: '100%',
            readOnly: readonly
        },
        phone: {
            with: '100%',
            mask: '+0 (X00) 000-00-00', 
            maskChar: '_',
            maskRules: { X: /[02-9]/ },
            readOnly: readonly
        },
        comment: {
            with: '100%',
            readOnly: readonly
        },
    }

    const getData = async () => {
        try{
            const result = await Agent.controller.getAgentById(fromReport ? id : urlId)
            form.updateData(result)
        } catch(err:any){
            manageRef?.current?.setError(err.message)
        }
        
        manageRef?.current?.setIsLoading(false)
    }

    const handleSubmit = async () => {
    
        
        try{
            await Agent.controller.updateAgent(data)
            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
              }, 'success', 3000);

            if(updateData){
                updateData()
            }

            handleClose()

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        } 
    }

    const handleDataChange = (e: any) => {
        const button = form.getButton('submit')

        if(button){
            if(e.component.validate().isValid){
                button.option('disabled', false)
            } else{
                button.option('disabled', true)
            }
        }
    }

    useEffect(() => {
        if ((id || urlId) && form){
            manageRef?.current?.setIsLoading(true)
            getData()
        }
    }, [form, id, urlId])

    useEffect(() => {
        if (form){
            manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`, false)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.options.onClick`, handleSubmit)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, !readonly)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, readonly)
        }
    }, [data, form])
    
    return (
            <form >
                <Form
                    onContentReady={e => e.component.validate()}
                    formData={data}
                    onFieldDataChanged={handleDataChange}
                    onInitialized={(e:any ) => {setForm(e.component)}}
                    id="form">
                    
                    <GroupItem colCountByScreen={colCountByScreen}>
                        <Item 
                            dataField="name" 
                            label={{text: t('name')}} 
                            editorOptions={EditorOptions.name} 
                            isRequired
                            />
                        <Item 
                            dataField="nameShort" 
                            label={{text: t('nameShort')}} 
                            editorOptions={EditorOptions.name} 
                            />
                            
                        <Item 
                            dataField="address" 
                            colSpan={2}
                            label={{text: t('address')}} 
                            editorOptions={EditorOptions.address} 
                            isRequired
                            />
                        <Item 
                            dataField="phone" 
                            label={{text: t('phone')}} 
                            editorOptions={EditorOptions.phone} 
                            />
                        <Item 
                            dataField="email" 
                            label={{text: t('email')}} 
                            editorOptions={EditorOptions.email}
                            validationRules={validationRules.email}
                            />
                        <Item 
                            dataField="comment" 
                            colSpan={colSpanItem(size, colCountByScreen)}
                            label={{text: t('comment')}} 
                            editorOptions={EditorOptions.comment} 
                            />
                    </GroupItem>
                    { !fromReport && (
                        <ButtonItem
                            colSpan={colSpanItem(size, colCountByScreen)}
                            name='submit'
                            visible={!readonly}
                            buttonOptions={{
                                type: 'default',
                                text: 'Сохранить',
                                onClick: () => handleSubmit()
                            }}
                            />
                    )}
                </Form>
            </form>
    )
}