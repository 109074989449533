import React, { memo, useEffect, useState } from 'react'

import { SelectBox } from 'devextreme-react';
import './styles.scss'
import { bemHelper } from '~/libs';
import { getProfitByPeriod } from '../../services';
import CustomChart from '~/ui/atoms/CustomChart';
import { Model } from '~/api/records';

export type PropsComponet = {
    data: Model[],
    days: number,
    date: Date,
    title?: string,

}

const ProfitChart: React.FC<PropsComponet> = memo(({data, date, days}) => {

    const cn = bemHelper('profit-chart');
    const [ period, setPeriod ] = useState(1)
    const [ profitData, setProfitData ] = useState<any[]>([])

    useEffect(() => {
        setProfitData(getProfitByPeriod(data, date, days, period))
    }, [data])


    const items = [
        {
            id: 1,
            name: 'Месяц'
        },
        {
            id: 2,
            name: 'Год'
        },
    ]

    
    const handleChangeSelectBox = (e: any) => {
        setPeriod(e.value)
        setProfitData(getProfitByPeriod(data, date, days, e.value))
    }


    return (
        <div className={cn('')}>
            <div className={cn('select-period')}>
                <SelectBox 
                        items={items} 
                        displayExpr="name"
                        valueExpr="id"
                        defaultValue={1}
                        name='period'
                        width={'100%'}
                        onValueChanged={handleChangeSelectBox} 
                        />
            </div>

            <CustomChart
                title="Соотношение прибыли" 
                seriesName="прибыль"
                valueField='profit'
                argumentField='period'
                isRuble
                data={profitData}
                />
            
        </div>
    )
})

export default ProfitChart