import { Partner, Records } from "~/api";
import CustomStore from 'devextreme/data/custom_store';

export const dkStatusDataSource = new CustomStore({
    key: 'id',
    loadMode: 'raw',
    load: async () => {
      const data = await Records.controller.getAllStatus()
      return data;
    },
})