const TOKEN_KEY = 'authToken';
const AGENT_ID_KEY = 'authAgentId';
const STO_ID_KEY = 'authStoId';

const store = (STORAGE_KEY:string): ((value: string)=>void) => {
  return (value: string) => {localStorage.setItem(STORAGE_KEY, value)};
};

const get = (STORAGE_KEY:string): (()=>string | null)=> {
  return () => localStorage.getItem(STORAGE_KEY);
};

const remove = (STORAGE_KEY: string): (()=>void) => {
  return () => {localStorage.removeItem(STORAGE_KEY)};
};

const prestore = (STORAGE_KEY:string): ((params: {login: string, password: string})=>void) => {

  return (params: {login: string, password: string}): void => {
    localStorage.setItem(STORAGE_KEY, btoa(`${params.login}:${params.password}`));
  };

}


export const authTokenStorage = { get: get(TOKEN_KEY), store: store(TOKEN_KEY), remove: remove(TOKEN_KEY), prestore: prestore(TOKEN_KEY) };

export const authAgentIdStorage = { get: get(AGENT_ID_KEY), store: store(AGENT_ID_KEY), remove: remove(AGENT_ID_KEY) };

export const authStoIdStorage = { get: get(STO_ID_KEY), store: store(STO_ID_KEY), remove: remove(STO_ID_KEY) };
