import { useCallback, useContext } from 'react';
import { AuthContext } from '~/context';
import { Common } from '~/types';


export const usePermissions = () => {
  const {
    authState: { userData },
  } = useContext(AuthContext);

  return userData?.roles;
};
