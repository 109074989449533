import React, { forwardRef, useContext, useEffect, useImperativeHandle } from "react"
import { Form } from "devextreme-react"
import { ButtonItem, EmptyItem, GroupItem, Item } from "devextreme-react/form"
import { useMemo, useRef } from "react"
import { colSpanItem } from "~/ui/atoms/Edit/service"
import { useWindowSize } from "~/hooks/useWindowSize"
import notify from 'devextreme/ui/notify';
import { AuthContext } from "~/context"
import { useWeekHours } from "../../../../hooks"

export type AddTimeBlockData = {
    date: Date,
    timeStart?: Date,
    timeFinish?: Date,
    allDay?: boolean,
}

export type AddTimeBlockManagerFormRef = {
    resetForm: () => void,
    updateData: (data: any) => void,
}

type Props = {
    handleClose: () => void,
    onSubmit: (forData: AddTimeBlockData) => void,
    defaultValues: Record<string, any>
}

export const AddTimeBlockManagerForm = forwardRef<AddTimeBlockManagerFormRef, Props>(({
    handleClose,
    onSubmit,
    defaultValues,
}, ref) => {
    const refForm = useRef<Form>(null)
    const size = useWindowSize()

    const colCountByScreen = {
        lg: 3,
        sm: 3,
        xs: 1,
        md: 3,
    }

    const editorOptions = useMemo(() => ({
        date: {
            type: 'date',
            width: '100%',
            useMaskBehavior: true,
            
        },
        time: {
            type: 'time',
            width: '100%',
            pickerType: "native",
            useMaskBehavior: true
        },
        allDay: {
            onValueChanged: ({value}: any) => {
                const timeStartEditor = refForm.current?.instance.getEditor('timeStart')
                const timeFinishEditor = refForm.current?.instance.getEditor('timeFinish')

                timeStartEditor?.option('disabled', value)
                timeFinishEditor?.option('disabled', value)
                refForm.current?.instance.updateData({
                    timeStart: undefined,
                    timeFinish: undefined,
                })
                handleDataChanged()
            }
        },
        saveButton: {
            text: 'Сохранить',
            onClick: () => {
                const values = refForm.current?.instance.option('formData')
                const delta = (values.timeFinish?.getTime() - values.timeStart?.getTime())
    
                if (delta >= 0 || values.allDay) {
                    onSubmit(refForm.current?.instance.option('formData'))
                } else {
                    notify({
                        message: `Поле "Время начала" должна быть меньше поля "Время конца"`,
                        position: 'top center',
                    }, 'error', 3000);
                }
                
            },
            type: 'default',
            disabled: true,
        },
        cancelButton: {
            text: 'Отмена',
            onClick: handleClose,
            type: 'normal'
        }
    }), [])

    const handleDataChanged = () => {
        const submitButton = refForm.current?.instance.getButton('submit')
        const isValid = refForm.current?.instance.validate().isValid

        submitButton?.option('disabled', !isValid)
    }

    useImperativeHandle(ref, () => ({
        resetForm: () => {
            return refForm.current?.instance.resetValues()
        },
        updateData: (data) => refForm.current?.instance.updateData(data),
    })) 
    
    return (
        <form>
            <Form
                ref={refForm}
                onFieldDataChanged={handleDataChanged}
                formData={defaultValues}
                >
                <GroupItem
                    colCountByScreen={colCountByScreen}
                    >
                    <Item
                        dataField="date" 
                        label={{text: "Дата"}}
                        editorOptions={editorOptions.date}
                        editorType="dxDateBox"
                        isRequired
                        />
                    <Item
                        dataField="timeStart" 
                        label={{text: "Время начала"}}
                        editorOptions={editorOptions.time}
                        editorType="dxDateBox"
                        isRequired
                        />
                    <Item
                        dataField="timeFinish" 
                        label={{text: "Время конца"}}
                        editorOptions={editorOptions.time}
                        editorType="dxDateBox"
                        isRequired
                        />
                    <Item
                        dataField="allDay" 
                        label={{
                            text: `Весь день:`,
                            location: 'left'
                        }} 
                        editorOptions={editorOptions.allDay}
                        editorType="dxCheckBox"
                        />
                </GroupItem>

                <EmptyItem/>

                <GroupItem
                    colCount={2}
                    >
                    
                    <ButtonItem
                        horizontalAlignment='left'
                        buttonOptions={editorOptions.cancelButton}
                        />

                    <ButtonItem
                        name='submit'
                        horizontalAlignment={'right'}
                        buttonOptions={editorOptions.saveButton}
                        />
                </GroupItem>
            </Form>
        </form>
    )
})