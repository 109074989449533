import React, { memo, MutableRefObject, Ref, useContext, useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { bemHelper } from '~/libs';
import AddIcon from '@material-ui/icons/Add';
import './styles.scss';
import { Scheduler } from 'devextreme-react';
import RefObject from 'react';
import { Common } from '~/types';
import { PopupContext } from '~/context';
import { usePermissions } from '~/hooks/usePermissions';

const cn = bemHelper({ name: 'nav-link', prefix: 'side-menu-' });

type Props = {
  children: string;
  disabled?: boolean;
  isFocusable: boolean;
  mix?: string;
  onClick?: () => void;
  to?: string;
  icon?: string;
  items?: Common.itemLink[],
  addButton?: boolean;
  onClickAddButton?: () => void;
  menuActive: boolean;
};

const defaultProps = {
  disabled: false,
};

const NavLinkCommon: React.FC<Props> = memo((props) => {
  const { children, disabled, isFocusable, mix, onClick, to, addButton, icon, menuActive, onClickAddButton } = props;
  return (
    <RouterNavLink
      activeClassName="side-menu-nav-link--active"
      className={cn(undefined, { disabled, visible: menuActive}, mix)}
      onClick={onClick}
      tabIndex={isFocusable ? 0 : -1}
      title={children}
      to={to ? to : ''}
    >
      { icon && (
        <div className={cn('icon', {visible: menuActive})}>
          <i className={icon}></i>
        </div>
      )}
      {children}
      {addButton && (
        <div className={cn('add-button', {visible: menuActive})} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onClickAddButton){
              onClickAddButton()
            }
          }}>
          <AddIcon fontSize='small'/>
        </div>
      )}
    </RouterNavLink>
  )
})

const NavLinkTree: React.FC<Props> = memo(({addButton, ...props}) => {

  const { children, disabled, isFocusable, mix, onClick, to, items, menuActive, onClickAddButton } = props;

  const [ isOpened, setIsOpened ] = useState(false)
  const perm = usePermissions()

  const getMaxHeightItem = () =>{
    if(menuActive){
      if(isOpened){
        return 36*Number(items?.length)
      }
    } else {
      if(isOpened){
        return 40*Number(items?.length)
      }
    }
    return 0
  }

  return ( 
    <div>
      <div 
        title={isOpened ? 'Cкрыть' : 'Раскрыть'}
        className={cn(undefined, {visible: menuActive}, mix) + " " + cn('dropdown') + (isOpened ? ' active' : '') } 
        onClick={() => {setIsOpened(!isOpened)}}
      >
        <div className={cn('dropdown-icon-box', {visible: menuActive})} >
          <span>{'▼'}</span>
        </div>

        {children}

        {addButton && (
          <div className={cn('add-button', {visible: menuActive})} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation()
              if (onClickAddButton){
                onClickAddButton()
              }
            }}>
            <AddIcon fontSize='small'/>
          </div>
        )}
      </div>
        
        <div 
          className={cn('dropdown-container', { disabled, visible: menuActive }, mix) + (isOpened ? ' active' : '')}
          style={{
            maxHeight: getMaxHeightItem()
          }}
          >
          {items?.map((item) => {
            if (!item.permissions || perm?.some(_i => item.permissions?.includes(_i))){
              return (
                <NavLinkCommon 
                  {...props}
                  icon={item.icon}
                  key={item.id} 
                  to={item.to}
                  >
                  {item.label}
                </NavLinkCommon>
              )
            }
          })}
        </div>
    </div>
  )
})

export const NavLink: React.FC<Props> = memo((props) => {
  const { items } = props;

  return (
    <>
    { items !== undefined ? (
      <NavLinkTree  {...props}/>
    ) : (
      <NavLinkCommon {...props} />
    )}
    </>
  );
});

NavLink.defaultProps = defaultProps;
