import React, { useState } from 'react';
import './styles.scss'
import { bemHelper } from '~/libs';
import { Button } from 'devextreme-react/button';
import { Tooltip } from 'devextreme-react/tooltip'
import { Model } from '~/api/autotype';

const cn = bemHelper('select-box-auto-type-item');

export const SelectBoxAutoTypeItem = (data:Model) => {

  return (
      <div className={cn()}>
          <span 
            className={cn('text')}
            title={data.descriptionShort}
            >
              {`${data.name} (${data.vehicleType})`}
          </span>
      </div>
  )
}