import React, { useEffect, useMemo, useRef, useState } from "react"

import { ActDetailTable } from "./atoms/ActDetailTable"

import { PageBase } from "~/ui/atoms/PageBase"
import { PageError } from "~/ui/atoms/PageError"
import { PageLoader } from "~/ui/atoms/PageLoader"
import { Common } from "~/types"
import { ActDetailForm } from "./atoms/ActDetailForm"
import { useHistory, useParams } from "react-router"
import { Act, ActRecord, Acts, UpdateActData } from "~/api/acts"
import { Status } from "~/api/records"
import { handleChangeToString, handleRecordComment } from "./service"
import notify from 'devextreme/ui/notify';


export type ActDetailParams = {
    aktId: string
}

export interface ActDetailFormData extends Act {
    dkList: ActRecord[]
}

export type ChangeDataStatus = Record<number, {
    from: Status,
    to: Status,
}>

export type ChangedData = Partial<{
    deletedDk: number[],
    status: ChangeDataStatus
}>

export const ActDetail = () => {    
    const { aktId } = useParams<ActDetailParams>()
    const history = useHistory()
    const [ error, setError ] = useState<string|null>(null)
    const [ data, setData ] = useState<ActDetailFormData|null>(null)

    
    const changedData: ChangedData = useMemo(() => {
        return {}
    }, [])
    

    const getData = async (aktId: string) => {
        try{
            const result = await Acts.controller.getActById(aktId)
            setData({
                ...result.dkAktResponse,
                dkList: result.dkListResponse
            })
        } catch(err:any) {
            setError(err)
        }
    }

    const handleApprove = async() => {
        if(data?.aktId){
            let comment: any = [
                handleChangeToString(changedData),
                handleRecordComment(data?.dkList || []),
                data?.comment
            ].filter(i => !!i)
            const postData: UpdateActData = {
                dkAktId: data?.aktId,
                dkAktStatusId: 3,
                comment: comment.join('\n\n')
            }

            try{
                await Acts.controller.updateAct(postData)
                notify({
                    message: 'Акт успешно обновлен',
                    position: 'top center',
                  }, 'success', 3000);
                history.push('/app/acts/all')
            } catch(err: any){
                notify({
                    message: `Упс... Произошла ошибка ${err.message}`,
                    position: 'top center',
                  }, 'error', 3000);
            }

        }
        
    }

    const handleSentToFix = async () => {
        if(data?.aktId){
            let comment: any = [
                handleChangeToString(changedData),
                handleRecordComment(data?.dkList || []),
                data?.comment
            ].filter(i => !!i)
            const postData: UpdateActData = {
                dkAktId: data?.aktId,
                dkAktStatusId: 6,
                comment: comment.join('\n\n')
            }

            try{
                await Acts.controller.updateAct(postData)
                notify({
                    message: 'Акт успешно обновлен',
                    position: 'top center',
                  }, 'success', 3000);
                history.push('/app/acts/all')
            } catch(err: any){
                notify({
                    message: `Упс... Произошла ошибка ${err.message}`,
                    position: 'top center',
                  }, 'error', 3000);
            }
            
        }
    }

    const handleReApprove = () => {
        if(data?.aktId){
            Acts.controller.updateAct({
                dkAktId: data?.aktId,
                dkAktStatusId: 2,
                comment: data?.comment
            }).then(() => {
                notify({
                    message: 'Акт успешно обновлен',
                    position: 'top center',
                  }, 'success', 3000);
                history.push('/app/acts/all')
            })
            .catch((err: any) => {
                notify({
                    message: `Упс... Произошла ошибка ${err.message}`,
                    position: 'top center',
                  }, 'error', 3000);
            })
        }
    }

    useEffect(() => {
        if(aktId){
            getData(aktId)
        }
    },[])


    if(error){
        return <PageError error={error}/>
    }

    if(data == null){
        return <PageLoader/>
    }

    return (
        <PageBase
            title={`Акт ${data.aktId} - ${data.statusName}`}
            >
                <ActDetailForm
                    data={data}
                    handleApprove={handleApprove}
                    handleSentToFix={handleSentToFix}
                    handleReApprove={handleReApprove}
                    changedData={changedData}
                    />
        </PageBase>
    )
}