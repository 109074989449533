import { Common } from '~/types';
import { AllActs } from './AllActs';
import { ActDetail } from './ActDetail';
import { CreateAct } from './CreateAct';
import { ActsForApproval } from './ActsForApproval';
import { ApprovedActs } from './ApprovedActs';
import { PaidActs } from './PaidActs';


const ActPermissions = [
  Common.SubPermissions.ROLE_ADMIN, 
  Common.SubPermissions.ROLE_BROKER_DIRECTOR, 
  Common.SubPermissions.ROLE_STO_DIRECTOR, 
  Common.Permissions.ROLE_STO_ADMINISTRATOR
]

export const ROUTES = [
  {
    Component: AllActs,
    permissionComponent: ActPermissions,
    exact: true,
    path: '/app/acts/all',
  },
  {
    Component: CreateAct,
    permissionComponent: [
      Common.SubPermissions.ROLE_ADMIN, 
      Common.Permissions.ROLE_STO_ADMINISTRATOR
    ],
    exact: true,
    path: '/app/acts/create',
  },
  {
    Component: ActsForApproval,
    permissionComponent: ActPermissions,
    exact: true,
    path: '/app/acts/for-approval',
  },
  {
    Component: ApprovedActs,
    permissionComponent: ActPermissions,
    exact: true,
    path: '/app/acts/approved',
  },
  {
    Component: PaidActs,
    permissionComponent: ActPermissions,
    exact: true,
    path: '/app/acts/paid',
  },
  {
    Component: ActDetail,
    permissionComponent: ActPermissions,
    exact: true,
    path: '/app/act/:aktId',
  },
  
];
