import { ky } from '~/api/kyInstance';
import { Model, ModelById } from '../records';
import { ActDetail } from '../../routes/Acts/ActDetail/ActDetail';

export type ActHistory = {
  date: string,
  action: string,
  staffName: string,
  comment: string
}

export type Act = {
  aktId: string,
  statusName: string,
  statusId: number,
  owner: boolean,
  dkIdList: number[],
  partnerName: string,
  dkAktHistories: ActHistory[],
  comment: string
};

export type ActRecord = {
  dkId: number,
  approvedStatusId: number,
  approvedStatusName: string,
  stoName: string,
  date: string,
  regNumber: string,
  brand: string,
  model: string,
  sum: number,
  comment: string
}

export type ActDetail = {
  dkAktResponse: Act,
  dkListResponse: ActRecord[]
};


export type ActsQueryData = {
  fio?: string,
  dkId?: number,
  regNumber?: string,
}

type CreateActData = {
  partnerId: number,
  dkIdList: number[]
}

type GenerateDkList = {
  partnerId: number,
  from: string,
  to: string
}

export type UpdateActData = {
  dkAktId: string,
  dkAktStatusId: number,
  comment?: string,
}

export type DeleteDkFromActData = {
  dkAktId: string,
  dkIdList: number[]
}

export namespace Acts {
  
  export class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getActs(query: ActsQueryData): Promise<Act[]> {
      return ky.get(`${this.url}/`, {
        searchParams: query
      }).json();
    }

    public createAct(data: CreateActData): Promise<any> {
      return ky.post(`${this.url}`, { 
        json: data
      });
    }

    public generateDkList(query: GenerateDkList): Promise<ModelById[]> {
      return ky.get(`${this.url}/dk/`, {
        searchParams: query
      }).json();
    }

    public getActById(aktId: string): Promise<ActDetail> {
      return ky.get(`${this.url}/${aktId}/`).json();
    }

    public updateAct(data: UpdateActData): Promise<any> {
      return ky.post(`${this.url}/approved`, {
        json: data
      });
    }

    public deleteDkFromAct(data: DeleteDkFromActData): Promise<any> {
      return ky.delete(`${this.url}/dk`, {
        json: data
      });
    }
  }

  export const controller = new Controller('api/dk/akt');
}
