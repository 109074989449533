import React, { useContext, useEffect, useState } from 'react'
import { authAgentIdStorage, bemHelper } from '~/libs';
import { useParams } from 'react-router-dom';

import Form, { Item, ButtonItem, GroupItem, CompareRule,SimpleItem } from 'devextreme-react/form';

import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { Auth } from '~/api';
import EditBase from '~/ui/atoms/EditBase';
import { passwordButtonFunc, validationRules } from '~/routes/Records/services';

import { useWindowSize } from '~/hooks/useWindowSize';
import { ComponentProps } from '~/ui/atoms/PopupEdit';
import { colSpanItem } from '~/ui/atoms/Edit/service';
import { AuthContext } from '~/context'

import './styles.scss'

export const MainComponent: React.FC<ComponentProps> = ({manageRef}) => {

    const { urlId } = useParams<any>()
    const size = useWindowSize()
    const { authState } = useContext(AuthContext);
    const cn = bemHelper('profile')

    const [data, setData] = useState<any>({
      ...authState.userData?.staff,
      phoneMobile: authState.userData?.staff.phoneMobile ? String(authState.userData?.staff.phoneMobile).substring(1) : '',
    })
    const [dataPassword, setDataPassword] = useState<any>({
      login: authState.userData?.staff.login
    })
    const [form, setForm] = useState<any>()
    const [formPass, setFormPass] = useState<any>()

    const colCountByScreen = {
        lg: 4,
        md: 4,
        sm: 3,
        xs: 1,
    }
    const colSpanChangePass = {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    }
    const colSpanMain = {
      lg: 5,
      md: 4,
      sm: 3,
      xs: 1,
  }

    const colSpanEmpty = {
      lg: 4,
      md: 4,
      sm: 2,
      xs: 1,
  }

    const EditorOptions = {
      lastName: {
        readOnly: true,
      },
      firstName: {
        readOnly: true,
      },
      fatherName: {
        readOnly: true,
      },
      phone: {
        mask: '+7 (X00) 000-00-00', 
        maskChar: '_',
        maskRules: { X: /[02-9]/ },
      },
      email: {
        
      },
      position: {
        readOnly: true,
      },
      tlgChatId: {
        
      },
      waNumber:{

      },
      login: {
        readOnly: true,
      },
      password: {
        mode: 'password',
        buttons: [
          passwordButtonFunc(formPass, 'password')
        ]
      },
      confirmPassword: {
        mode: 'password',
      },
    }

    const validateConfirmPassword = () => {
        return [{
          type: 'compare',
          comparisonTarget: () => dataPassword.password,
          message: 'Пароли не совпадают'
      }]
    }

    const { t } = useTranslation('staff');


    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault()

        try{
            const values = {
              ...data,
              phoneMobile: data.phoneMobile ? `7${data.phoneMobile}`: null,
              agentId: authAgentIdStorage.get() ? authAgentIdStorage.get() : 0,
            }
            
            await Auth.controller.changeStaffData(values)
            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
              }, 'success', 3000);

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        } 
    }

    const handleChangePassword = async () => {

      if(formPass.validate().isValid){
        try{

          await Auth.controller.changePass(dataPassword.password)

          notify({
            message: 'Пароль успешно изменен',
            position: 'top center',
          }, 'success', 3000);

          setDataPassword({
            ...dataPassword,
            password: '',
            confirmPassword: '',
          })

        } catch(err: any) {
          notify({
              message: `Упс... Произошла ошибка ${err.message}`,
              position: 'top center',
            }, 'error', 3000);
        } 
      }
        
    }

    const handleDataChange = (e: any) => {
        const button = form.getButton('submit')

        if(button){
            if(e.component.validate().isValid){
                button.option('disabled', false)
            } else{
                button.option('disabled', true)
            }
        }
        
    }

    useEffect(() => {
      manageRef?.current?.setIsLoading(false)
    }, [])

    const [popup, isPopup] = useState<any>()
    

    return (
            <form onSubmit={handleSubmit}>
                <Form
                    colCount={2}
                    onContentReady={e => e.component.validate()}
                    formData={data}
                    onInitialized={(e:any ) => {setForm(e.component)}}
                    colCountByScreen={colCountByScreen}
                    onFieldDataChanged={handleDataChange}
                    id="form">
                    
                    <GroupItem
                      colCount={colSpanItem(size, colSpanChangePass)}
                      colSpan={colSpanItem(size, colCountByScreen)}
                      >
                      <Item 
                          dataField="lastname" 
                          label={{text: t('lastName')}} 
                          editorOptions={EditorOptions.lastName} 
                          />
                      <Item 
                          dataField="firstname" 
                          label={{text: t('firstName')}} 
                          editorOptions={EditorOptions.firstName} 
                          /> 
                      <Item 
                          dataField="fathername" 
                          label={{text: t('fatherName')}} 
                          editorOptions={EditorOptions.fatherName}
                          />
                    </GroupItem>

                    <GroupItem
                      colCount={colSpanItem(size, colSpanMain)}
                      colSpan={colSpanItem(size, colCountByScreen)}
                      >
                    <Item 
                        dataField="position" 
                        label={{text: t('position')}} 
                        editorOptions={EditorOptions.position}
                        />
                        

                    <Item 
                        dataField="phoneMobile" 
                        label={{text: t('phone')}} 
                        editorOptions={EditorOptions.phone} 
                        isRequired
                        />
                    
                      
                    <Item 
                        dataField="email" 
                        label={{text: t('email')}} 
                        editorOptions={EditorOptions.email}
                        validationRules={validationRules.email}
                        />

                    <Item 
                        dataField="tlgChatId" 
                        label={{text: t('tlgChatId')}} 
                        editorOptions={EditorOptions.tlgChatId}
                        validationRules={validationRules.tlgChatId}
                        />
                    <Item 
                      dataField="waNumber" 
                      label={{text: t('waNumber')}} 
                      editorOptions={EditorOptions.waNumber}
                      />
                    </GroupItem>
                    <Item
                      itemType={'empty'}
                      colSpan={colSpanItem(size,colSpanEmpty)}
                      />

                    <ButtonItem
                        colSpan={colSpanItem(size, colCountByScreen)}
                        name="submit"
                        buttonOptions={{
                            type: 'default',
                            text: 'Сохранить',
                            useSubmitBehavior: true,
                        }}
                        />
                    <Item
                      itemType={'empty'}
                      colSpan={colSpanItem(size,colSpanEmpty)}
                      />
                </Form>
                <Form
                    colCount={2}
                    formData={dataPassword}
                    colCountByScreen={colCountByScreen}
                    onInitialized={(e:any ) => {setFormPass(e.component)}}
                    showValidationSummary={true}
                    id="form-change-password">

                    <GroupItem
                      colSpan={colSpanItem(size, colCountByScreen)}
                      colCount={colSpanItem(size, colSpanChangePass)}
                      cssClass={cn('group-change-pass') + " form-group"}
                      >
                      <GroupItem
                        colSpan={colSpanItem(size, colSpanChangePass)}
                        colCount={colSpanItem(size, colSpanChangePass)}
                      >
                        <Item 
                          dataField="login" 
                          label={{text: t('login')}} 
                          editorOptions={EditorOptions.login}
                          />
                      </GroupItem>
                      <Item 
                          dataField="password" 
                          label={{text: t('password')}} 
                          editorOptions={EditorOptions.password}
                          isRequired
                          />
                      <Item 
                          dataField="confirmPassword" 
                          label={{text: t('confirmPassword')}} 
                          editorOptions={EditorOptions.confirmPassword}
                          validationRules={validateConfirmPassword()}
                          isRequired
                          />

                      <ButtonItem
                        name="chengePassword"

                        buttonOptions={{
                            type: 'default',
                            text: 'Изменить пароль',
                            onClick: () => handleChangePassword(),
                            height: '39px'
                        }}
                        />  
                        
                    </GroupItem>
                </Form>
          </form>
    )
}


const Main = () => {
    
  return (
      <EditBase Component={MainComponent} title='Профиль'/>
  )
}

export default Main
