import { dkStatusDataSource, partnersDataSource, stoAllDataSource, tireBrandsDataSource } from "../stores"
import CustomStore from 'devextreme/data/custom_store';

export const clearStoreCache = async () => {
    const ar: CustomStore[] = [ 
        dkStatusDataSource,
        partnersDataSource,
        stoAllDataSource,
        tireBrandsDataSource,
    ]

    ar.map(i => {
        i.clearRawDataCache()
    })
}