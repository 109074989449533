import React, { useContext } from 'react'
import { AuthContext } from '~/context';
import { Common } from '~/types';
import { EditStationComponent } from '~/ui/atoms/Edit/EditStation'
import EditBase from '~/ui/atoms/EditBase'

const EditStation = () => {
  const { authState: {
    userData
    }} = useContext(AuthContext);  

  let title = "Сведения о СТО"
  
  return (
    <EditBase Component={EditStationComponent} title={title}/>
  )
}
export default EditStation
