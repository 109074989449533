import { useMemo } from "react"

type Props = {
    fromReport: boolean,
    isDirectorOrAdmin: boolean,
}

export const useColCountEditStation = ({fromReport, isDirectorOrAdmin}: Props) => {

    const colCountByScreen = useMemo(() => {
        if(fromReport){
            return {
                lg: 2,
                md: 2,
                sm: 2,
                xs: 1,
            }
        }
        return {
            lg: 5,
            md: 3,
            sm: 3,
            xs: 1,
        }
    },[fromReport])

    const colCountTimePeriodGroup = useMemo(() => {
        if(fromReport){
            return {
                lg: 2,
                md: 2,
                sm: 2,
                xs: 1,
            }
        }
        return {
            lg: 2,
            md: 2,
            sm: 2,
            xs: 1,
        }
    },[fromReport])

    const colCountTypesPriceGroup = useMemo(() => {
        if(fromReport){
            return {
                lg: 3,
                md: 3,
                sm: 3,
                xs: 1,
            }
        }
        if(isDirectorOrAdmin){
            return {
                lg: 4,
                md: 3,
                sm: 3,
                xs: 1,
            }
        }   
        return colCountByScreen
    },[fromReport, isDirectorOrAdmin, colCountByScreen])

    const colSpanTypesPriceGroup = useMemo(() => {
        if(isDirectorOrAdmin){
            return {
                lg: 4,
                md: 2,
                sm: 2,
                xs: 1,
            }
        }
        return colCountByScreen
    },[isDirectorOrAdmin, colCountByScreen])

    const colCountSomeInfo = useMemo(() => {
        if(fromReport){
            return {
                lg: 3,
                md: 3,
                sm: 3,
                xs: 1,
            }
        }
        return colCountByScreen
    },[fromReport, colCountByScreen])

    return [
        colCountByScreen,
        colCountTimePeriodGroup,
        colCountTypesPriceGroup,
        colSpanTypesPriceGroup,
        colCountSomeInfo,
    ]
}