import React, { memo, MutableRefObject, useContext, useState } from 'react';
import { NavLink } from './atoms';
import { Loader } from '~/ui/atoms';
import { Common } from '../../../../../types';
import './styles.scss';
import { bemHelper } from '~/libs';
import { AuthContext } from '~/context';
import { usePermissions } from '~/hooks/usePermissions';


type Props = {
  isNavGroupsLoaded: boolean;
  navLinks: Common.NavGroupLink[];
  mix: string;
  menuActive: boolean;
  toggleMenu(): void;
};

const cn = bemHelper('side-menu');


export const SideMenu: React.FC<Props> = memo((props) => {
  const { isNavGroupsLoaded, navLinks, mix, menuActive } = props;

  const { authState } = useContext(AuthContext);
  const perm = usePermissions()
  

  return (
    <aside className={cn('', '', mix)}>
      {!isNavGroupsLoaded && <Loader transparent />}
      {isNavGroupsLoaded && (
        <div className={cn('navigation', { visible: menuActive })}>
          <ul>
            <li>{authState.userData?.agent?.address}</li>
            <li>{authState.userData?.agent?.phone}</li>
          </ul>
          {navLinks
            .map(({ label, disabled, to ,addButton, permissions, items, icon, onClickAddButton}) => {
              const isAddButton = addButton
              

              if (perm?.some(_i => permissions?.includes(_i))) {
                return (
                  <NavLink 
                    key={label} 
                    isFocusable={!disabled} 
                    to={to} 
                    items={items} 
                    addButton={isAddButton} 
                    onClickAddButton={onClickAddButton}
                    icon={icon}
                    menuActive={menuActive}
                    >
                    {label}
                  </NavLink>
                );
              }
              
            })}
        </div>
      )}
    </aside>
  );
});
