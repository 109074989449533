export const clearData = (data?: Record<any,any>) => {
    
    if(data){
        const result: any = {}
        Object.keys(data).map(key => {
            if(data[key]){
                result[key] = data[key]
            }
        })
        return result
    }
    return data
}