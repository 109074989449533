import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';

import Form, { Item, ButtonItem, GroupItem } from 'devextreme-react/form';

import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { Client } from '~/api';
import { IndividualModel } from '~/api/client';
import { validationRules } from '~/routes/Records/services';
import { ComponentProps } from '~/ui/atoms/PopupEdit';
import { colSpanItem } from '../service';
import { useWindowSize } from '~/hooks/useWindowSize';
import { checkPermission } from '~/utils/checkPermission';
import { AuthContext } from '~/context';
import { Common } from '~/types';
import { ToolbarButton } from '../../CustomPopup';





export const EditClientIndividualComponent: React.FC<ComponentProps> = ({
    updateData, 
    handleClose, 
    id, 
    manageRef,
    fromReport, 
    activeRecord=false
}) => {

    const { urlId } = useParams<any>()
    const size = useWindowSize()

    const [data, setData] = useState<IndividualModel>({})
    const [form, setForm] = useState<any>()
    const { authState: {
        userData
        }} = useContext(AuthContext);   

    const readonly = activeRecord ? checkPermission(userData?.roles, Common.Permissions.ROLE_STO_OPERATOR)  : fromReport

    const colCountByScreen = fromReport ? {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    } : {
        lg: 5,
        md: 4,
        sm: 3,
        xs: 1,
    }
    const colSpanEmpty = {
        md: 3,
        sm: 1,
        xs: 1,
    }

    const EditorOptions = {
        firstName: {
            with: '100%',
            readOnly: readonly
        },
        lastName: {
            with: '100%',
            readOnly: readonly
        },
        fatherName: {
            with: '100%',
            readOnly: readonly
        },
        email: {
            with: '100%',
            readOnly: readonly
        },
        phone: {
            with: '100%',
            mask: '+0 (X00) 000-00-00', 
            maskRules: { X: /[02-9]/ },
            maskChar: '_',
            readOnly: readonly
        },
        comment: {
            with: '100%',
            readOnly: readonly
        },

    }

    const { t } = useTranslation('client');

    const getData = async () => {

        try{
            const result = await Client.controller.getClientIndividualById(fromReport ? id : urlId)
            form.updateData(result)
        } catch(err:any){
            manageRef?.current?.setError(err.message)
        }
        
        manageRef?.current?.setIsLoading(false)
    }

    const handleSubmit = async () => {
        const editor = form.getEditor('phoneMobile')

        const values = {
            ...data,
            phoneMobile: editor.option('text').replace(/\s/g, "")
        }

        try{
            await Client.controller.updateIndividual(values)
            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
              }, 'success', 3000);

            if(updateData){
                updateData()
            }

            handleClose()

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        } 
    }

    const handleDataChange = (e: any) => {
        const button = form.getButton('submit')

        if(button){
            if(e.component.validate().isValid){
                button.option('disabled', false)
            } else{
                button.option('disabled', true)
            }
        }
    }


    useEffect(() => {

        if ((id || urlId) && form){
            manageRef?.current?.setIsLoading(true)
            getData()
        }

    }, [form,id,urlId])

    useEffect(() => {
        if (form){
            manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`, false)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.options.onClick`, handleSubmit)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, !readonly)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, readonly)
        }
    }, [data, form])
    

    return (
            <form>
                <Form
                    onContentReady={e => e.component.validate()}
                    formData={data}
                    onInitialized={(e:any ) => {setForm(e.component)}}
                    onFieldDataChanged={handleDataChange}
                    id="form">
                    <GroupItem colCountByScreen={colCountByScreen}>
                        <Item 
                            dataField="lastname" 
                            label={{text: t('lastName')}} 
                            editorOptions={EditorOptions.lastName} 
                            validationRules={validationRules.clientFio}
                            isRequired
                            />
                        <Item 
                            dataField="firstname" 
                            label={{text: t('firstName')}} 
                            editorOptions={EditorOptions.firstName} 
                            validationRules={validationRules.clientFio}
                            isRequired
                            /> 
                        <Item 
                            dataField="fathername" 
                            label={{text: t('fatherName')}} 
                            editorOptions={EditorOptions.fatherName}
                            validationRules={validationRules.clientFio}
                            />
                        <Item 
                            dataField="phoneMobile" 
                            label={{text: t('phone')}} 
                            editorOptions={EditorOptions.phone} 
                            />
                        <Item 
                            dataField="email" 
                            label={{text: t('email')}} 
                            editorOptions={EditorOptions.email}
                            validationRules={validationRules.email}
                            />
                    </GroupItem>
                    <Item 
                        dataField="comment" 
                        label={{text: t('comment')}} 
                        colSpan={colSpanItem(size, colCountByScreen)} 
                        editorOptions={EditorOptions.comment}
                        />
                    
                    { !fromReport && (
                        <ButtonItem
                            colSpan={colSpanItem(size, colCountByScreen)}
                            name="submit"
                            visible={!readonly}
                            buttonOptions={{
                                type: 'default',
                                text: 'Сохранить',
                                onClick: () => handleSubmit()
                            }}
                            />
                    )}
                </Form>
            </form>
    )
}
