import React, { useContext, useMemo, useRef } from "react"
import { DataGrid } from "devextreme-react"
import { FC } from "react"
import { Column, Editing, FilterRow, Paging, Scrolling, Sorting, Lookup, Export } from "devextreme-react/data-grid"
import { CellRenderLink, cellRenderIndex } from "~/utils/reportCellLink"
import { PopupContext } from "~/context"
import { onDbClick } from "~/routes/Reports/service"
import { ActRecord, Acts } from "~/api/acts"
import { ExportingEvent } from "devextreme/ui/data_grid"
import { ChangeEventProps, StatusSelectBox } from "./atoms/StatusSelectBox"
import { dkStatusDataSource } from "~/custom-data-sources"
import { ActDetailParams, ChangedData } from "../../ActDetail"
import { useParams } from "react-router"
import notify from 'devextreme/ui/notify';
import { Records } from "~/api"
import { Common } from "~/types"


type Props = {
    data: ActRecord[],
    changedData: ChangedData,
    allowEditPartner: boolean,
    allowEditOwner: boolean,
    onDataChanged: () => void,
    onExport: (e: ExportingEvent<ActRecord, any>) => void
}

export const ActDetailTable: FC<Props> = ({
    data,
    changedData,
    allowEditPartner,
    allowEditOwner,
    onDataChanged,
    onExport,
}) => {
    const { 
        editPopupOpen
    } = useContext(PopupContext)
    const dataGridRef = useRef<DataGrid<ActRecord>>(null)
    const { aktId } = useParams<ActDetailParams>()

    const handleValueChangedPartner = async (e: ChangeEventProps, dkId: number) => {
        const defaultValue = changedData.status || {}
        const from = defaultValue[dkId]?.from || (await dkStatusDataSource.byKey(e.previousValue))
        const to = (await dkStatusDataSource.byKey(e.value))

        if(from.id != to.id){
            changedData.status = {
                ...defaultValue,
                [dkId]: {
                    from: defaultValue[dkId]?.from || (await dkStatusDataSource.byKey(e.previousValue)),
                    to: (await dkStatusDataSource.byKey(e.value))
                }
            }
        } else {
            if(changedData.status){
                delete changedData.status[dkId]
            }
        }
    }

    const handleValueChanged = (cell: any,e: ChangeEventProps, dkId: number) => {
        Records.controller.changeStatus(
            dkId, 
            e.value
        )
            .then(() => {
                if(!allowEditOwner){
                    handleValueChangedPartner(e, dkId)
                }
                notify({
                    message: 'Статус записи обновлен',
                    position: 'top center',
                }, 'success', 3000);
            })
            .catch((err: any) => {
                cell.setValue(e.previousValue)
                notify({
                    message: `Упс... Произошла ошибка ${err.message}`,
                    position: 'top center',
                }, 'error', 3000);
            })
    }

    const onValueChanged = (cell: any, e: ChangeEventProps, dkId: number) => {
        handleValueChanged(cell, e, dkId)
    }

    const handleDeleteDk = (item: ActRecord) => {
        if(allowEditOwner){
            Acts.controller.deleteDkFromAct({
                dkAktId: aktId,
                dkIdList: [item.dkId]
            })
                .then(() => {
                    notify({
                        message: 'Запись удалена',
                        position: 'top center',
                      }, 'success', 3000);
                })
                .catch((err: any) => {
                    data.push(item)
                    dataGridRef.current?.instance.option('dataSource', data)

                    notify({
                        message: `Упс... Произошла ошибка ${err.message}`,
                        position: 'top center',
                      }, 'error', 3000);
                })
        } else {
            changedData.deletedDk = [...(changedData.deletedDk || []), item.dkId]
        }
    }

    const saleAmountFormat = useMemo(() => {
        return { 
            style: 'currency', 
            currency: 'RUB', 
            useGrouping: true, 
            minimumSignificantDigits: 1 
        };
    },[])

    return (
        <DataGrid
            ref={dataGridRef}
            dataSource={data}
            allowColumnResizing={true}
            columnAutoWidth={true}
            showBorders
            hoverStateEnabled={true}
            showColumnLines
            onRowRemoved={(e) => handleDeleteDk(e.key)}
            allowColumnReordering={true}
            onExporting={onExport}
            onCellDblClick={(e) => {onDbClick({e: e, editPopupOpen, activeRecord: true})}}
            noDataText="Нет записей"
        >
            <Sorting mode="multiple" />
            <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
            <Paging enabled={false} />
            <FilterRow visible={true}
                applyFilter='auto'/>
            <Editing 
                mode={'cell'}
                allowUpdating={allowEditPartner || allowEditOwner}
                allowDeleting={allowEditPartner || allowEditOwner}
                onChangesChange={onDataChanged}
                />
            <Export
                enabled={true}
                formats={['pdf', 'xlsx']}
                />

            <Column
                caption={'№'}
                allowEditing={false}
                width={50}
                cellRender={(data:any) => cellRenderIndex({cellData: data, editPopupOpen, activeRecord: true})}
                alignment="left"
                name="number"
                dataType="number"
                />

            <Column
                caption='Адрес СТО (ПТО)'
                allowEditing={false}
                alignment="center"
                dataField="stoAddress"
                cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen, activeRecord: true})}
                dataType="string"
                />
            
            <Column
              dataField="approvedStatusId"
              caption='Статус'
              alignment="center"
              dataType="string"
              editCellRender={(cell:any) => <StatusSelectBox cell={cell} onValueChanged={onValueChanged}/>}
              allowEditing={true}
              width={200}
              >
                <Lookup
                  dataSource={dkStatusDataSource}
                  displayExpr="name"
                  valueExpr="id"
                />
            </Column>

            <Column
                caption="Дата"
                allowEditing={false}
                dataField='date'
                defaultSortOrder='desc'
                alignment="center"
                dataType="string"
                />

            <Column caption='TC' alignment="center">
                <Column
                    dataField="regNumber"
                    allowEditing={false}
                    caption='ГРЗ'
                    alignment="center"
                    dataType="string"
                    cellRender={(data:any) => CellRenderLink({cellData: data, editPopupOpen, activeRecord: true})}
                    />
                <Column
                    dataField="brand"
                    allowEditing={false}
                    caption='Марка'
                    alignment="center"
                    dataType="string"
                    />
                <Column
                    dataField="model"
                    allowEditing={false}
                    caption='Модель'
                    dataType="string"
                    alignment="center"
                    />
            </Column>

            
            <Column
                dataField="sum"
                allowEditing={false}
                caption='Стоимость'
                dataType="number"
                format={saleAmountFormat}
                alignment="center"
                />

            <Column
                dataField="comment"
                allowEditing={allowEditPartner}
                dataType="string"
                caption='Комментарий'
                alignment="center"
                />

        </DataGrid>
    )
}