import { Model } from "~/api/records"

export const getActiveRecords = (data: Model[]) => {

    let result: Model[] = []
  
    const disapproveStatus: number[] = [0]
  
    data.map(i => {
        if( !disapproveStatus.includes(i.approvedStatusId) ){
            result.push(i)
        }
    })
  
    return result
  }
  


export const validationRules = {

    dkAutoType: [
        {
            type: 'required', 
            message: 'Выберите тип записываемого ТС'
        }
    ],
    inn: [
        {
            type: 'stringLength', 
            min: 0,
            max: 12,
            message: 'ИНН должен содержать 12 символов'
        }
    ],
    email: [
        {
            type: 'pattern',
            pattern: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
            message: 'Введите корректный email'
        }
    ],
    clientFio: [
        {
            type: 'pattern',
            pattern: /^[\sа-яА-ЯёЁ]*$/,
            message: 'Можно использовать только русские буквы'
        },
        {
            type: 'required', 
            message: 'Это поле обязательно для заполнения'
        }
    ],
    phone: [
        {
            type: 'stringLength', 
            min: 10,
            message: 'Введите номер телефона правильно'
        }
    ],
    tlgChatId: [
        {
            type: 'stringLength', 
            max: 20,
            message: 'Chat-ID не может содержать больше 20 символов'
        }
    ],
}

export const mileageAdorment = {
            location: "after",
            name: 'mileage',
            options: {
                text: 'км',
                stylingMode: 'text',
                width: 32,
                disabled: true,
                elementAttr: {
                    class: 'none-disabled-textbox-button'
                }
            }
}

export const priceAdorment = {
    location: "after",
    name: 'RUB',
    options: {
        text: '₽',
        stylingMode: 'text',
        width: 32,
        disabled: true,
        elementAttr: {
            class: 'none-disabled-textbox-button none-disabled-textbox-button-price'
        }
    }
}

export const passwordButtonFunc = (form:any, fieldName: string) => {
    
    return {
        location: "after",
        name: 'Password',
        options: {
            icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAuIwAALiMBeKU/dgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAASOSURBVHic7dtbiFVVHMfxzzTajA5NUmKUkpRhKdrNsotUVET1XKQP9hBRXp66+NblJYJudIVCKyotJeotjSJLe4h6six6KYoocoIczelmnHR6WHtsHN17z5m1zpkR1hcWw9l7zv//W3/2Weu//mttMplMJpPJZDKZTCaTyWRaQcc4+Z2G83EhzsRszEIvujEFf2M/BvATfsR3+Lxo+9quuk104lo8ji9xEIMR7SB24jFcU9g/5jkPT6NPXHDqWh+ewsL2dCsdHbgRW7U2QGXtA9zQ8l4m4FJ8bHyCNLJ9hqta292xcQreNP4BOlrbVOibENyKfuMflKrWj+WtCsBo6MFr0nbqX+wRUoQ9xeeU9l8tdLeVM/B1hOih1sA7WCnMZMeP8NNVXF+FzcX/x/r8Ssjr2sLF+CVScANrcVqTvmdinfig9WFRk76b5gr8Hil0G86J1DEP2yN1DGBJpI5SLi8cxAhci8mJ9EzCM5F6/sCVifQcYj5+ixS2JrWogjWRuvYKT2oSpuPbSEEvpRJTwvOR+r7HjFgRnfgoUsg29T+9HizD6/gE3xR/N2Cp+ul+svgx7EMcV+OnkgcjBTRUD+YdQtpQN7v2YYXqktI88bPk/fUhOTqLxSeFayvsdwtLkWbsbSq+V8a6SL0NITVqikn4IoHjsjyqQ/OBGmoblT9hM8U/XTuK/o+aeyMdDgqZeRkrI23fWWF7SwLtd1eH53+mSbMwXlliv0f8CqBP+aC/OoH23TixPlQ8nMDZIBaU2F+WyP7SEvsLE9l/qC5QveKz9EFhYugq8bEhUWfWl9jvlqZasa+IxyFG5hW344QSEc2wF/+U3DsrgX2YU3J9vzQ7P724bfiFkcG6KYETwjZWGScl8jG94t6fiXzcPPzDyGC9ncjJlIp7/Yl8/FpxL1WB762qm73CI5zHrFA4OGxIGvlkDeC5EhHN0Im5Jfe2JLBfZWeuNJuuzwq1u0pS5VmrSuz3iN903WWC5FlwTwKHmyvsr4i0fUeF7XcTaL+rOjyHM0k4fBHjsCGs1Y5Gh7DGG4vdqrXhLOOwNiSsvmMdr6uw3635gG1UXXV4MVJvAxfVBaaMBxI4ryrZdgiL4roxbJfw06uqZ80XPwveVx+Sco4TKogxArYbXaX0FiEdGF4pXV9cn1rz/cniz1hsFVkphZPF1+BfjhVRwwuR+pLU4IeYJ2ynxwiaqLs7/Tg7tajLxGf3E23fcB8uSaTnCJaIL+FsF79PN1/8GDUgbBy3lEXC7BQjtCGkFWV5WBkzhfQgdtb7WTj82xZmC6dRYgQPBW2LsEQ515GL767i+mohM09ximYnTk8WiVEyVTjvFCt+eGv1+axX1KchLWW5iX/yb7dxPvk3nBnGvg/YynYQb0iYQ6VksfgzB6nap8J5sgnP9Xhf/JsUY3mS3sN1re9iehbgSfGpRl3bhSeE/OuYp1N4x+ZRoU52QFxwDgh1p0dwtTa9uzNeb4X14oKizRHytlOFbbIuYXr/S9h73COUcH4QFrw7hIDX1sczmUwmk8lkMplMJpPJZI4N/gNZlF+HHvBaEwAAAABJRU5ErkJggg==',
            type: 'default',
            stylingMode: 'text',
            width: 32,
            onClick: () => {
              const passwordEditor = form.getEditor(fieldName)
              let mode = passwordEditor.option('mode') 
              if (mode === 'password'){
                mode = 'text'
              } else {
                mode = 'password'
              }
              passwordEditor.option('mode', mode)
            }
        }
    }
}