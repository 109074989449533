import Main from "./Main";


export const ROUTES = [

  {
    Component: Main,
    exact: true,
    path: '/app/profile',
    permissionComponent: 'All',
  },
];
