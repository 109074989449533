import React, { useContext, useEffect, useRef, useState } from "react";
import { YMaps, Map, ZoomControl, ObjectManager, ObjectManagerFeature, Placemark, YMapsApi, Clusterer } from "react-yandex-maps";
import { renderToString } from 'react-dom/server'
import { ModelSto } from '~/api/sto'



import './styles.scss'
import { useHistory } from "react-router";
import PopupEdit from "~/ui/atoms/PopupEdit";
import { Common } from "~/types";
import { PopupContext } from "~/context";


type Props = {
    data: ModelSto[] | null,
    selectStation: (stationId: number) => void,
}

export const StationsMap: React.FC<Props> = ({data, selectStation}) => {
    
    const [ ymaps, setYmaps] = useState<YMapsApi| null>(null)
    const history = useHistory()
    const { 
        editPopupOpen
    } = useContext(PopupContext)


    const customBalloonContentLayout = (id: number) => ymaps?.templateLayoutFactory.createClass([
    '<div class="map-balloon">',
        '<h3>{{properties.name}}</h3>',
        '<ul>',
            '<li><b>Адрес:</b>{{properties.address}}</li>',
            `<li>
                <b>Часы работы:</b> 
                <div class="map-balloon-work-time">
                    Пн: {{properties.mondayWorkTime}} <br/> 
                    Вт: {{properties.tuesdayWorkTime}} <br/>
                    Ср: {{properties.wednesdayWorkTime}} <br/>
                    Чт: {{properties.thursdayWorkTime}} <br/>
                    Пт: {{properties.fridayWorkTime}} <br/>
                    Сб: {{properties.saturdayWorkTime}} <br/> 
                    Вс: {{properties.sundayWorkTime}} 
                </div>
            </li>`,
            '<li class="map-balloon-link"><span id="baloon-link-{{properties.id}}" data-id="{{properties.id}}" >Подробнее...</span></li>',
        '</ul>',
        '<button id="baloon-button-{{properties.id}}" data-id="{{properties.id}}">Записать клиента</button>',
    '</div>',
    ].join(' '),
    {
        build: function() {
            customBalloonContentLayout(id).superclass.build.call(this);
            const button: any = document.getElementById(`baloon-button-${id}`);
            const link: any = document.getElementById(`baloon-link-${id}`);
            button?.addEventListener("click", this.onButtonClick)
            link?.addEventListener("click", this.onLinkClick)
        },
        clear: function () {
            // Выполняем действия в обратном порядке - сначала снимаем слушателя,
            // а потом вызываем метод clear родительского класса.
            const button: any = document.getElementById(`baloon-button-${id}`)?.removeEventListener('click', this.onButtonClick)
            customBalloonContentLayout(id).superclass.clear.call(this);
        },
        onButtonClick: function () {
            selectStation(Number(this.getAttribute('data-id')))
        },
        onLinkClick: function () {
            const id = Number(this.getAttribute('data-id'))
            const type = Common.TypePopup.STATION
            
            //Cначала type потом id
            editPopupOpen({
                id,
                type,
                updateData: () => null
            })

        }
    })


    return (
        <YMaps>
            <div style={{height: '100%'}}>
                <Map 
                    height={'400px'}
                    width="100%"
                    modules={[
                        'templateLayoutFactory',
                        'geoObject.addon.balloon',
                        'clusterer.addon.balloon',
                      ]}
                    onLoad={(maps: YMapsApi): void => {
                        if (maps && !ymaps) {
                            setYmaps(maps);
                            
                        }
                    }}
                    defaultState={{ 
                        center: [59.933, 30.3154], 
                        zoom: 9 ,
                    }} 
                    >

                    <Clusterer options={{
                        preset: 'islands#invertedDarkBlueClusterIcons',
                        groupByCoordinates: false
                        }}>

                        {data?.map(_i => (
                            <Placemark 
                                key={_i.id}
                                geometry={[_i.lat, _i.lon]} 
                                properties={{
                                    id: _i.id,
                                    address: _i.address,
                                    mondayWorkTime: _i.mondayWorkTime,
                                    tuesdayWorkTime: _i.tuesdayWorkTime,
                                    wednesdayWorkTime: _i.wednesdayWorkTime,
                                    thursdayWorkTime: _i.thursdayWorkTime,
                                    fridayWorkTime: _i.fridayWorkTime,
                                    saturdayWorkTime: _i.saturdayWorkTime,
                                    sundayWorkTime: _i.sundayWorkTime,
                                }}
                                options={{
                                    preset: 'islands#darkBlueAutoIcon',
                                    balloonContentLayout: customBalloonContentLayout(_i.id),
                                    
                                }}
                            />
                            
                        ))}
                    </Clusterer>

                    <ZoomControl options={{float:'left'}}/>

                </Map>
                
            </div>
        </YMaps>
    )
}