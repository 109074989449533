import React, { useContext, useEffect, useState } from 'react'
import { bemHelper } from '~/libs';
import { useHistory, useParams } from 'react-router-dom'
;

import Form, { Item, ButtonItem, GroupItem } from 'devextreme-react/form';

import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { Client } from '~/api';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useIsTablet } from '~/hooks/useIsTablet';
import EditBase from '~/ui/atoms/EditBase';
import { validationRules } from '~/routes/Records/services';
import { EntityModel } from '../../../../api/client/index';
import { ComponentProps } from '~/ui/atoms/PopupEdit';
import { colSpanItem } from '../service';
import { useWindowSize } from '~/hooks/useWindowSize';
import { checkPermission } from '~/utils/checkPermission';
import { Common } from '~/types';
import { AuthContext } from '~/context';
import { ToolbarButton } from '../../CustomPopup';




export const EditClientEntityComponent: React.FC<ComponentProps> = ({
    handleClose, 
    updateData, 
    manageRef,
    id, 
    fromReport=false, 
    activeRecord=false
}) => {

    const { urlId } = useParams<any>()
    

    const [data, setData] = useState<EntityModel>({})
    const [form, setForm] = useState<any>()
    const size = useWindowSize()

    const { authState: {
        userData
        }} = useContext(AuthContext); 
    
    const readonly = activeRecord ? checkPermission(userData?.roles, Common.Permissions.ROLE_STO_OPERATOR) : fromReport

    const colCountByScreen = fromReport ? {
        lg: 2,
        md: 2,
        sm: 2,
        xs: 1,
    } : {
        lg: 5,
        md: 4,
        sm: 3,
        xs: 1,
    }
 
    const colCountContactInfo = fromReport ? {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    } : {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    }

    const EditorOptions = {

        contactName: {
            with: '100%',
            readOnly: readonly
        },
        email: {
            with: '100%',
            readOnly: readonly
        },
        phone: {
            with: '100%',
            mask: '+0 (X00) 000-00-00', 
            maskChar: '_',
            maskRules: { X: /[02-9]/ },
            readOnly: readonly
        },
        comment: {
            with: '100%',
            readOnly: readonly
        },
        inn: {
            with: '100%',
            readOnly: readonly
        },
        companyName: {
            with: '100%',
            readOnly: readonly
        },
    }


    const { t } = useTranslation('client');

    const getData = async () => {

        try{
            const result = await Client.controller.getClientEntityById(fromReport ? id : urlId)
            form.updateData(result)
        } catch(err:any){
            manageRef?.current?.setError(err.message)
        }
        
        manageRef?.current?.setIsLoading(false)
    }

    const handleSubmit = async () => {

        const editor = form.getEditor('phoneContactName')

        const values = {
            ...data,
            companyName: data.name,
            phoneContactName: data.phoneContactName ? editor.option('text').replace(/\s/g, ""): ''
        }

        delete values.name
        delete values.address
        delete values.phone

        try{
            await Client.controller.updateEntity(values)
            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
              }, 'success', 3000);

            if(updateData){
                updateData()
            }

            handleClose()

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        } 
    }

    const handleDataChange = (e: any) => {
        const button = form.getButton('submit')

        if(button){
            if(e.component.validate().isValid){
                button.option('disabled', false)
            } else{
                button.option('disabled', true)
            }
        }
    }


    useEffect(() => {

        if ((id || urlId) && form){
            manageRef?.current?.setIsLoading(true)
            getData()
        }

    }, [form,id, urlId])

    useEffect(() => {
        if (form){
            manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`, false)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.options.onClick`, handleSubmit)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, !readonly)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, readonly)
        }
    }, [data, form])
    

    return (
            <form >
                <Form
                    onContentReady={e => e.component.validate()}
                    formData={data}
                    onFieldDataChanged={handleDataChange}
                    onInitialized={(e:any ) => {setForm(e.component)}}
                    id="form">
                    <GroupItem colCountByScreen={colCountByScreen}>
                        <Item 
                            dataField="name" 
                            label={{text: t('companyName')}} 
                            editorOptions={EditorOptions.companyName} 
                            isRequired
                            />
                        <Item 
                            dataField="inn" 
                            label={{text: t('inn')}} 
                            editorOptions={EditorOptions.inn}
                            validationRules={validationRules.inn}
                            isRequired
                            />
                        {fromReport && (
                            <GroupItem 
                                colSpan={colSpanItem(size, colCountByScreen)}
                                colCountByScreen={colCountContactInfo}
                                >
                                <Item 
                                    dataField="contactName" 
                                    label={{text: t('contactName')}} 
                                    editorOptions={EditorOptions.contactName} 
                                    validationRules={validationRules.clientFio}
                                    isRequired
                                    />
                                <Item 
                                    dataField="phoneContactName" 
                                    label={{text: t('phone')}} 
                                    editorOptions={EditorOptions.phone} 
                                    />
                                <Item 
                                    dataField="email" 
                                    label={{text: t('email')}} 
                                    editorOptions={EditorOptions.email}
                                    validationRules={validationRules.email}
                                    />
                        </GroupItem>
                        )}

                        {!fromReport && (
                            <Item 
                                dataField="contactName" 
                                label={{text: t('contactName')}} 
                                editorOptions={EditorOptions.contactName} 
                                validationRules={validationRules.clientFio}
                                isRequired
                                />
                        )}
                        {!fromReport && (
                            <Item 
                                dataField="phoneContactName" 
                                label={{text: t('phone')}} 
                                editorOptions={EditorOptions.phone} 
                                />
                        )}
                        {!fromReport && (
                            <Item 
                                dataField="email" 
                                label={{text: t('email')}} 
                                editorOptions={EditorOptions.email}
                                validationRules={validationRules.email}
                                />
                        )}
                    </GroupItem>
                    <Item 
                        dataField="comment" 
                        label={{text: t('comment')}} 
                        colSpan={colSpanItem(size, colCountByScreen)} 
                        editorOptions={EditorOptions.comment}
                        />
                    
                    { !fromReport && (
                        <ButtonItem
                            colSpan={colSpanItem(size, colCountByScreen)}
                            name='submit'
                            visible={!readonly}
                            buttonOptions={{
                                type: 'default',
                                text: 'Сохранить',
                                onClick: () => handleSubmit()
                            }}
                            />
                    )}
                </Form>
            </form>
    )
}