import React, { useEffect, useRef, useState } from "react";
import { PageBase } from "~/ui/atoms/PageBase";
import { GenerateActListForm, GenerateActListFormRef } from "./atoms/GenerateActListForm";
import { bemHelper } from "~/libs";

import { Act, Acts } from "~/api/acts";
import { CreateActForm, CreateActFormRef } from "./atoms/CreateActForm";

import './styles.scss'
import { Common } from "~/types";
import { Model, ModelById } from "~/api/records";
import notify from 'devextreme/ui/notify';
import { useHistory } from "react-router";
import { PageLoader } from "~/ui/atoms";
import { LoadIndicator } from "devextreme-react";


export const CreateAct = () => {
    const cn = bemHelper('create-act')
    const history = useHistory()
    const generateActListFormRef = useRef<GenerateActListFormRef>(null) 
    const createFormRef = useRef<CreateActFormRef>(null)
    const [ isLoadingList, setIsLoadingList ] = useState(false)

    const [ isVisibleCreateForm, setIsVisibleCreateForm ] = useState(false)

    const handleGenerateSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoadingList(true)
        const formData = generateActListFormRef.current?.formData
        if(formData){
            const result = await Acts.controller.generateDkList({
                ...formData,
                from: formData.from.toLocaleDateString('sv'),
                to:formData.to.toLocaleDateString('sv')
            })
            createFormRef.current?.setFormData(result)
            createFormRef.current?.setDisabledButton(result.length == 0)

            setIsLoadingList(false)
            setIsVisibleCreateForm(true)
        }
    }

    const handleCreateSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const createFormData: any = createFormRef.current?.getFormData()
        const generateFormData = generateActListFormRef.current?.formData
        const data = {
            partnerId: generateFormData?.partnerId || 0,
            dkIdList: createFormData.map((i:ModelById) => i.id)
        }

        try{
            await Acts.controller.createAct(data)
            notify({
                message: 'Акт успешно создан',
                position: 'top center',
              }, 'success', 3000);
            history.push('/app/acts/all')
        } catch(err: any){
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        }
    
    }

    useEffect(() => {
        createFormRef.current?.setFormData([])
    }, [])

    return (
        <PageBase
            title="Создать акт"
            >
            <div
                className={cn('content')}
                >
                <form
                    onSubmit={handleGenerateSubmit}
                    >
                    <GenerateActListForm
                        ref={generateActListFormRef}
                        /> 
                </form>   
            </div>
            <div
                className={cn('create-act')}
                >
                <form
                    onSubmit={handleCreateSubmit} 
                    hidden={!isVisibleCreateForm || isLoadingList}
                    >
                    <CreateActForm
                        ref={createFormRef}
                        />
                </form>
                {isLoadingList && (
                    <div
                        className={cn('loader-box')}
                        >
                        <LoadIndicator/>
                    </div>
                )}
            </div>
            
            
        </PageBase>
    )
}