import React, { useContext, useEffect, useState } from 'react'
import EditBase from '~/ui/atoms/EditBase';

import find from 'list-files'
import { Link, useHistory } from 'react-router-dom';
import { bemHelper } from '~/libs';

import './style.scss'
import { Common } from '~/types';
import { AuthContext } from '~/context';
import { ComponentProps } from '~/ui/atoms/PopupEdit';

type Permissions = Common.SubPermissions | Common.Permissions | 'COMMON'

type InstructionsDictType = {
  [P in Permissions]: string[]
}


const cn = bemHelper('instructions');

export const MainComponent: React.FC<ComponentProps> = ({manageRef}) => {

    const [ list, setList ] = useState<string[]>([])
    const history = useHistory()
    const { authState: {
      userData
    } } = useContext(AuthContext);

    useEffect(() => {
      fetch('data/instructions/list.json').then(async (response) => {

        const dictInstructions: InstructionsDictType = await response.json()

        const list: string[] = []

        if(dictInstructions['COMMON']){
          list.push(...dictInstructions['COMMON'])
        }
        userData?.roles.map(perm => {
          const tempList = dictInstructions[perm]
          if(tempList){
            list.push(...tempList)
          }
        })
        
        if(list?.length){
          setList(list.sort((a:string, b:string) => {
            const aNum = Number(a.split('_')[0])
            const bNum = Number(b.split('_')[0])
    
            if(aNum > bNum){
              return 1
            } else {
              return -1 
            }
          }))
          manageRef?.current?.setIsLoading(false)
        }
  
       
      })
    }, [userData?.roles])

    return (

      <div className={cn('box-link')}>
        {list.map(i => (
          <span
            onClick={() => {
              history.push(`/app/instructions/${i}`, {
                  pathname: '/app/instructions',
                  isBack: true,
              })
            }} 
            className={cn('link')}
            >
             {i.split('_')[1]}
         </span> 
        ))}
      </div>
    
    )

}


const Main = () => {
    
  return (
      <EditBase Component={MainComponent} title='Инструкции'/>
  )
}

export default Main