import { Common } from '~/types';
import { ActiveRecords } from './ActiveRecords';
import { RecordsScheduler } from './RecordsScheduler';

export const ROUTES = [
  {
    Component: RecordsScheduler,
    permissionComponent: [Common.Permissions.ROLE_STO_ADMINISTRATOR, Common.Permissions.ROLE_STO_OPERATOR,],
    exact: true,
    path: '/app/planer',
  },

  {
    Component: ActiveRecords,
    permissionComponent: 'All',
    exact: true,
    path: '/app/records',
  },

];
