import React, { forwardRef, useImperativeHandle, useState } from "react"
import './styles.scss'
import { bemHelper } from "~/libs"
import { LoadIndicator } from "devextreme-react"

type Props = {}

export type LoaderRef = {
    setIsVisible: (value: boolean) => void,
}

const cn = bemHelper('loader')

export const Loader = forwardRef<
    LoaderRef,
    Props
>(({}, ref) => {
    const [ isVisible, setIsVisible ] = useState(true)

    useImperativeHandle(ref, () => ({
        setIsVisible: setIsVisible
    }))

    return (
        <div 
            className={cn('box')}
            hidden={!isVisible}
            >
            <LoadIndicator />
        </div>
    )
})