import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import { bemHelper } from '~/libs';
import { useParams } from 'react-router-dom';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import './styles.scss'

import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { AutoType, Records } from '~/api';
import { Sto, ModelSto } from '~/api/sto';
import { AuthContext } from '~/context';
import { Common } from '~/types';
import { validationRules } from '~/routes/Records/services';
import { useWindowSize } from '~/hooks/useWindowSize';
import { colSpanItem } from '../service';
import { StationLocationMap } from './atoms/StationLocationMap/index';
import { ComponentProps } from '~/ui/atoms/PopupEdit';
import { checkPermission } from '~/utils/checkPermission';
import { ToolbarButton } from '../../CustomPopup';
import { useColCountEditStation, useEditOptionsEditStation } from './hooks';
import { weekDays } from './constants';
import { dataSource } from './dataSource';
import { Model } from '~/api/autotype';


type ParamsProps = {
    urlId: string
}

export const EditStationComponent: React.FC<ComponentProps> = memo(({
    id, 
    updateData, 
    manageRef,
    fromReport = false, 
    activeRecord=false
}) => {

    const size = useWindowSize()
    const { t } = useTranslation('sto');
    const { urlId } = useParams<ParamsProps>()
    const { authState: {
        userData
        }} = useContext(AuthContext);    
    const cn = bemHelper('edit-station');

    const refForm = useRef<Form>(null)

    const [data, setData] = useState<ModelSto | any>({
        tsLimit: 1,
        autoType: [],
        startWorkTime: new Date(1970, 1, 1, 9, 0),
        endWorkTime: new Date(1970, 1, 1, 19, 0),
        startHolidayTime: new Date(1970, 1, 1, 9, 0),
        endHolidayTime: new Date(1970, 1, 1, 19, 0),
    })
    const [ autoTypes, setAutoTypes ] = useState<any[]>([])

    const isStoDirector = userData?.roles.includes(Common.SubPermissions.ROLE_STO_DIRECTOR)
    const isAdmin = userData?.roles.includes(Common.SubPermissions.ROLE_ADMIN)

    const stoId = userData?.sto ? userData?.sto.id : false
    
    const isDirectorOrAdmin = ((isStoDirector || isAdmin) && stoId && stoId === data.id)

    const [
        colCountByScreen,
        colCountTimePeriodGroup,
        colCountTypesPriceGroup,
        colSpanTypesPriceGroup,
        colCountSomeInfo,
    ] =  useColCountEditStation({
        fromReport,
        isDirectorOrAdmin
    })

    const disableWeekDayTimeFields = (key: string, value: boolean) => {
        refForm.current?.instance.updateData(`${key}TimeStart`, null)
        refForm.current?.instance.updateData(`${key}TimeEnd`, null)
        const timeStartEditor = refForm.current?.instance.getEditor(`${key}TimeStart`)
        const timeEndEditor = refForm.current?.instance.getEditor(`${key}TimeEnd`)
        
        timeEndEditor?.option('disabled', value)
        timeStartEditor?.option('disabled', value)
        handleDataChange()
    }

    const EditorOptions = useEditOptionsEditStation({
        id,
        isAdmin,
        isDirectorOrAdmin,
        autoTypes,
        refForm,
        data,
        disableWeekDayTimeFields,
    })
    

    const getAutoTypes = async () => {
        const result:any = await dataSource.autoTypes.load()
        setAutoTypes(result)
    }


    const getPriceAll = async (stoId: number) => {
        const result = await Records.controller.getPriceAll({
                stoId: stoId,
                agentId: Number(userData?.agent.id)
            })

        delete result.sto_id
        delete result.agent_id

        refForm.current?.instance.updateData({
            ...result
        })
    }

    const getData = async () => {
        getAutoTypes()

        try{
            const result = await Sto.controller.getById(fromReport ? Number(id) : Number(urlId))

            getPriceAll(result.id)

            const resultAny: any = result
            const workTimes: Record<string, any> = {}
            weekDays.map((weekDay) => {
                if(resultAny[`${weekDay.key}HourStart`] == null){
                    workTimes[`${weekDay.key}NotWorking`] = true
                    disableWeekDayTimeFields(weekDay.key, true)
                    return
                }
                workTimes[`${weekDay.key}TimeStart`] = new Date(1970, 1, 1, resultAny[`${weekDay.key}HourStart`], resultAny[`${weekDay.key}MinuteStart`])
                workTimes[`${weekDay.key}TimeEnd`] = new Date(1970, 1, 1, resultAny[`${weekDay.key}HourFinish`], resultAny[`${weekDay.key}MinuteFinish`])
            })

            setData({
                ...result,
                regionId: result.region_id,
                phone: result.phone.replaceAll(/[() \-+]/gi, ""),
                districtId: result.district_id,  
                ...workTimes
            })
        
        } catch(err:any){
            manageRef?.current?.setError(err.message)
        }

        
        manageRef?.current?.setIsLoading(false)
    }


    const handleSubmit = async () => {

        const editor = refForm.current?.instance.getEditor('phone')
        const phone: any = editor?.option('text') || ""

        const values = {
            ...data,
            phone: data.phone ? phone: '',
        }

        const dataAny = data as any
        weekDays.map((weekDay) => {
            const notWorkingDay = dataAny[`${weekDay.key}NotWorking`]
            
            values[`${weekDay.key}HourStart`] = notWorkingDay ? null : dataAny[`${weekDay.key}TimeStart`].getHours()
            values[`${weekDay.key}MinuteStart`] = notWorkingDay ? null : dataAny[`${weekDay.key}TimeStart`].getMinutes()

            values[`${weekDay.key}HourFinish`] = notWorkingDay ? null : dataAny[`${weekDay.key}TimeEnd`].getHours()
            values[`${weekDay.key}MinuteFinish`] = notWorkingDay ? null : dataAny[`${weekDay.key}TimeEnd`].getMinutes()

            delete values[`${weekDay.key}TimeEnd`]
            delete values [`${weekDay.key}TimeStart`]
            delete values [`${weekDay.key}NotWorking`]
            delete values [`${weekDay.key}WorkTime`]
        })

        const resultPrice:any = {
            "sto_id": data.id,
            "agent_id": 0
        }

        data.autoType.map((id: number) => {
            const name = autoTypes.filter(_i => _i.id == id)[0].name.toLowerCase()
            if(data[name]){
                resultPrice[name] = values[name]
                delete values[name]
            }
        })

        delete values.endHolidayTime
        delete values.endWorkTime
        delete values.startHolidayTime
        delete values.startWorkTime
        delete values.region_id,
        delete values.district_id

        try{
            await Sto.controller.update(values)

            await Records.controller.patchPriceSto(resultPrice)
            
            notify({
                message: `Запись успешно ${urlId ? 'обновлена': 'создана'}`,
                position: 'top center',
              }, 'success', 3000);

            if(updateData){
                updateData()
            }

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        } 
    }

    const handleDataChange = () => {
        const button = refForm.current?.instance.getButton('submit')

        if (button){
            if(refForm.current?.instance.validate().isValid && data.lat && data.lon){
                button.option('disabled', false)
            } else{
                button.option('disabled', true)
            }
        }
    }

    useEffect(() => {
        if(id || urlId){
            manageRef?.current?.setIsLoading(true)
            getData()
        }
    }, [])

    useEffect(() => {
        if(refForm.current){
            autoTypes.map((_i: Model) => {
                if (data.autoType.includes(_i.id)){
                    refForm.current?.instance.itemOption(`autoTypeGroup.autoTypePrices.${_i.name.toLowerCase()}`, 'visible', true)
                } else {
                    refForm.current?.instance.itemOption(`autoTypeGroup.autoTypePrices.${_i.name.toLowerCase()}`, 'visible', false)
                }
            })
        }
    }, [data, autoTypes])

    useEffect(() => {
        if (refForm.current){
            manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`,false)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.options.onClick`,handleSubmit)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, !!(!id || isDirectorOrAdmin))
            manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, !(!id || isDirectorOrAdmin))
        }
    }, [refForm.current])

    return (
            <form>
                <Form
                    onContentReady={e => e.component.validate()}
                    formData={data}
                    colCountByScreen={colCountByScreen}
                    onFieldDataChanged={handleDataChange}
                    ref={refForm}
                    id="form"
                    >

                    <Item 
                        dataField="name" 
                        label={{text: t('name')}} 
                        editorOptions={EditorOptions.name} 
                        isRequired
                        />
                    <Item 
                        dataField="nameShort" 
                        label={{text: t('nameShort')}} 
                        editorOptions={EditorOptions.nameShort} 
                        isRequired
                        />

                    <Item 
                        dataField="address" 
                        colSpan={2}
                        cssClass={'form-group-no-padding'}
                        label={{text: t('address')}} 
                        editorOptions={EditorOptions.address} 
                        isRequired
                        />
                    { fromReport && (
                        <GroupItem 
                            colCount={colSpanItem(size, colCountSomeInfo )} 
                            colSpan={colSpanItem(size, colCountByScreen)}
                            name={"first-block"}
                            >
                            <Item 
                                dataField="phone" 
                                label={{text: t('phone')}} 
                                editorOptions={EditorOptions.phone} 
                                validationRules={validationRules.phone}
                                />
                            
                            <Item 
                                dataField="email" 
                                label={{text: t('email')}} 
                                editorOptions={EditorOptions.email} 
                                validationRules={validationRules.email}
                                />
                            

                            <Item 
                                dataField="licenseNumber" 
                                label={{text: t('licenseNumber')}} 
                                editorOptions={EditorOptions.licenseNumber} 
                                />    

                            <Item 
                                dataField="tsLimit" 
                                label={{text: t('tsLimit')}} 
                                editorOptions={EditorOptions.tsLimit} 
                                isRequired
                                editorType="dxNumberBox"
                                />    
                            
                            <Item 
                                dataField="regionId" 
                                label={{text: t('region')}} 
                                editorOptions={EditorOptions.region} 
                                editorType="dxSelectBox"
                                isRequired
                                />

                            <Item 
                                dataField="districtId" 
                                label={{text: t('district')}} 
                                editorOptions={EditorOptions.district} 
                                editorType="dxSelectBox"
                                isRequired
                                />
                        </GroupItem>
                    )}
                        <Item 
                            dataField="phone" 
                            label={{text: t('phone')}} 
                            editorOptions={EditorOptions.phone} 
                            validationRules={validationRules.phone}
                            visible={!fromReport}
                            />
                        
                        <Item 
                            dataField="email" 
                            label={{text: t('email')}} 
                            editorOptions={EditorOptions.email} 
                            visible={!fromReport}
                            validationRules={validationRules.email}
                            />
                        

                        <Item 
                            dataField="licenseNumber" 
                            label={{text: t('licenseNumber')}} 
                            editorOptions={EditorOptions.licenseNumber} 
                            visible={!fromReport}
                            />    

                        <Item 
                            dataField="tsLimit" 
                            label={{text: t('tsLimit')}} 
                            editorOptions={EditorOptions.tsLimit} 
                            isRequired
                            visible={!fromReport}
                            editorType="dxNumberBox"
                            />    
                        
                        <Item 
                            dataField="regionId" 
                            label={{text: t('region')}} 
                            editorOptions={EditorOptions.region} 
                            editorType="dxSelectBox"
                            visible={!fromReport}
                            isRequired
                            />

                        <Item 
                            dataField="districtId" 
                            label={{text: t('district')}} 
                            editorOptions={EditorOptions.district} 
                            editorType="dxSelectBox"
                            visible={!fromReport}
                            isRequired
                            />

                    <GroupItem name={"times-block"} colCount={6} colSpan={colSpanItem(size, colCountByScreen)} cssClass={cn('time-work-group')} >
                        {weekDays.map(weekDay => (
                            <GroupItem name={`${weekDay.key}TimeBlock`} key={weekDay.key} colCount={colSpanItem(size, colCountTimePeriodGroup)}  colSpan={colSpanItem(size, colCountTimePeriodGroup)} caption={weekDay.name} cssClass={cn('time-period-group')}>
                                <Item 
                                    dataField={`${weekDay.key}TimeStart`}
                                    label={{text: t('startTime')}} 
                                    editorOptions={{
                                        ...EditorOptions.startTime,
                                        disabled: refForm.current?.instance.getEditor(`${weekDay.key}TimeStart`)?.option('disabled')
                                    }} 
                                    editorType="dxDateBox"
                                    isRequired
                                    />
                                <Item 
                                    dataField={`${weekDay.key}TimeEnd`}
                                    label={{text: t('endTime')}} 
                                    editorOptions={{
                                        ...EditorOptions.endTime,
                                        disabled: refForm.current?.instance.getEditor(`${weekDay.key}TimeEnd`)?.option('disabled')
                                    }} 
                                    editorType="dxDateBox"
                                    isRequired
                                    />
                                <Item 
                                    dataField={`${weekDay.key}NotWorking`}
                                    label={{visible:false}} 
                                    editorOptions={EditorOptions.notWorkingDay} 
                                    editorType="dxCheckBox"
                                    />
                            </GroupItem>
                        ))}
                    </GroupItem>
                    <GroupItem colCount={colSpanItem(size, colCountByScreen)} colSpan={colSpanItem(size, colCountByScreen)} cssClass={cn('time-work-group')} name='autoTypeGroup'>
                        {isDirectorOrAdmin && ( 
                            <Item 
                                dataField="autoType" 
                                label={{
                                    text: checkPermission(userData?.roles, Common.SubPermissions.ROLE_STO_DIRECTOR)? t('accreditedAutoType') :t('autoType')
                                }} 
                                editorOptions={EditorOptions.autoType} 
                                editorType="dxTagBox"
                                isRequired
                                />
                        )} 
                        <GroupItem colCount={colSpanItem(size, colCountTypesPriceGroup)}  
                            colSpan={colSpanItem(size, colSpanTypesPriceGroup)} caption={'Стоимость:'} 
                            name='autoTypePrices' 
                            cssClass={cn('price-group')}>
                            { autoTypes.map(type => (
                                <Item 
                                    key={type.id}
                                    visible={false}
                                    dataField={type.name.toLowerCase()} 
                                    label={{text: `${type.name}:`, location: 'left'}} 
                                    editorOptions={EditorOptions.autoTypePrice} 
                                    editorType="dxNumberBox" 
                                    isRequired
                                    />
                            ))}
                        </GroupItem>
                    </GroupItem>
                    
                    {isStoDirector && (
                        <GroupItem name={"BlockRequiredApproved"} colSpan={colSpanItem(size, colCountByScreen)} colCount={4}>
                            <Item 
                                dataField="requiredApprovedOrder" 
                        
                                label={{text: t('requiredApprovedOrder'), location: 'right'}} 
                                editorType="dxCheckBox" 
                                editorOptions={EditorOptions.requiredApprovedOrder}
                                />
                        </GroupItem>
                    )}

                    <Item 
                        dataField="comment" 
                        colSpan={colSpanItem(size, colCountByScreen)}
                        label={{text: t('comment')}} 
                        editorOptions={EditorOptions.comment}
                        />

                    {/* Для ROLE_ADMIN */}
                    {isAdmin && (
                    <Item 
                        dataField="margin" 
                        label={{text: t('margin')}} 
                        editorType="dxNumberBox" 
                        editorOptions={EditorOptions.margin}
                        isRequired
                        />
                    )}
                    {isAdmin && (
                    <Item 
                        dataField="active" 
                        label={{text: t('active'), location: 'left'}} 
                        editorType="dxCheckBox" 
                        cssClass={cn('checkbox-active')}
                        editorOptions={EditorOptions.active}
                        />
                    )}
                    { !fromReport && (!id || isDirectorOrAdmin) && (
                        <ButtonItem
                            colSpan={colSpanItem(size, colCountByScreen)}
                            name='submit'
                            buttonOptions={{
                                type:'default',
                                text: 'Сохранить',
                                disabled: true,
                                onClick: () => handleSubmit()
                            }}
                        />
                    )}
                </Form>
                <div className={cn('map')}>
                    <div className={cn('title-map')}>
                        <span>Местоположение на карте:</span>
                    </div>
                    <StationLocationMap data={data}/>
                </div>
            </form>
    )
})

