import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router';
import { bemHelper } from '~/libs';
import './styles.scss';
import { Common } from '~/types';
import { AddRecordPopup } from '../AddRecordPopup';
import { AuthContext, PopupContext } from '~/context';
import PopupEdit from '../PopupEdit';
import { checkPermission } from '../../../utils/checkPermission';


const cn = bemHelper('fade-in-route');

interface IFadeInRouteProps
  extends Omit<RouteProps, 'component' | 'render' | 'children'> {
  Component: React.ComponentType | any;
}


export const FadeInRoute: React.FC<IFadeInRouteProps> = (props) => {

  const { Component, path, ...otherProps } = props;

  const {
    authState: {userData },
  } = useContext(AuthContext);


  return (
    <Route
      render={() => (
        <div className={cn()}>
          {(path === '/app/planer' && checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR)) ? (
            <Component />
          ) : (
            <Component />
          )}
          
        </div>
      )}
      path={path}
      {...otherProps}
    />
  );
}