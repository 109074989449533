import React, { useEffect, useMemo, useRef, useState } from "react"
import { FC } from "react"
import { Act, ActsQueryData } from "~/api/acts"

import './styles.scss'
import { bemHelper } from "~/libs";
import { ActTableRef, ActsBaseProps, ActsDataGridRef } from "./ActsBase.types";
import { FilterForm, FilterFormRef } from "./atoms/FilterForm";
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from "devextreme-react";
import { Common } from "~/types";
import { CellDblClickEvent } from 'devextreme/ui/data_grid';
import { useHistory, useLocation } from "react-router";
import { filterInAndOutActs } from "./service";
import { clearData } from "~/libs/utils";


export const ActsBase: FC<ActsBaseProps> = ({
    Table,
    title,
    dataSource,
    excludeColumn,
    incomingAndOutgoingTables=false,
}) => {
    const cn = bemHelper('acts-base');
    const history = useHistory()
    const location = useLocation()

    const filterForm = useRef<FilterFormRef>(null)
    const incomingTableRef = useRef<ActsDataGridRef>(null)
    const outgoingTableRef = useRef<ActsDataGridRef>(null)

    const [ isLoading, setIsLoading ] = useState(false)

    const handleSubmitFilter = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const filterData: ActsQueryData = filterForm.current?.instance.option('formData')

        getData(clearData(filterData))
        filterForm.current?.instance.option('formData', filterData)
    }

    const getData = (search?: ActsQueryData) => {
        setIsLoading(true)
        dataSource.load({
            searchValue: search
        }).then((result: any) => {
            if(incomingAndOutgoingTables){
                const {incoming, outgoing} = filterInAndOutActs(result)
                incomingTableRef.current?.instance.option('dataSource', incoming)
                outgoingTableRef.current?.instance.option('dataSource', outgoing)
            } else {
                incomingTableRef.current?.instance.option('dataSource', result)
            }
            setIsLoading(false)
        })
        .catch(error => {
            setIsLoading(false)
            notify({
                message: `Упс... Произошла ошибка ${error.message}`,
                position: 'top center',
            }, 'error', 3000);
        })
    }

    const updateData = () => {
        getData()
    }

    const recoverFilterData = (data: ActsQueryData) => {
        filterForm.current?.instance.updateData(data)
    }

    const pushToActDetail = (aktId: string) => {
        history.push(`/app/act/${aktId}`, {
            pathname: location.pathname,
            isBack: true,
            backOptions: {
                filterData: filterForm.current?.instance.option('formData')
            }
        })
    }

    useEffect(() => {
        const filterData = location.state?.filterData
        if(filterData){
            recoverFilterData(filterData)
        }
        getData(clearData(filterData))
    }, [])

    return (
        <div className={'paper-tab ' + cn()}>
            <h1 className={cn('title')}>
                {title}
            </h1>

            <form 
                className={cn('filter-bar')}
                onSubmit={handleSubmitFilter}
                >
                <FilterForm
                    ref={filterForm}
                    />
            </form>

            <div
                className={cn('table')}
                >
                <h2
                    className={cn('table-title')}
                    hidden={!incomingAndOutgoingTables}
                    >
                    Входящие
                </h2>
                <div
                    hidden={!isLoading}
                    className={cn('loader')}
                    >
                    <LoadIndicator/>
                </div>
                <Table
                    ref={incomingTableRef}
                    updateData={updateData}
                    excludeColumn={excludeColumn}
                    pushToActDetail={pushToActDetail}
                    height={incomingAndOutgoingTables? '40hv': undefined}
                    />
            </div>

            <div
                className={cn('table')}
                hidden={!incomingAndOutgoingTables}
                >
                <div
                    hidden={!isLoading}
                    className={cn('loader')}
                    >
                    <LoadIndicator/>
                </div>
                <h2
                    className={cn('table-title')}
                    >
                    Исходящие
                </h2>
                <Table
                    ref={outgoingTableRef}
                    updateData={updateData}
                    pushToActDetail={pushToActDetail}
                    excludeColumn={excludeColumn}
                    height={'40hv'}
                    />
            </div>

            
        </div>
    )
}