import { ky } from '~/api/kyInstance';

export type PartnerModel = {
    id: number,
    name: string,
    agentsId?: number[],
    stoId?: number[], 
    comment?: string
};


export namespace Partner {
  
  export class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getPartners(): Promise<PartnerModel[]> {
      return ky.get(`${this.url}/partners`).json();
    }

  }

  export const controller = new Controller('api');
}
