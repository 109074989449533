import React, { useEffect,  useState} from 'react';

const windowSize = (width: number) => {

    if (width >1200) {
        return 'lg'
    } else if (width <= 1200 && width > 991){
        return 'md'
    } else if (width <= 991 && width > 768){
        return 'sm'
    } else {
        return 'xs'
    }
}

export function useWindowSize() {
    const [size, setSize] = useState<string>(windowSize(window.innerWidth))
    
 
    //choose the screen size 
    const handleResize = () => {
    
        setSize(windowSize(window.innerWidth))
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    }, [])

  
    return size
  }
