import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { App } from './App';
import './i18n';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'devextreme/dist/css/dx.common.css';
import '~/assets/css/dx.material.utrace-material.css';
import '~/assets/css/ReactToastify.css';
import '~/assets/css/reboot.css';
import '~/assets/css/common.scss';
import { AuthProvider, NotificationProvider, PopupProvider } from '~/context';


ReactDOM.render(
  <>
  <NotificationProvider>
    <AuthProvider>
      <PopupProvider>
        <App />
      </PopupProvider>
    </AuthProvider>
  </NotificationProvider>
      
  
    <ToastContainer
      closeOnClick={false}
      draggable={false}
      position="bottom-right"
      transition={Slide}
    />
  </>,
  document.getElementById('root')
);
