import { useEffect, useState } from "react";

export const usePressKey = (keyTarget: string, func: () => void) => {
    const [ isKeyPressed, setKeyPressed ] = useState(false)

    const handlerPress = ({key}:any) => {
        if(key === keyTarget ){
            func()
        }
    }


    useEffect(() => {

        window.addEventListener('keyup',handlerPress)

        return () => {
            window.removeEventListener('keyup',handlerPress)
        }


    }, [])

    return isKeyPressed

}