import { Form } from "devextreme-react";
import { useMemo } from "react"
import { Addressing, AutoType } from "~/api";
import { Model } from "~/api/autotype";
import { dataSource, DataSourceEditStation } from "../dataSource";
import { priceAdorment } from "~/routes/Records/services";
import { useTranslation } from "react-i18next";

type RefForm = React.RefObject<Form>


type Props = {
    id?: number,
    isAdmin?: boolean,
    isDirectorOrAdmin: boolean,
    autoTypes: Model[],
    data: any,
    refForm:  RefForm,
    disableWeekDayTimeFields: (key: string, value: boolean) => void,
}

export const useEditOptionsEditStation = ({
    id,
    isAdmin,
    isDirectorOrAdmin,
    autoTypes,
    data,
    refForm,
    disableWeekDayTimeFields,
}: Props) => {
    const { t } = useTranslation('sto');

    return useMemo(() => ({
        name: {
            readOnly: id || !isAdmin,
        },
        nameShort: {
            readOnly: id || !isAdmin,
        },
        autoType: {
            dataSource: dataSource.autoTypes,
            deferRendering: false,
            showSelectionControls: true,
            showMultiTagOnly: false,
            maxDisplayedTags: 2,
            displayExpr: 'name',
            valueExpr: 'id',
            readOnly: id || !isDirectorOrAdmin,
            onValueChanged: async ({value}: any) => {
                
                autoTypes.map(_i => {
                    if (value.includes(_i.id)){
                        refForm.current?.instance.itemOption(`autoTypeGroup.autoTypePrices.${_i.name.toLowerCase()}`, 'visible', true)
                    } else {
                        refForm.current?.instance.itemOption(`autoTypeGroup.autoTypePrices.${_i.name.toLowerCase()}`, 'visible', false)
                    }
                })
            }
        },
        autoTypePrice: {
            readOnly: id || !isDirectorOrAdmin,
            buttons: [priceAdorment]
        },
        licenseNumber: {
            readOnly: id || !isDirectorOrAdmin,
        },
        tsLimit: {
            readOnly: id || !isDirectorOrAdmin,
            defaultValue: 1,
            min:1,
            max:10,
        },
        address: {
            readOnly: id || !isDirectorOrAdmin,
        },
        phone: {
            readOnly: id || !isDirectorOrAdmin,
            mask: '+0 (X00) 000-00-00', 
            maskChar: '_',
            maskRules: { X: /[02-9]/ },
        },
        email: {
            readOnly: id || !isDirectorOrAdmin,
        },
        region: {
            readOnly: id || !isDirectorOrAdmin,
            dataSource: dataSource.regions,
            deferRendering: false,
            displayExpr: 'name',
            valueExpr: 'id',
            onValueChanged: ({value}:any) => {
                const districtEditor = refForm.current?.instance.getEditor('districtId')
                getDistrictsByRegion(value, refForm)
                if(districtEditor){
                    districtEditor.option('disabled', false)
                }
                
              }
        },
        district: {
            readOnly: id || !isDirectorOrAdmin,
            disabled: !data?.regionId,
            displayExpr: 'name',
            valueExpr: 'id',
        },
        startTime: {
            readOnly: id || !isDirectorOrAdmin,
            useMaskBehavior: true,
            type: 'time',
        },
        endTime: {
            readOnly: id || !isDirectorOrAdmin,
            type: 'time',
            useMaskBehavior: true,
        },
        notWorkingDay: {
            readOnly: id || !isDirectorOrAdmin,
            text: t('notWorkingDay'),
            onValueChanged: (e:any) => {
                const weekDayKey = e.component.option('name').split("NotWorking")[0]
                disableWeekDayTimeFields(weekDayKey, e.value)
            }
        },
        requiredApprovedOrder: {
            readOnly: id || !isDirectorOrAdmin,
        },
        comment: {
            readOnly: id || !isDirectorOrAdmin,
        },
        active: {
            readOnly: id || !isDirectorOrAdmin,
        },
        margin: {
            readOnly: id || !isDirectorOrAdmin,
            min: 0,
            buttons: [priceAdorment]
        },
    }),[id, isAdmin, isDirectorOrAdmin, refForm.current, data])
}

const getDistrictsByRegion = async (regionId: number, refForm: RefForm) => {
    const districts = await Addressing.controller.getDistrictsByRegion(regionId);
    const districtEditor = refForm.current?.instance.getEditor('districtId')

    districtEditor?.option('dataSource', districts)
}