import React, { memo, useEffect, useState } from 'react'
import { Records, Sto } from '~/api';
import notify from 'devextreme/ui/notify';
import CustomPieChart from '~/ui/atoms/CustomPieChart';
import { getPassedForToday } from '../../services';
import { Model } from '~/api/records';

const TodayPassedPieChart: React.FC = memo(() => {

    const [ data, setData ] = useState<Model[]>([])
    const [ statistics, setStatistics ] = useState<any[]>([])

    const getData = async () => {
        try{
            const result = await Sto.controller.getOrderInfo({
                date: new Date()
                    .toISOString()
                    .split('T')[0],
                days: 1
            }); 
            setData(result)
        } catch(error: any) {
            notify({
                message: `Упс... Произошла ошибка ${error.message}`,
                position: 'top center',
            }, 'error', 3000);
        }
    }

    useEffect(() => {
        getData()
    },[])

    useEffect(() => {
        setStatistics(getPassedForToday(data))
    },[data])

    return (
        <CustomPieChart
                palette="Dark Violet"
                data={statistics}
                title="Записи на сегодня"
                сustomColor
                />
    )
})

export default TodayPassedPieChart