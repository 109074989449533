import { Common } from '~/types';
import { MainStatistics } from './MainStatistics';



export const ROUTES = [
  {
    Component: MainStatistics,
    permissionComponent: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_ADMINISTRATOR],
    exact: true,
    path: '/app/statistics',
  },
];
