import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Auth, Sto } from '~/api';
import { Model } from '~/api/records';
import { authAgentIdStorage, authStoIdStorage, authTokenStorage, initialLocationStorage } from '~/libs';
import { Common } from '~/types';
import { NotificationContext } from '../NotificationContext';
import { ModelSto } from '~/api/sto';
import { checkPermission } from '~/utils/checkPermission';

enum ActionType {
  AUTH_ERROR = 'AUTH_ERROR',
  AUTH_IN = 'AUTH_IN',
  AUTH_GET_USER_AGENT = 'AUTH_GET_USER_AGENT',
  AUTH_GET_USER_STAFF = 'AUTH_GET_USER_STAFF',
  AUTH_GET_USER_STO = 'AUTH_GET_USER_STO',
  AUTH_OUT = 'AUTH_OUT',
  AUTH_START = 'AUTH_START',
  CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR',
  AUTH_IN_FINISHED = 'AUTH_IN_FINISHED',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  CHANGE_IS_MANY_PROFILES = 'CHANGE_IS_MANY_PROFILES'
}

export interface TypeUserData extends Auth.ModelAuth {
  staff: Auth.UserStaff,
  agent: Auth.UserAgent,
  sto?: any,
}

type ReducerState = {
  authError: string | null;
  authInProgress: boolean;
  isAuthorized: boolean;
  isManyProfiles: boolean;
  userData: TypeUserData | null
};

type ReducerAction = {
  type: ActionType;
  payload?: any;
};

const initReducerState = (storedAuthToken?: string | null): ReducerState => {
  return {
    authError: null,
    authInProgress: false,
    isAuthorized: Boolean(storedAuthToken),
    isManyProfiles: false,
    userData: null
  }
  }

const reducer = (state: ReducerState, { type, payload }: ReducerAction) => {
  switch (type) {
    case ActionType.AUTH_START:
      return {
        ...state,
        authError: null,
        authInProgress: true,
        isAuthorized: false,
      };
    case ActionType.AUTH_IN:
      return {
        ...state,
        authInProgress: true,
        userData: {...state.userData, ...payload},
      };

    case ActionType.AUTH_IN_FINISHED:
      return {
        ...state,
        authInProgress: false,
        isAuthorized: true,
        userData: {...state.userData, ...payload},
      };

    case ActionType.AUTH_GET_USER_STAFF:
      return {
        ...state,
        userData: {...state.userData, staff: payload},
      };
    case ActionType.AUTH_GET_USER_AGENT:
      return {
        ...state,
        userData: {...state.userData, agent: payload},
      };
    case ActionType.AUTH_GET_USER_STO:
      return {
        ...state,
        userData: {...state.userData, sto: payload},
      };
    case ActionType.AUTH_OUT:
      return {
        ...state,
        authInProgress: false,
        isAuthorized: false,
        userData: null
      };
    case ActionType.AUTH_ERROR:
      return {
        ...state,
        authError: payload,
        authInProgress: false,
        isAuthorized: false,
      };
    case ActionType.CLEAR_AUTH_ERROR:
      return {
        ...state,
        authError: null,
      };
    case ActionType.AUTH_LOGOUT:
      
      Auth.controller.logOut(String(state.userData?.staff.login))

      return state
    
      case ActionType.CHANGE_IS_MANY_PROFILES:
        return {
          ...state,
          isManyProfiles: payload,
        };
    default:
      throw new Error('Invalid reducer action name at auth');
  }
};

type AuthContextState = {
  authState: ReducerState;
  clearAuthError: () => void;
  changeIsManyProfiles: (value: boolean) => void;
  logIn: (params: Auth.LogInParams) => void;
  logOut: () => void;
  renewToken: () => any,
};

const AuthContext = createContext<AuthContextState>({
  authState: initReducerState(),
  clearAuthError: () => null,
  changeIsManyProfiles: (value: boolean) => null,
  logIn: () => null,
  logOut: () => null,
  renewToken: () => null,
});

// const mapUserData = (userData: Auth.UserData): ReducerState['userData'] => {
//   const { login, name, permissions } = userData;
//   return {
//     login,
//     name,
//     permissions: permissions.map((p) => p.id),
//   };
// };

const AuthProvider: React.FC = (props) => {
  const { children } = props;

  const {
    setFirstLoad,
  } = useContext(NotificationContext);

  const history = useHistory();


  const [state, dispatch] = useReducer(
    reducer,
    authTokenStorage.get(),
    initReducerState
  );

  const clearAuthError = useCallback(() => {
    dispatch({ type: ActionType.CLEAR_AUTH_ERROR });
  }, []);

  const changeIsManyProfiles = useCallback((value: boolean) => {
    dispatch({ type: ActionType.CHANGE_IS_MANY_PROFILES, payload: value });
  }, []);

  const logIn = useCallback(async (params: Auth.LogInParams) => {
    dispatch({ type: ActionType.AUTH_START });
    try {
      authTokenStorage.prestore(params.credentials);
      const authData = await Auth.controller.logIn();

      dispatch({ type: ActionType.AUTH_IN, payload: authData });

      if (!Object.values(Common.Permissions).some(i => authData.roles.includes(i))){
        if (Object.values(Common.SubPermissions).some(i => authData.roles.includes(i))){
          throw new Error('Для вашей учетной записи вход в ЛК не предусмотрен')
        } else {
          throw new Error('У вас нет необходимых прав доступа')
        }
      }

      if (authData.companyShortDescriptionList.length <= 1){

        if(authData?.companyShortDescriptionList.length === 1){
          authAgentIdStorage.store(String(authData?.companyShortDescriptionList[0].agentId))
          authStoIdStorage.store(String(authData?.companyShortDescriptionList[0].stoId))
        }

        const userStaff = await Auth.controller.getUserStaff();
        dispatch({ type: ActionType.AUTH_GET_USER_STAFF, payload: userStaff });

        const userAgent = await Auth.controller.getUserAgent();
        dispatch({ type: ActionType.AUTH_GET_USER_AGENT, payload: userAgent });

        if(checkPermission(authData.roles, [Common.Permissions.ROLE_STO_OPERATOR, Common.Permissions.ROLE_STO_ADMINISTRATOR])){
          const userSto = await Sto.controller.getById(userAgent.id)
          dispatch({ type: ActionType.AUTH_GET_USER_STO, payload: userSto });
        }

        dispatch({ type: ActionType.AUTH_IN_FINISHED });
    } else {
      dispatch({ type: ActionType.CHANGE_IS_MANY_PROFILES, payload: true });
    }

    } catch (error: any) {
      dispatch({ type: ActionType.AUTH_ERROR, payload: error.message });
      authTokenStorage.remove();
      authAgentIdStorage.remove()
      authStoIdStorage.remove()
      initialLocationStorage.remove();
      return <Redirect to={'/auth'} />;
    }

  }, []);

  const logOut = useCallback(async () => {
    dispatch({ type: ActionType.AUTH_LOGOUT});

    authTokenStorage.remove();
    authAgentIdStorage.remove()
    authStoIdStorage.remove()
    initialLocationStorage.remove();
    dispatch({ type: ActionType.CHANGE_IS_MANY_PROFILES, payload: false });
    dispatch({ type: ActionType.AUTH_OUT });

    setFirstLoad(true)
    
    return <Redirect to={'/auth'} />;
    
  }, []);

  const renewToken = useCallback(async () => {
    
    dispatch({ type: ActionType.AUTH_START});
    const storedToken = authTokenStorage.get();
    const agetnId = authAgentIdStorage.get()
    const stoId = authStoIdStorage.get()

    if (storedToken && agetnId && stoId) {
      dispatch({ type: ActionType.AUTH_START });
      try {
        const authData = await Auth.controller.logIn();
        dispatch({ type: ActionType.AUTH_IN, payload: authData });
        
        if (!Object.values(Common.Permissions).some(i => authData.roles.includes(i))){
          throw new Error('У вас нет необходимых прав доступа')
        }

        const userStaff = await Auth.controller.getUserStaff();
        dispatch({ type: ActionType.AUTH_GET_USER_STAFF, payload: userStaff });

        const userAgent = await Auth.controller.getUserAgent();
        dispatch({ type: ActionType.AUTH_GET_USER_AGENT, payload: userAgent });

        if(checkPermission(authData.roles, [Common.Permissions.ROLE_STO_OPERATOR, Common.Permissions.ROLE_STO_ADMINISTRATOR])){
          const userSto = await Sto.controller.getById(userAgent.id)
          dispatch({ type: ActionType.AUTH_GET_USER_STO, payload: userSto });
        }

        dispatch({ type: ActionType.AUTH_IN_FINISHED });
        if (authData.companyShortDescriptionList.length > 1){
          dispatch({ type: ActionType.CHANGE_IS_MANY_PROFILES, payload:true });
        }
        return true

      } catch (error: any) {
        dispatch({ type: ActionType.AUTH_ERROR, payload: error.message });
        authTokenStorage.remove();
        authAgentIdStorage.remove()
        authStoIdStorage.remove()
        
        initialLocationStorage.remove();
        return <Redirect to={'/auth'} />;
      }
      
    } else {
      authTokenStorage.remove();
      authAgentIdStorage.remove()
      authStoIdStorage.remove()
      
      initialLocationStorage.remove();
      dispatch({ type: ActionType.AUTH_ERROR, payload: Error('Упс... Что-то пошло не так') });
      return <Redirect to={'/auth'} />;
    }
  }, []);

  /** updating user access token on app mount or setting sso error */
  useEffect(() => {
    renewToken();
  }, [renewToken]);

  return (
    <AuthContext.Provider
      value={{
        authState: state,
        clearAuthError,
        changeIsManyProfiles,
        renewToken,
        logIn,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
