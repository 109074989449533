import { bemHelper } from "~/libs";
import React, { useMemo } from "react";
import './style.scss'
import { classMerge } from "~/libs/utils";
import { useWeekHours } from "../../hooks";

type Props = {
    itemData: any;
}

const cn = bemHelper('DateCell');

export const DateCell: React.FC<Props> = ({itemData}) => {
    const { currentView, date, text } = itemData;

    const {checkIsWorkingDay}= useWeekHours()

    const isDisabled = useMemo(() => {
        return !checkIsWorkingDay(date)
    }, [date])
  
    return (
        <div className={isDisabled ? classMerge(cn(), cn('disabled')): undefined}>
            <div>{text}</div>
        </div>
    );
};
