import React, {useContext, useEffect, useRef, useState } from "react";
import { Sto, ModelSto } from '~/api/sto'
import { useTranslation } from "react-i18next";
import { bemHelper } from "~/libs";
import { Button } from 'devextreme-react/button';

import "./styles.scss"
import { FormSignUp } from "./atoms/FormSignUp";
import { FormSelectStation } from "./atoms/FormSelectStation";
import { AutoType, Model } from "~/api/autotype";
import { SelectBox } from "devextreme-react";


export const MainStations = () => {
    const cn = bemHelper('main-stations');

    const [ stationSelected, setStationSelected ] = useState<boolean | number >(false)
    const selectedAutoType = useRef<number|null>(1)

    const [ stationsInit, setStationsInit] = useState<ModelSto[] | null>(null)
    const [ autoTypesInit, setAutoTypesInit] = useState<Model[]>([])
    const [ stoAddresses, setStoAddresses ] = useState<ModelSto[]>([])

    const getStations = async () => {
        const result = await Sto.controller.getAll()
        setStationsInit(result)
    }

    const getAutoTypesInit = async () => {
        const result = await AutoType.controller.getAll()
        const newResult = result.map(i => {
            return {
                ...i,
                displayName: `${i.name} (${i.vehicleType})`
            }
        })
        setAutoTypesInit(newResult)
    }

    const getAddresses = (dkAutoType: number) => {
        if(stationsInit){
            setStoAddresses(stationsInit?.filter(i => i.autoType.includes(dkAutoType)))
        }
    }

    const selectStation = (stoId: number) => {
        setStationSelected(stoId)
    }

    useEffect(() => {
        getStations()
        getAutoTypesInit()
    }, [])

    useEffect(() => {
        if(selectedAutoType.current){
            getAddresses(selectedAutoType.current)
        }
    }, [selectedAutoType.current, stationsInit])
    


  return (
    <div className={cn('paper')}>
        { stationSelected && (
            <Button
                width={30}
                icon="back"
                type="normal"
                onClick={() => setStationSelected(false)}
            />
        )}

        <div className={cn('box-title')}>
            <h1>Запись клиента на СТО</h1>
            {stationSelected && (
                <div 
                    className={cn('select-sto-address')}
                    >
                    <SelectBox
                        displayExpr={'address'}
                        focusStateEnabled={false}
                        valueExpr={'id'}
                        useItemTextAsTitle
                        value={stationSelected}
                        dataSource={stoAddresses}
                        onValueChanged={({value}: any) => {
                            selectStation(value)
                        }}
                    />
                </div>
            )}
        </div>
        
        {stationSelected ? (
            <FormSignUp 
                stationsInit={stationsInit}
                autoTypesInit={autoTypesInit}
                selectedDkAutoType={selectedAutoType}
                selectedStation={stationSelected}
                />
            
        ) : (
            <FormSelectStation
                selectStation={selectStation}
                stationsInit={stationsInit}
                autoTypesInit={autoTypesInit}
                selectedAutoType={selectedAutoType}
                />
        )}
        

    </div>
  )
}
