import CustomStore from 'devextreme/data/custom_store';
import DevExpress from 'devextreme';

/**
 * Creates devextreme CustomStore instance with adapted pagination params
 */
type Options<T extends any> = {
  /** Method that gets entity from data-base by provided "key" option */
  byKey?: (key: string) => Promise<T>;
  /** Method that adds entity to data-base */
  insert?: (values: any) => Promise<T>;
  /** Unique data-entity key (most commonly "id") */
  key: string;
  loadMode?: 'processed'|'raw'|undefined;
  /** Method that load paginated data from data-base */
  load: (
    adaptedPagination: DevExpress.data.LoadOptions<T>
  ) => Promise<T>
  
  // Promise<{ data: any[]; totalCount?: number }>;
  /** Method that removes entity from data-base */
  remove?: (key: string) => Promise<any>;
  /** Method that updates entity at data-base */
  update?: (key: string, values: any) => Promise<T>;
}

export const createCustomStore = <T extends any>(options: Options<T>) => {
  const { byKey, insert, key, load, remove, update, loadMode } = options;
  return new CustomStore({
    key,
    loadMode,
    byKey,
    load,
    insert,
    remove,
    update,
  });
};
