import React, { memo, useEffect, useState } from 'react'
import { StationsMap } from '../StationsMap'
import Form, { Item, GroupItem, Label, ButtonItem } from "devextreme-react/form";
import { Addressing } from '~/api';
import { ModelSto } from '~/api/sto';
import SelectBox from 'devextreme-react/select-box';
import { useTranslation } from 'react-i18next';
import { bemHelper } from '~/libs';
import notify from 'devextreme/ui/notify';
import { RegionModel, DistrictModel } from '~/api/addressing';
import { useWindowSize } from '~/hooks/useWindowSize';
import { SelectBoxAutoTypeItem } from '~/ui/atoms/SelectBoxAutoTypeItem';

import './styles.scss'

import { Model } from '~/api/autotype';
import { LabelAutoTypeTemplate } from '~/ui/atoms/LabelAutoTypeTemplate';
import { colSpanItem } from '~/ui/atoms/Edit/service';
import { TablePrices } from '../TablePrices';


type Props = {
    selectStation: (stoId: number) => void,
    stationsInit: ModelSto[] | null,
    selectedAutoType: React.MutableRefObject<number | null>,
    autoTypesInit: Model[],
}


export const FormSelectStation: React.FC<Props> = memo(({selectStation, stationsInit, autoTypesInit, selectedAutoType}) => {

    const { t } = useTranslation('sto');
    const cn = bemHelper('form-select-station');
    const size = useWindowSize()

    const [ stations, setStations] = useState<ModelSto[] | null>(null)
    const [ regions, setRegions ] = useState<RegionModel[] | null>(null)
    const [ districts, setDistricts ] = useState<DistrictModel[] | null>(null)
    const [ addresses, setAddresses ] = useState<{
        id: number,
        name: string
    }[]>([])
    const [form, setForm] = useState<any>()
    const [ filter, setFilter ] = useState<any>({
        dkAutoType: selectedAutoType?.current? selectedAutoType.current : null,
        region: null,
        district: null,
        address: null,
    })

    const colCountByScreen = {
        lg: 9,
        md: 1,
        sm: 1,
        xs: 1,
    }

    const colCountSignInButton = {
        lg: 5,
        md: 5,
        sm: 6,
        xs: 1,
    }
    const colCountCopyButton = {
        lg: 2,
        md: 3,
        sm: 2,
        xs: 2,
    }
    const colCountAddressItem = {
        lg: 13,
        md: 12,
        sm: 12,
        xs: 1,
    }

    const getRegions = async () => {
        const region = await Addressing.controller.getRegions()
        const result: any[] = [
            {
                name: 'Все',
                id: null,
            },
            ...region
        ]
        setRegions(result)
    }  

    const getDistrictById = async (regionId: number ) => {
        if (regionId){
            const districts = await Addressing.controller.getDistrictsByRegion(regionId);
            const result: any[] = [
                {
                    name: 'Все',
                    id: null,
                },
                ...districts
            ]
            setDistricts(result)
        }
    }  

    const getAddresses = (stos: ModelSto[]) => {
        const result: any[] = [{
            name: 'Все',
            id: null,
        }]
        stos.map(_i => {
            if (!result.includes(_i.address)){
                result.push({
                    id: _i.id,
                    name: _i.address
                })
            }     
        })
        setAddresses(result)
    }

    const copyStoAddress = () => {
        const addrs = addresses.filter(_i => _i.id !== null).map(_i=> _i.name).join(';\n')
        navigator.clipboard.writeText(addrs)
        
        notify({
                message: 'Список СТО скопирован в буфер',
                position: 'top center',
              }, 'success', 3000);
    }    

    const filterAllParams = (sto: ModelSto, currentFilter: any) => {
        let result = true
        if(currentFilter.dkAutoType){
            if (!sto.autoType.includes(currentFilter.dkAutoType)){
                result = false
            }
        }

        if(currentFilter.region){
            if (sto.region_id !== currentFilter.region){
                result = false
            }
        }

        if(currentFilter.district){
            if (sto.district_id !== currentFilter.district){
                result = false
            }
        }

        if(currentFilter.address){
            if (sto.address.toLocaleLowerCase() !== addresses.filter(i => currentFilter.address === i.id)[0].name.toLocaleLowerCase() ){
                result = false
            }
        }
        return result
    }

    useEffect(() => {
        getRegions()

    }, [])

    useEffect(() => {
        const newData: any = stationsInit?.filter(_i => filterAllParams(_i, filter))
        setStations(newData)
        if(stationsInit){
            getAddresses(newData)
        }
    }, [stationsInit])


    const EditorOptions = {

        dkAutoType:{
            displayExpr: 'displayName',
            placeholder: '',
            focusStateEnabled:false,
            valueExpr: 'id',
            dataSource: autoTypesInit,
            defaultValue: filter.dkAutoType,
            itemRender: SelectBoxAutoTypeItem,
            dropDownOptions: {
                wrapperAttr :{
                    class: 'select-auto-type'
            }
            },
            onValueChanged: async({value}:any) => {
                selectedAutoType.current = value
                const currentFilter = {
                    ...filter,
                    dkAutoType: value,
                    address: null,
                }
                form.updateData({
                    'address': null,
                })
                setFilter(currentFilter)
                const newData: any = stationsInit?.filter(_i => filterAllParams(_i, currentFilter))
                getAddresses(newData)
                setStations(newData)
            }
        },
        region: {
            displayExpr: 'name',
            searchEnabled: true,
            focusStateEnabled:false,
            valueExpr: 'id',
            dataSource: regions,
            onValueChanged: async({value}:any) => {
                const currentFilter = {
                    ...filter,
                    region: value,
                    district: null,
                    address: null,
                }
                form.updateData({
                    'address': null,
                    'district': null
                })
                setFilter(currentFilter)
                const newData: any = stationsInit?.filter(_i => filterAllParams(_i, currentFilter))
                setStations(newData)
                getAddresses(newData)
                getDistrictById(value)
            }
        },
        district: {
            displayExpr: 'name',
            focusStateEnabled:false,
            searchEnabled: true,
            valueExpr: 'id',
            dataSource: districts,
            onValueChanged: async({value}:any) => {
                const currentFilter = {
                    ...filter,
                    district: value,
                    address: null,
                }
                form.updateData({
                    'address': null,
                })
                setFilter(currentFilter)
                const newData: any = stationsInit?.filter(_i => filterAllParams(_i, currentFilter))
                getAddresses(newData)
                setStations(newData)
            }
        },

        address: {
            focusStateEnabled:false,
            searchEnabled: true,
            displayExpr: 'name',
            valueExpr: 'id',
            value: filter.address,
            dataSource: addresses,
            onValueChanged: async({value}:any) => {
                const currentFilter = {
                    ...filter,
                    address: value,
                }
                setFilter(currentFilter)
                const newData: any = stationsInit?.filter(_i => filterAllParams(_i, currentFilter))
                setStations(newData)
            }
        },

        copyAddress:{
            icon: 'far fa-file-alt',
            hint: 'Cкопировать список СТО в буфер',
            stylingMode: 'text',
            height: '100%',
            type: 'default',
            focusStateEnabled:false,
            hoverStateEnabled:false,
            activeStateEnabled:false,
            onClick: () => copyStoAddress()
        },
        signInFromAddress:{
            text: 'Записать',
            height: '100%',
            type: 'default',
            disabled: !filter.address,
            stylingMode:"contained",
            onClick: () => {selectStation(filter.address)}
        }
    }

  return (
    <>
        <Form
            className={cn('form')}
            onContentReady={e => e.component.validate()}
            colCountByScreen={colCountByScreen}
            showValidationSummary={true}
            onInitialized={(e:any ) => {setForm(e.component)}}
            id="form-stations-map">

            <Item
                dataField="dkAutoType" 
                colSpan={1}
                cssClass={cn('select-autotype')}
                >
                <Label>
                    <LabelAutoTypeTemplate dataField={'dkAutoType'} autoTypes={autoTypesInit}/>
                </Label>
                <SelectBox
                    {...EditorOptions.dkAutoType}
                    />
            </Item>
            <GroupItem
                colCount={size === 'xs' ?1:10}
                colSpan={8}
                >
                <Item
                    dataField="region" 
                    label={{text: t('region')}} 
                    editorOptions={EditorOptions.region} 
                    editorType="dxSelectBox"
                    colSpan={2}
                    /> 
                <Item
                    dataField="district" 
                    label={{text: t('district')}} 
                    editorOptions={EditorOptions.district} 
                    editorType="dxSelectBox"
                    disabled={!filter.region}
                    colSpan={2}
                    />
                <GroupItem
                    colCount={size === 'xs' ?1:20}
                    colSpan={6}
                    >
                        
                    <Item
                        dataField="address" 
                        label={{text: t('address')}} 
                        editorOptions={EditorOptions.address} 
                        editorType="dxSelectBox"
                        
                        colSpan={colSpanItem(size, colCountAddressItem)}
                        /> 
                    <ButtonItem
                        buttonOptions={EditorOptions.signInFromAddress} 
                        verticalAlignment='bottom'
                        colSpan={colSpanItem(size, colCountSignInButton)}
                        cssClass={cn('button-sign')}
                        name={'signInFromAddress'}
                        />
                    <ButtonItem
                        buttonOptions={EditorOptions.copyAddress} 
                        colSpan={colSpanItem(size, colCountCopyButton)}
                        cssClass={cn('button-copy-address')}
                        />
                </GroupItem>
            </GroupItem>
        
        </Form>
        <StationsMap data={stations} selectStation={selectStation}/>

        <div style={{marginTop: 30}} className={cn('table-price')}>
            <TablePrices
                addresses={addresses}
                selectStation={selectStation}
                />
        </div>
        
    </>
  )
})
