import { Model } from "~/api/records"
import { ModelSto } from "~/api/sto"

type DayHour = {
    hourStart: number,
    minuteStart: number,
    hourFinish: number,
    minuteFinish: number,
}

export const getCountSources = (data: Model[]) => {

    let result: {
        id: number,
        name: string,
        value: number,
    }[] = []

    data.map(i => {
        const index = result.findIndex((_i) => _i.id === i.agentId)
        if (index !== -1){
            result[index].value += 1
        } else {
            result.push({
                id: i.agentId,
                name: i.agentName,
                value: 1
            })
        }
    })
    return result
}

const calcDayMinutes = (dayHour: DayHour) => {
    return (((dayHour.hourFinish - dayHour.hourStart) * 60) + dayHour.minuteFinish - dayHour.minuteStart)
}

const getTotalMinutes = (stoData: ModelSto, date: Date, days: number) => {
    const weekDays = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ]
    const stoDataAny: any = stoData
    const weekDaysMinutes: number[] = []
    weekDays.map(weekDay => {
        const dayMinutes = calcDayMinutes({
            hourStart: stoDataAny[`${weekDay}HourStart`], 
            minuteStart: stoDataAny[`${weekDay}MinuteStart`], 
            hourFinish: stoDataAny[`${weekDay}HourFinish`], 
            minuteFinish: stoDataAny[`${weekDay}MinuteFinish`], 
        })
        weekDaysMinutes.push(dayMinutes)
    })

    let weekDay = date.getDay()
    let totalMinutes = 0
    for(let i=0; i<days; i++){
        totalMinutes += weekDaysMinutes[weekDay]
        if(weekDay == 6){
            weekDay = 0
        } else {
            weekDay++
        }
    }

    return totalMinutes
}

export const getRatioWorkTime = (data: Model[], stoData: ModelSto, date: Date, days: number) => {
    const fullTime = getTotalMinutes(stoData ,date ,days)

    let busyTime = 0

    data.map(_i => {        
        busyTime += (new Date(_i.dateReceiptFinish).getTime() - new Date(_i.dateReceiptStart).getTime()) / (1000 * 60)
    })
    

    return [
        {
            id: 1,
            name: 'Свободное время',
            value: fullTime - busyTime,
            color: '#96ca5b',
        },
        {
            id: 2,
            name: 'Занятое время',
            color: '#f4564a',
            value: busyTime
        },
    ]

}
    
export const getPassedByAutoType = (data: Model[]) => {

    let result: {
        id: number,
        name: string,
        value: number,
    }[] = []

    data.map(i => {
        const index = result.findIndex((_i) => _i.id === i.dkAutoTypeId)
        if (index !== -1){
            result[index].value += 1
        } else {
            result.push({
                id: i.dkAutoTypeId,
                name: i.dkAutoTypeName,
                value: 1
            })
        }
    })
    return result
}

export const getPassedForToday = (data: Model[]) => {

    let result: {
        id: number,
        name: string,
        value: number,
        color: string
    }[] = [
        {
            id: 1,
            name: 'На утверждении',
            value: 0,
            color: '#ffe34e'
        },
        {
            id: 2,
            name: 'Завершены',
            value: 0,
            color: '#96ca5b'
        },
    ]

    if (data.length !== 0){
        data.map(i => {
            if (i.approvedStatusId === 1){
                result[0].value += 1
            } else {
                result[1].value += 1
            }        
        })
        
    } else {
        result = []
    }



    return result
}

// export const getPassedByAutoTypeChart = (data: Model[], date: Date, days: number, period: number) => {

//     const months = ["January", "February", "March", "April", "May", "June",
//                     "July", "August", "September", "October", "November", "December"];

//     let countDate = new Date(date.getTime())
//     let endDate = new Date(date.getTime() + (days *24*60*60*1000))



//     const result: any[] = []
//     const resultTypes: string[] = []

//     if (endDate.getMonth() || endDate.getMonth() == 0){
        
//         let isEnd = false
//         if(period === 1){
//             const end = endDate.getMonth() === 11 ? 11 : endDate.getMonth() + 1
//             isEnd = countDate.getMonth() !== end ||
//                         countDate.getFullYear() !== endDate.getFullYear()
//         } else {
//             isEnd = countDate.getFullYear() !== endDate.getFullYear()+1
//         }

//         while(isEnd){

//             let periodId = ''
//             let resultPeriod = ''

//             if(period === 1){
//                 periodId = `${countDate.getFullYear()}-${countDate.getMonth()}`
//                 resultPeriod = months[countDate.getMonth()]+" "+ countDate.getFullYear()
//                 countDate.setMonth(countDate.getMonth()+1)
//             } else {
//                 periodId = `${countDate.getFullYear()}`
//                 resultPeriod = `${countDate.getFullYear()}`
//                 countDate.setFullYear(countDate.getFullYear() + 1)
//             }

//             result.push({
//                 periodId: periodId ,
//                 period: resultPeriod
//             })

//             if(period === 1){
//                 const end = endDate.getMonth() === 11 ? 11 : endDate.getMonth() + 1
//                 console.log(end)
//                 isEnd = countDate.getMonth() !== end ||
//                             countDate.getFullYear() !== endDate.getFullYear()
//             } else {
//                 isEnd = countDate.getFullYear() !== endDate.getFullYear()+1
//             }
//             console.log('hello')
//         }   


//         data.map(i => {
//             const recordStartDate = new Date(i.dateReceiptStart)
//             let datePeriodId = ''
//             if(period === 1){
//                 datePeriodId = `${recordStartDate.getFullYear()}-${recordStartDate.getMonth()}`
//             } else {
//                 datePeriodId = `${recordStartDate.getFullYear()}`
//             }
            
//             const index = result.findIndex((_i) => _i.periodId === datePeriodId)
            
//             if (index != -1){
//                 if( `type${i.dkAutoTypeName}` in result[index]){
//                     result[index][`type${i.dkAutoTypeName}`] += 1
//                 } else {
//                     result[index][`type${i.dkAutoTypeName}`] = 1
//                     if(!resultTypes.includes(i.dkAutoTypeName)){
//                         resultTypes.push(i.dkAutoTypeName)
//                     }
//                 }
//             } 
//             console.log('hello')
//         })
//     }


    
//     console.log(result)

    
//     return [result, resultTypes]
// }


export const getProfitByPeriod = (data: Model[], date: Date, days: number, period: number) => {

    const months = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];

    let countDate = new Date(date.getTime())
    let endDate = new Date(date.getTime() + (days *24*60*60*1000))



    let result: any[] = []

    if (endDate.getMonth() || endDate.getMonth() == 0){
        
        let isEnd = false
        if(period === 1){
            const endMonth = endDate.getMonth() === 11 ? 0 : endDate.getMonth() + 1
            const endYear = endDate.getMonth() === 11 ? endDate.getFullYear() + 1 : endDate.getFullYear()
            
            isEnd = countDate.getMonth() !== endMonth ||
                        countDate.getFullYear() !== endYear
        } else {
            isEnd = countDate.getFullYear() !== endDate.getFullYear()+1
        }

        while(isEnd){

            let periodId = ''
            let resultPeriod = ''

            if(period === 1){
                periodId = `${countDate.getFullYear()}-${countDate.getMonth()}`
                resultPeriod = months[countDate.getMonth()]+" "+ countDate.getFullYear()
                countDate.setMonth(countDate.getMonth()+1)
            } else {
                periodId = `${countDate.getFullYear()}`
                resultPeriod = `${countDate.getFullYear()}`
                countDate.setFullYear(countDate.getFullYear() + 1)
            }

            result.push({
                periodId: periodId ,
                period: resultPeriod,
                profit: 0
            })

            if(period === 1){
                const endMonth = endDate.getMonth() === 11 ? 0 : endDate.getMonth() + 1
                const endYear = endDate.getMonth() === 11 ? endDate.getFullYear() + 1 : endDate.getFullYear()
            
                isEnd = countDate.getMonth() !== endMonth ||
                                countDate.getFullYear() !== endYear
            } else {
                isEnd = countDate.getFullYear() !== endDate.getFullYear()+1
            }
        }   


        data.map(i => {
            const recordStartDate = new Date(i.dateReceiptStart)
            let datePeriodId = ''
            if(period === 1){
                datePeriodId = `${recordStartDate.getFullYear()}-${recordStartDate.getMonth()}`
            } else {
                datePeriodId = `${recordStartDate.getFullYear()}`
            }
            
            const index = result.findIndex((_i) => _i.periodId === datePeriodId)
            
            if (index != -1){
                result[index].profit += i.priceSto
            }
        })
    }


    
    return result
}

export const getPassedByStatus = (data: Model[]) => {

    let result: {
        id: number,
        name: string,
        value: number,
        color: string,
    }[] = []

    data.map(i => {
        const index = result.findIndex((_i) => _i.id === i.approvedStatusId)
        if (index !== -1){
            result[index].value += 1
        } else {
            result.push({
                id: i.approvedStatusId,
                name: i.approvedStatusName,
                color: i.approvedStatusColor,
                value: 1
            })
        }
    })
    return result
    
}

export const getPassedBySto = (data: Model[]) => {

    let result: any[] = []
    const resultTypes: {
        valueField: string,
        seriesName: string,
    }[] = []

    const colors: any= {}

    data.map(i => {
        colors[`status${i.approvedStatusId}`] = i.approvedStatusColor
        const index = result.findIndex((_i) => _i.id === i.stoId)
        if (index !== -1){
            if( `status${i.approvedStatusId}` in result[index]){
                result[index][`status${i.approvedStatusId}`] += 1
            } else {
                result[index][`status${i.approvedStatusId}`] = 1
                if(!resultTypes.some(_i => _i.valueField === `status${i.approvedStatusId}`)){
                    resultTypes.push({
                       valueField: `status${i.approvedStatusId}`,
                       seriesName: i.approvedStatusName
                    })
                }
            }
        } else {
            result.push({
                id: i.stoId,
                name: i.stoNameShort,
                [`status${i.approvedStatusId}`]: 1,
            })
            if(!resultTypes.some(_i => _i.valueField === `status${i.approvedStatusId}`)){
                resultTypes.push({
                   valueField: `status${i.approvedStatusId}`,
                   seriesName: i.approvedStatusName
                })
            }
        }
    })
    return [result, resultTypes, colors]
    
}




// export const getPassedBySto = (data: Model[]) => {

//     let result: any[] = []
//     const resultTypes: {
//         valueField: string,
//         seriesName: string,
//     }[] = []


//     data.map(i => {
//         const index = result.findIndex((_i) => _i.id === i.stoId)
//         if (index !== -1){
//             if( `type${i.dkAutoTypeName}` in result[index]){
//                 result[index][`type${i.dkAutoTypeName}`] += 1
//             } else {
//                 result[index][`type${i.dkAutoTypeName}`] = 1
//                 if(!resultTypes.some(_i => _i.valueField === `type${i.dkAutoTypeName}`)){
//                     resultTypes.push({
//                        valueField: `type${i.dkAutoTypeName}`,
//                        seriesName: i.dkAutoTypeName
//                     })
//                 }
//             }
//         } else {
//             result.push({
//                 id: i.stoId,
//                 name: i.stoName,
//                 [`type${i.dkAutoTypeName}`]: 1,
//             })
//             resultTypes.push({
//                 valueField: `type${i.dkAutoTypeName}`,
//                 seriesName: i.dkAutoTypeName
//              })
//         }
//     })

//     console.log(resultTypes)
//     return [result, resultTypes]
    
// }