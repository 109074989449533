import { useCallback, useContext, useMemo } from "react"
import { AuthContext } from "~/context";


export const useWeekHours = () => {
    const { authState } = useContext(AuthContext);

    const sto = authState.userData?.sto

    const startDayWeekHours = useMemo(() => {
        return [
            sto.sundayHourStart,
            sto.mondayHourStart,
            sto.tuesdayHourStart,
            sto.wednesdayHourStart,
            sto.thursdayHourStart,
            sto.fridayHourStart,
            sto.saturdayHourStart,
        ]
    }, [])

    const endDayWeekHours = useMemo(() => {
        return [
            sto.sundayHourFinish,
            sto.mondayHourFinish,
            sto.tuesdayHourFinish,
            sto.wednesdayHourFinish,
            sto.thursdayHourFinish,
            sto.fridayHourFinish,
            sto.saturdayHourFinish,
        ]
    }, [])

    const startDayWeekMinutes = useMemo(() => {
        return [
            sto.sundayMinuteStart,
            sto.mondayMinuteStart,
            sto.tuesdayMinuteStart,
            sto.wednesdayMinuteStart,
            sto.thursdayMinuteStart,
            sto.fridayMinuteStart,
            sto.saturdayMinuteStart,
        ]
    }, [])

    const endDayWeekMinutes = useMemo(() => {
        return [
            sto.sundayMinuteFinish,
            sto.mondayMinuteFinish,
            sto.tuesdayMinuteFinish,
            sto.wednesdayMinuteFinish,
            sto.thursdayMinuteFinish,
            sto.fridayMinuteFinish,
            sto.saturdayMinuteFinish,
        ]
    }, [])

    const getWorkHours = useCallback((date: Date) => {
        const weekDay = date.getDay()
        return [startDayWeekHours[weekDay] || 9, endDayWeekHours[weekDay] || 22]
    }, [startDayWeekHours, endDayWeekHours])

    const getWorkMinutes = useCallback((date: Date) => {
        const weekDay = date.getDay()
        return [startDayWeekMinutes[weekDay] || 0, endDayWeekMinutes[weekDay] || 0]
    }, [startDayWeekMinutes, endDayWeekMinutes])

    const getWorkTime = useCallback((date: Date) => {
        const hours = getWorkHours(date)
        const minutes = getWorkMinutes(date)
        
        return [new Date(1970, 1, 1, hours[0], minutes[0]), new Date(1970, 1, 1, hours[1], minutes[0])]
    }, [startDayWeekHours, endDayWeekHours])

    const checkIsWorkingDay = useCallback((date: Date) => {
        const weekDay = date.getDay()
        return Boolean(startDayWeekHours[weekDay])
    },[startDayWeekHours, endDayWeekHours])

    const checkIsWorkingTime = useCallback((startDate: Date, endDate: Date) => {
        if(!checkIsWorkingDay(startDate)){
            return false
        }

        const weekDay = startDate.getDay()
        
        const startWorkTime = new Date(startDate.getTime())
        startWorkTime.setHours(startDayWeekHours[weekDay])
        startWorkTime.setMinutes(startDayWeekMinutes[weekDay])
        
        const endWorkTime = new Date(endDate.getTime())
        endWorkTime.setHours(endDayWeekHours[weekDay])
        endWorkTime.setMinutes(endDayWeekMinutes[weekDay])

        return startWorkTime <= startDate && endWorkTime >= endDate
    },[startDayWeekHours, endDayWeekHours, startDayWeekMinutes, endDayWeekMinutes])
    
    const getMaxHour = useCallback(() => {
        return Math.max(...endDayWeekHours)
    }, [endDayWeekHours])

    const getMinHour = useCallback(() => {
        let min = 24
        startDayWeekHours.forEach(hour => {
            if(min > hour && hour != null){
                min = hour
            }
        })
        return min
    }, [startDayWeekHours])

    return { getWorkHours, checkIsWorkingDay, checkIsWorkingTime, getMaxHour, getMinHour, getWorkMinutes, getWorkTime }
}