import React, { useEffect, useRef, useState, memo, useContext } from "react";
import { YMaps, Map, ZoomControl, Placemark, YMapsApi, Clusterer } from "react-yandex-maps";
import { ModelSto } from '~/api/sto'



import './styles.scss'
import { Common } from "~/types";
import { AuthContext } from "~/context";

type Props = {
    data: ModelSto,
}

type PropsComponent = {
    setMarkerFixed: (value: boolean) => void,
    markerFixed: boolean,
    data: ModelSto,
}

const MapComponent: React.FC<PropsComponent> = memo(({setMarkerFixed, data, markerFixed}) => {

    const [ mapRef, setMapRef] = useState<any>(null)
    const [ marker, setMarker ] = useState<any>(null)
    const { authState: {
        userData
        }} = useContext(AuthContext);  

    const isStoDirector = userData?.roles.includes(Common.SubPermissions.ROLE_STO_DIRECTOR)
    const isAdmin = userData?.roles.includes(Common.SubPermissions.ROLE_ADMIN)

    const stoId = userData?.sto ? userData?.sto.id : false
    

    const isDirectorOrAdmin = ((isStoDirector || isAdmin) && stoId && stoId === data.id)

    
    return (
        <YMaps>
            <div style={{height: '100%'}} >
                <Map 
                    height={'400px'}
                    width="100%"
                    instanceRef={(ref: any) => {
                        if(ref && !mapRef){
                            setMapRef(ref)
                        }
                        if(ref && marker && isDirectorOrAdmin){
                            ref.events.add('click', function (e: any) {
                                var coords = e.get('coords');
                                marker.geometry.setCoordinates(coords)
                                data.lat = coords[0]
                                data.lon = coords[1]
                                setMarkerFixed(true)
                            });
                        }
                    }}
                    defaultState={{ 
                        center: (data.lat&&data.lon) ? [data.lat, data.lon]: [59.933, 30.3154], 
                        zoom: 9 ,
                    }} 
                    >

                        <Placemark 
                            instanceRef={(ref: any) => {
                                if(ref && !marker){
                                    setMarker(ref)
                                }

                                if(ref && isDirectorOrAdmin){
                                    ref.events.add('dragend', function (e: any) {
                                        if (marker){
                                            const coords = marker.geometry.getCoordinates()
                                            data.lat = coords[0]
                                            data.lon = coords[1]
                                        }

                                    });
                                }
                            }}
                            geometry={(data.lat&&data.lon) ? [data.lat, data.lon]: []} 
                            options={{
                                visible: markerFixed,
                                preset: 'islands#blueDotIcon',
                                draggable: isDirectorOrAdmin && 1,
                            }}
                            />
                            

                    <ZoomControl options={{float:'left'}}/>

                </Map>
                
            </div>  
        </YMaps>
    )
    
})




export const StationLocationMap: React.FC<Props> = ({data}) => {
    
    const [ markerFixed, setMarkerFixed ] = useState<boolean>(false)

    useEffect(() => {
        if (data.lat&&data.lon){
            setMarkerFixed(true)
        }
    }, [data])

    return (
        <div className={!markerFixed ? 'marker-fixed' : ''}>
            <MapComponent setMarkerFixed={setMarkerFixed} markerFixed={markerFixed} data={data}/>
        </div>
    )
}