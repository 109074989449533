import React, { memo, useEffect, useMemo, useState } from "react";
import { AutoType, Model } from "~/api/autotype";
import { Tooltip } from 'devextreme-react/tooltip';
import { useTranslation } from "react-i18next";

type Props = {
  dataField: string, 
  autoTypes?: Model[]
}

export const LabelAutoTypeTemplate: React.FC<Props> = memo(({dataField, autoTypes}) =>{
    
    const { t } = useTranslation('sto');
    const [defaultAutoTypes, setDefaultAutoTypes] = useState<Model[]>([])

    const getAutoTypes = async () => {
        let result = await AutoType.controller.getAll()
        setDefaultAutoTypes(result)
    }

    useEffect(() => {
      if(!autoTypes){
        getAutoTypes()
      }
    }, [])

    const array = useMemo(() => {
      if(autoTypes){
        return autoTypes
      }
      return defaultAutoTypes
    }, [autoTypes, defaultAutoTypes])

    return (
      <span>
        <span>
            {t(dataField)}
        </span>
        <span 
            style={{paddingLeft: '20px',position: 'absolute'}}
            id={`${dataField}`}
            >
            <i className="fa fa-question" aria-hidden="true"></i>
        </span>
  
        <Tooltip
            target={`#${dataField}`}
            showEvent="mouseenter"
            hideEvent="mouseleave"
            position="right"
            width={500}      
        >
        {array.map((data) => (
          <p key={data.id} style={{wordWrap:'break-word', whiteSpace: 'normal' }}>{data.name}:    {data.description}</p>)
        )}
        
      </Tooltip>
      </span>
    );
  })
