import { Common } from '~/types';
import { MainStations } from './MainStations';


export const ROUTES = [
  {
    Component: MainStations,
    permissionComponent: Common.Permissions.ROLE_AGENT,
    exact: true,
    path: '/app/stations',
  },
];
