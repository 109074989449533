import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Common } from '../types';
import { AuthContext, PopupContext } from '~/context';
import { useHistory } from 'react-router';
import { checkPermission } from '~/utils/checkPermission';

export const useNavGroups = (): {
  isNavGroupsLoaded: boolean;
  navLinks: Common.NavGroupLink[];
} => {
  const { t, ready: i18nLoaded } = useTranslation('nav-groups', {
    useSuspense: false,
  });

  const { 
    addPopupOpen,
    } = useContext(PopupContext);
  const { authState: {
    userData
  }
   } = useContext(AuthContext);

  const history = useHistory();

  const navLinks = useMemo(() => {
    if (!i18nLoaded) {
      return [];
    }
    return [
      {
        label: t('Records'),
        to: '/app/records',
        addButton: checkPermission(userData?.roles,Common.Permissions.ROLE_AGENT),
        onClickAddButton: () => {
          history.push('/app/stations')
        },
        icon: 'fas fa-list',
        permissions: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_OPERATOR, Common.Permissions.ROLE_STO_ADMINISTRATOR]
      },
      {
        label: t('Planer'),
        to: '/app/planer',
        icon: 'fas fa-clock',
        addButton: checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR),
        onClickAddButton: () => {
          addPopupOpen()
        },
        permissions: [Common.Permissions.ROLE_STO_ADMINISTRATOR, Common.Permissions.ROLE_STO_OPERATOR]
      },
      {
        label: t('Stations'),
        to: '/app/stations',
        icon: 'fas fa-warehouse',
        permissions: [Common.Permissions.ROLE_AGENT]
      },
      {
        label: "Акты",
        permissions: [Common.SubPermissions.ROLE_ADMIN, Common.SubPermissions.ROLE_BROKER_DIRECTOR, Common.SubPermissions.ROLE_STO_DIRECTOR, Common.Permissions.ROLE_STO_ADMINISTRATOR],
        addButton: checkPermission(userData?.roles, [Common.SubPermissions.ROLE_ADMIN, Common.Permissions.ROLE_STO_ADMINISTRATOR]),
        onClickAddButton: () => {
          history.push('/app/acts/create')
        },
        items: [
          {
            id: 1,
            label: "Все",
            icon: 'fas fa-bars',
            to: '/app/acts/all'
          },
          {
            id: 2,
            label: "На согласовании",
            icon: 'fas fa-spinner',
            to: '/app/acts/for-approval'
          },
          {
            id: 3,
            label: "Согласованные",
            icon: 'far fa-check-circle',
            to: '/app/acts/approved'
          },
          {
            id: 4,
            label: "Оплаченные",
            icon: 'fas fa-money-bill',
            to: '/app/acts/paid'
          },
        ]
      },
      {
        label: t('Statistics'),
        to: '/app/statistics',
        icon: 'fas fa-chart-pie',
        permissions: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_ADMINISTRATOR]
      },
      {
        label: t('Reports'),
        permissions: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_ADMINISTRATOR],
        items: [
          {
            id: 1,
            label: "Общий отчет",
            icon: 'fas fa-table',
            permissions: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_ADMINISTRATOR],
            to: '/app/report/common'
          },
          {
            id: 2,
            label: "Финансовый отчет",
            icon: 'fas fa-coins',
            permissions: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_ADMINISTRATOR],
            to: '/app/report/financial'
          },
          {
            id: 3,
            label: "Сверочный отчет",
            icon: 'fas fa-crown',
            permissions: [Common.SubPermissions.ROLE_ADMIN],
            to: '/app/report/admin'
          },
        ]
      },
    ];
  }, [t, i18nLoaded]);

  return useMemo(
    () => ({
      isNavGroupsLoaded: i18nLoaded,
      navLinks,
    }),
    [i18nLoaded, navLinks]
  );
};
