import { bemHelper } from "~/libs";
import React, { useMemo } from "react";
import './style.scss'
import { classMerge } from "~/libs/utils";
import { useWeekHours } from "../../hooks";

type Props = {
    className: string
    data: {
        startDate: Date;
        endDate: Date;
    }
}

const cn = bemHelper('DataCell');

export const DataCell: React.FC<Props> = ({className, children, data}) => {
    const {checkIsWorkingTime}= useWeekHours()

    const isDisabled = useMemo(() => {
        return !checkIsWorkingTime(data.startDate, data.endDate)
    }, [data])


    const cssClasses = classMerge(
        cn(),
        cn('disabled')
    ) 
  
    return (
      <div 
        className={isDisabled ? cssClasses : undefined}
        >
        {children}
      </div>
    );
};
