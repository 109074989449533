import Main from "./Main";
import Page from "./Page"

export const ROUTES = [

  {
    Component: Main,
    exact: true,
    path: '/app/instructions',
    permissionComponent: 'All',
  },
  {
    Component: Page,
    exact: true,
    path: '/app/instructions/:instructionName',
    permissionComponent: 'All',
  },
];
