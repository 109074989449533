import React, { memo, useContext, useEffect, useState } from 'react'
import { bemHelper } from '~/libs';
import { useParams } from 'react-router-dom';

import Form, { Item, ButtonItem, GroupItem, Label } from 'devextreme-react/form';

import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify'
import { AutoType } from '~/api';
import SelectBox from 'devextreme-react/select-box';
import { Model, ModelEngineTypes, Vehicle } from '~/api/vehicle';
import { ComponentProps } from '~/ui/atoms/PopupEdit';
import { colSpanItem } from '../service';
import { useWindowSize } from '~/hooks/useWindowSize';
import { SelectBoxAutoTypeItem } from '../../SelectBoxAutoTypeItem';
import { LabelAutoTypeTemplate } from '../../LabelAutoTypeTemplate';
import { checkPermission } from '~/utils/checkPermission';
import { AuthContext } from '~/context';
import { Common } from '~/types';
import { ToolbarButton } from '../../CustomPopup';

const cn = bemHelper('add-record-popup');

export const EditVehicleComponent: React.FC<ComponentProps> = memo(({
    handleClose, 
    updateData, 
    fromReport=false, 
    manageRef,
    id, 
    activeRecord
}) => {

    const { urlId } = useParams<any>()
    const size = useWindowSize()

    const [data, setData] = useState<Model | any>({})
    const [models, setModels] = useState<any>([])
    const [brands, setBrands] = useState<any>([])
    const [ isDisabled, setIsDisabled ] = useState<boolean>(true)

    const [autoTypes, setAutoTypes] = useState<any>([])
    const [enginesTypes, setEnginesTypes] = useState<ModelEngineTypes[] | null>(null)
    const { authState: {
        userData
        }} = useContext(AuthContext);   

    const [form, setForm] = useState<any>()
    const [ modelsDisabled, setModelsDisabled ] = useState(false)

    const [ other, setOther ] = useState(false)

    const readonly = checkPermission(userData?.roles, Common.Permissions.ROLE_STO_OPERATOR) || (activeRecord ? false : fromReport)


    const colCountByScreen = fromReport ? {
        lg: 4,
        md: 4,
        sm: 4,
        xs: 1,
    } : {
        lg: 4,
        md: 3,
        sm: 3,
        xs: 1,
    }

    const EditorOptions = {

        other:{
            text:"Иное",
            iconSize: 15,
            onValueChanged: (e: any) => {
                setOther(e.value)
            },
            visible: !readonly,
            value: other,
        },
        brand: {
            with: '100%',
            readOnly: readonly,
            dataSource: brands,
            searchEnabled: true,
            acceptCustomValue: true,
            value: data.brand,
            onValueChanged: (e:any) => {
                if(form){
                    getModels(e.value)
                    form.updateData('model', null)
                }
                
            }
        },
        model: {
            with: '100%',
            dataSource: models,
            readOnly: readonly,
            searchEnabled: true,
            acceptCustomValue: true
        },
        regNumber: {
            with: '100%',
            readOnly: readonly,
            disabled: data.noRegNumber,
            minLength: 8,
            maxLength: 9,
            mask: 'XXXXXXXXK',
            maskRules: {
                X: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9]/,
                K: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9\s]/
            },
            maskChar: ' ',
            placeholder: 'А123МН78',
            useMaskedValue: true,
            showMaskMode: 'onFocus',
            maskInvalidMessage: "8 или 9 символов. Можно вводить только русские буквы (А,В,Е,К,М,Н,О,Р,С,Т,У,X) и цифры.",
        },
        noRegNumber:{
            text:"без номера",
            iconSize: 13,

            onValueChanged: (e: any) => {
                const editor = form.getEditor('regNumber')
                editor.option('disabled', e.value)
                handleDataChange()
            },
        },
        dkAutoType: {
            with: '100%',
            displayExpr: 'name',
            valueExpr: 'id',
            readOnly: readonly,
            dataSource: autoTypes,
            value: data.dkAutoTypeId,
            itemRender: SelectBoxAutoTypeItem,
            dropDownOptions: {
                wrapperAttr :{
                    class: 'select-auto-type'
                }
            },
            onValueChanged: (e: any) => {
                setData({
                    ...data,
                    dkAutoTypeId: e.value
                })
                setTimeout(()=>{ 
                    handleDataChange()
                },200)
            },
        },
        vin: {
            with: '100%',
            mask: 'XXXXXXXXXXXXXXXXX', 
            maskChar: '_',
            maskRules: { X: /[0-9A-Za-z]/ },
            valueChangeEvent: 'input',
            onInput: (e:any) => {
                const string: string = e.component.option('value')
                e.component.option('value', string.toLocaleUpperCase())  
            },
            readOnly: readonly,
        },
        enginesType: {
            with: '100%',
            readOnly: readonly,
            dataSource: enginesTypes,
            displayExpr: 'name',
            valueExpr: 'id',
        },
        comment: {
            with: '100%',
            readOnly: readonly,
        }
    }

    const { t } = useTranslation('vehicle');

    const getData = async () => {

        try{
            const result = await Vehicle.controller.getById(fromReport ? id : urlId)
            const newResult = {
                ...result,
                noRegNumber: !result.regNumber 
            }
            setData(newResult)
        } catch(err:any){
            manageRef?.current?.setError(err.message)
        }
    
        manageRef?.current?.setIsLoading(false)
    }

    const getModels = async (brand: string) => {
        const result = await Vehicle.controller.getModelsByBrand(brand);
        setModels(result)
    }
    const getBrands = async () => {
        const result = await Vehicle.controller.getAllBrands();
        setBrands(result)
    }
    const getAutoTypes= async () => {
        const result = await AutoType.controller.getAll()
        const newResult = result
        setAutoTypes(newResult)
    }

    const getEnginesTypes = async () => {
        const result = await Vehicle.controller.getEnginesTypes();
        setEnginesTypes(result)
    }


    const handleSubmit = async () => {

        try{
            const values = {
                ...data
            }
            if(other){
                await Vehicle.controller.createBrandAndModel({
                    brand: values.brand,
                    model: values.model
                })
            }
            if(values.noRegNumber){
                values.regNumber = ''
            }

            delete values.noRegNumber

            await Vehicle.controller.update(values)

            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
            }, 'success', 3000);

            if(updateData){
                updateData()
            }

            handleClose()

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
            }, 'error', 3000);
        } 
    }

    const handleDataChange = () => {
        if(form){
            const button = form.getButton('submit')

            if (button){
                if(form.validate().isValid ){
                    setIsDisabled(false)
                } else{
                    setIsDisabled(true)
                }
            }
        }
    }

    useEffect(() => {
        if(form){
            if (id || urlId){
                getData()
            }
            getBrands()
            getAutoTypes()
            getEnginesTypes()
        }
    }, [form])

    useEffect(() => {
        if (data.brand){
            getModels(data.brand)
        }
    }, [data, id, urlId])

    

    useEffect(() => {
        if (form){
            manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`, false)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.options.onClick`, handleSubmit)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, !readonly)
            manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, readonly)
        }
    }, [data, form])

    return (
            <form>
                <Form
                    onContentReady={e => e.component.validate()}
                    onInitialized={(e:any ) => {setForm(e.component)}}
                    formData={data}
                    onFieldDataChanged={(e:any) =>handleDataChange()}
                    id="form">
                    <GroupItem colCountByScreen={colCountByScreen}>
                        { !other && (
                            <Item 
                                dataField="brand" 
                                colSpan={1}
                                label={{text: t('brand')}} 
                                editorOptions={EditorOptions.brand} 
                                editorType="dxSelectBox"

                                isRequired
                                />
                            )}
                        { !other && (
                            <Item
                                dataField="model" 
                                disabled={modelsDisabled || other} 
                                label={{text: t('model')}} 
                                editorOptions={EditorOptions.model} 
                                editorType="dxSelectBox"
                                isRequired
                            />
                        )}
                        { other && (
                            <Item 
                                dataField="brand" 
                                label={{text: t('brand')}} 
                                isRequired
                                />
                        )}
                        { other && (
                            <Item
                                dataField="model" 
                                label={{text: t('model')}} 

                                isRequired
                            />
                        )}
                        { !readonly && (
                            <Item 
                                dataField="other" 
                                colSpan={1}
                                cssClass={cn('checkbox-other')}
                                label={{visible: false}} 
                                editorOptions={EditorOptions.other} 
                                editorType="dxCheckBox"
                                />  
                        )}
                        <GroupItem
                                cssClass={cn('group-regNumber')}
                                colCount={1}
                                colSpan={1}
                                >
                                <Item
                                    dataField="noRegNumber" 
                                    label={{visible: false}} 
                                    visible={!readonly}
                                    editorType="dxCheckBox"
                                    cssClass={cn('no-regNumber')}
                                    editorOptions={EditorOptions.noRegNumber}
                                />
                                <Item
                                    dataField="regNumber" 
                                    label={{text: t('regNumber')}} 
                                    editorOptions={EditorOptions.regNumber}
                                    isRequired
                                />
                            </GroupItem>
                        <Item 
                            dataField="dkAutoTypeId" 
                            isRequired
                            >
                            <Label render={() => <LabelAutoTypeTemplate dataField={'dkAutoType'} autoTypes={autoTypes}/>}/>
                            <SelectBox
                                {...EditorOptions.dkAutoType}
                                />
                        </Item>
                        <Item 
                            dataField="vin" 
                            label={{text: t('vin')}} 
                            editorOptions={EditorOptions.vin} 
                            />
                        <Item 
                            dataField="enginesTypeId" 
                            label={{text: t('enginesType')}} 
                            editorOptions={EditorOptions.enginesType} 
                            editorType="dxSelectBox"
                            />
                        <Item 
                            dataField="comment" 
                            label={{text: t('comment')}} 
                            colSpan={colSpanItem(size, colCountByScreen)} 
                            editorOptions={EditorOptions.comment}
                            />
                    </GroupItem>            
                    
                    { !fromReport && (
                        <ButtonItem
                            colSpan={colSpanItem(size, colCountByScreen)}
                            visible={!readonly}
                            name='submit'
                            buttonOptions={{
                                type: 'default',
                                text: 'Сохранить',
                                disabled: isDisabled,
                                onClick: () => handleSubmit(),
                            }}
                            />
                    )}
                </Form>
            </form>
    )
})
