import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { bemHelper } from '~/libs';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Form, { Item, ButtonItem, GroupItem, Label } from 'devextreme-react/form';
import Accordion from 'devextreme-react/accordion';
import { Button } from 'devextreme-react/ui/button';

import './styles.scss'
import { ComponentProps } from '~/ui/atoms/PopupEdit';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { ModelById, Records } from '~/api/records';
import EditBase from '~/ui/atoms/EditBase';
import { useIsMobile } from '~/hooks/useIsMobile';
import { AuthContext, PopupContext } from '~/context';
import { Common } from '~/types';
import { mileageAdorment, priceAdorment} from '~/routes/Records/services';
import { useWindowSize } from '~/hooks/useWindowSize';
import { colSpanItem } from '../service';
import { LabelAutoTypeTemplate } from '../../LabelAutoTypeTemplate';
import ItemAccordionHistory from '../../ItemAccordionHistory';
import { checkPermission } from '~/utils/checkPermission';
import { handleDownload } from '~/utils/downloadDk';
import { ChangeDatePopup, PopupChangeDataRef } from './atoms/ChangeDatePopup';
import { stoAllDataSource, tireBrandsDataSource } from '~/custom-data-sources';
import { ToolbarButton } from '../../CustomPopup';

type ParamsProps = {
    urlId: string
}

const getPriorities = (tsLimit: number) => {
    const priorities = []
    for (let i = 1; i <= tsLimit; i++) {
        priorities.push({
            id: i,
            title: `Линия ${i}`,
          })
    }

    return priorities
  }

export const EditRecordComponent: React.FC<ComponentProps> = memo(({
    id, 
    handleClose, 
    updateData, 
    manageRef,
    fromReport = false
}) => {
    const history = useHistory();
    const isMobile = useIsMobile()
    const size = useWindowSize()
    const { urlId } = useParams<ParamsProps>()

    const location = useLocation()
    const { authState: {
        userData
        }} = useContext(AuthContext);   
    const { 
        popupState: {
            editPopup: {
                activeRecord
            }
        },
        offerPopupOpen,
        offerPopupClose,
        offerPopupAgree
        } = useContext(PopupContext);
    const cn = bemHelper('edit-record');
    const popupChangeDataRef = useRef<PopupChangeDataRef>(null)
    
    const [data, setData] = useState<ModelById | any>({})
    const agentNameCssClass = useRef<string | null>(null)
    const [serviceVar, setServiceVar] = useState<any>({
        isStoAgent: true,
        readonly: true,
        onlyEditTimeAndAddress: false,
        isClosed: true,
        deleteButtonVisible: true,
        first: true,
        form: null,
    })

    const colCountByScreen = fromReport ? {
        lg: 2,
        md: 2,
        sm: 2,
        xs: 1,
    } : {
        lg: 5,
        md: 4,
        sm: 3,
        xs: 1,
    }

    const colCountAditionalByScreen = fromReport ? userData?.roles.includes(Common.Permissions.ROLE_AGENT) ? {
        lg: 4,
        md: 4,
        sm: 4,
        xs: 1,
    } : {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    } : {
        lg: 5,
        md: 4,
        sm: 3,
        xs: 1,
    }

    const colGroupFirst = {
        lg: 4,
        md: 4,
        sm: 4,
        xs: 1,
    }

    const colCountClientGroup = fromReport ? {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    } : {
        lg: 3,
        md: 3,
        sm: 3,
        xs: 1,
    }

    const colCountAutoGroup = fromReport ? {
        lg: 4,
        md: 4,
        sm: 4,
        xs: 1,
    } : {
        lg: 5,
        md: 4,
        sm: 3,
        xs: 1,
    }

    const colCountComment = fromReport ? 2 : {
        lg: 3,
        md: 2,
        sm: 3,
        xs: 1,
    }

    const EditorOptions = useMemo(() => {
        return {

            dateReceiptStart :{
                type: 'datetime',
                width: '100%',
                useMaskBehavior: true,
                readOnly: serviceVar.readonly || (serviceVar.isClosed && !serviceVar.onlyEditTimeAndAddress),
            },
    
            lineNumber: {
                with: '100%',
                dataSource: getPriorities(userData?.sto ? userData?.sto.tsLimit : 4),
                displayExpr: 'title',
                deferRendering: false,
                valueExpr: 'id',
                readOnly: serviceVar.readonly || serviceVar.isClosed,
            },
            
            priceClient:{
                with: '100%',
                readOnly: serviceVar.readonly || serviceVar.isClosed,
                min: 0,
                placeholder: "Стоимость, рубли",
                buttons: [priceAdorment],
            },
            priceSto:{
                with: '100%',
                min: 0,
                readOnly: true,
                placeholder: "Стоимость, рубли",
                buttons: [priceAdorment]
            },
            clientFio:{
                with: '100%',
                readOnly: true,
            },
            brand: {
                with: '100%',
                searchEnabled: true,
                acceptCustomValue: true,
                readOnly: true,
            },
            model: {
                readOnly: true,
                with: '100%',
                searchEnabled: true,
                acceptCustomValue: true,
            },
            dkAutoType: {
                readOnly: true,
                with: '100%',
                displayExpr: 'name',
                valueExpr: 'id',
            },
            email: {
                readOnly: true,
                with: '100%'
            },
            vin: {
                readOnly: true,
                with: '100%',
                mask: 'XXXXXXXXXXXXXXXXX', 
                maskChar: '_',
                maskRules: { X: /[0-9A-Za-z]/ },
                valueChangeEvent: 'input',
                onInput: (e:any) => {
                    const string: string = e.component.option('value')
                    e.component.option('value', string.toLocaleUpperCase())  
                }
            },
            regNumber: {
                readOnly: true,
                minLength: 8,
                maxLength: 9,
                mask: 'XXXXXXXXK',
                maskRules: {
                    X: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9]/,
                    K: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9\s]/
                },
                maskChar: ' ',
                placeholder: 'А123МН78',
                useMaskedValue: true,
                showMaskMode: 'onFocus',
                maskInvalidMessage: "8 или 9 символов. Можно вводить только русские буквы (А,В,Е,К,М,Н,О,Р,С,Т,У,X) и цифры.",
                with: '100%',
            },
            phone: {
                readOnly: true,
                with: '100%',
                maskChar: '_',
                mask: '+0 (X00) 000-00-00', 
                maskRules: { X: /[02-9]/ }
            },
            inn: {
                with: '100%',
                readOnly: true,
            },
            companyName: {
                with: '100%',
                readOnly: true,
            },
            tireBrand: {
                with: '100%',
                displayExpr: 'name',
                dataSource: tireBrandsDataSource,
                valueExpr: 'id',
                readOnly: serviceVar.readonly || serviceVar.isClosed,
                searchEnabled: true,
            },
            stoAddress: {
                with: '100%',
                readOnly: checkPermission(userData?.roles, Common.Permissions.ROLE_AGENT) ? (serviceVar.readonly || (serviceVar.isClosed && !serviceVar.onlyEditTimeAndAddress) ) : true,
                displayExpr: 'address',
                dataSource: stoAllDataSource,
                valueExpr: 'id',
                onValueChanged: (e: any) => {          
                    if(!serviceVar.load){
                        popupChangeDataRef.current?.show({
                            stoId: e.value,
                            dkAutoTypeId: data.dkAutoTypeId,
                            previousStoId: e.previousValue
                        })
                    }
                }
            },
            channel: {
                with: '100%',
                readOnly: true,
            },
            mileage: {
                with: '100%',
                min: 0,
                max: 10000000,
                readOnly: serviceVar.readonly || serviceVar.isClosed,
                placeholder: "Пробег, км",
                buttons: [mileageAdorment]
            },
            comment: {
                readOnly: serviceVar.readonly || serviceVar.isClosed,
                with: '100%',
            },
            buttonAction: {
                text: "Действия",
                useItemTextAsTitle: false,
                
                items: [
                {
                    value: 1,
                    text:'Техосмотр пройден ',
                    visible: !serviceVar.isStoAgent && !serviceVar.isClosed
                },
                {
                    value: 2,
                    text:'Техосмотр не пройден',
                    visible: !serviceVar.isStoAgent && !serviceVar.isClosed
                },
                {
                    value: 3,
                    text:'Клиент прибыл',
                    visible: !serviceVar.isStoAgent && !serviceVar.isClosed
                },
                {
                    value: 5,
                    text:'Клиент не прибыл',
                    visible: !serviceVar.isStoAgent && !serviceVar.isClosed
                },
                {
                    value: 6,
                    text:'Отклонена',
                    visible: !serviceVar.isStoAgent && !serviceVar.isClosed
                },
                {
                    value: 4,
                    text:'Согласована',
                    visible: !serviceVar.isStoAgent && !serviceVar.isClosed && userData?.staff.agentId !== data.agentId && data.approvedStatusId === 1
                },
                {
                    value: 7,
                    text:'Скачать ДК',
                    visible: data.uploadDk
                },
                ],
                width: '100%',
                position: 'right',
                onSelectionChanged: async (e: any) => {
    
                try{
                    switch(e.item.value){
                    case 1:
                        await Records.controller.changeStatus(data.id, 6)
                        setData({
                            ...data,
                            approvedStatusName: `ТО пройден`, 
                            approvedStatusId: 6, 
                            approvedStatusColor: "#63d897" 
                        })
                        break;
                    case 2:
                        await Records.controller.changeStatus(data.id, 7)
                        setData({
                            ...data,
                            approvedStatusName: `ТО не пройден`, 
                            approvedStatusId: 7, 
                            approvedStatusColor: "#ee696e" 
                        })
                        handleOpenOfferPopup('resign')
                        break;
                    case 3:
                        await Records.controller.changeStatus(data.id, 5)
                        setData({
                            ...data,
                            approvedStatusName: `Клиент прибыл на СТО`, 
                            approvedStatusId: 5, 
                            approvedStatusColor: "#b0b0b0" 
                        })
                        break;
                    case 4:
                        await Records.controller.changeStatus(data.id, 2)
                        setData({
                            ...data,
                            approvedStatusName: `Подтверждена`, 
                            approvedStatusId: 2, 
                            approvedStatusColor: "#5ebce7" 
                        })
                        break;
                    case 5:
                        await Records.controller.changeStatus(data.id, 8)
                        setData({
                            ...data,
                            approvedStatusName: `Клиент не прибыл на СТО`, 
                            approvedStatusId: 8, 
                            approvedStatusColor: "#ff4435" 
                        })
                        break;
                    case 6:
                        await Records.controller.changeStatus(data.id, 3)
                        setData({
                            ...data,
                            approvedStatusName: `Отклонена`, 
                            approvedStatusId: 3, 
                            approvedStatusColor: "#ff4435" 
                        }) 
                        break;
                    case 7:
                        handleDownload(data.id)
                        break;
                    }
    
                    if(![3,4].includes(e.item.value)){
                        manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, false)
                        manageRef?.current?.toolbar?.option(`${ToolbarButton.CANCEL}.visible`, true)
                        manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`, false)
                        manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, false)
                    }
    
                    if(updateData && e.item.value != 7){
                        notify({
                            message: 'Запись успешно обновлена',
                            position: 'top center',
                        }, 'success', 3000);
                        updateData()
                    }
                    
    
                }catch (err: any) {
                
                    notify({
                    message: `Упс... Произошла ошибка ${err.message}`,
                    position: 'top center',
                    }, 'error', 3000);
                }
                },
            },
    
            dkFile: {
                accept: "application/pdf",
                uploadMode:"useButton",
                allowCanceling: true,
                maxFileSize: 10000000,
                labelText: '',
                onValueChanged: (e: any) => {
                  const options = e.component.option()
                if(serviceVar.form){
                    if(options.value.length > 0) {
                        if(options.accept == options.value[0].type && options.value[0].size <= options.maxFileSize){
                            serviceVar.form.itemOption('uploadedFile', 'cssClass', 'invisible')
                            const button = serviceVar.form.getButton('downloadButton')
                            if (button){
                                button.option('disabled', false)
                            }
                        } else {
                            serviceVar.form.itemOption('uploadedFile', 'cssClass', data.uploadDk ? 'visible' : 'invisible')
                            const button = serviceVar.form.getButton('downloadButton')
                            if (button){
                                button.option('disabled', true)
                            }
                        }
                    } else {
                        serviceVar.form.itemOption('uploadedFile', 'cssClass', data.uploadDk ? 'visible' : 'invisible')
                        const button = serviceVar.form.getButton('downloadButton')
                        if (button){
                            button.option('disabled', true)
                        }
                    }
                    } 
                }
            },
    
            downloadButton: {
                text: data.uploadDk ? 'Заменить' :'Загрузить',
                type: 'success',
                disabled: true,
                verticalAlignment: 'center',
                onClick: async (e: any) => {
                    try{
    
                        const data = serviceVar.form.option('formData')
    
                        if(data.approvedStatusId !== 6){
                            await Records.controller.changeStatus(data.id, 6)
                            setData({
                                ...data,
                                approvedStatusName: `ТО пройден`, 
                                approvedStatusId: 6, 
                                approvedStatusColor: "#63d897" 
                            }) 
                            
                        }
                    
                        await Records.controller.uploadDkPdf(data.dkFile[0], data.id )
                        serviceVar.form.updateData('uploadDk', true)

                        manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, false)
                        manageRef?.current?.toolbar?.option(`${ToolbarButton.CANCEL}.visible`, true)
                        manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`, false)
                        manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, false)

                        if(updateData){
                            updateData()
                        }
                        
                        serviceVar.form.itemOption('uploadedFile', 'cssClass', data.uploadDk ? 'visible' : 'invisible')
                        const button = serviceVar.form.getButton('downloadButton')
                        if (button){
                            button.option('disabled', true)
                            button.option('text', 'Заменить')
                        }
                        const editor = serviceVar.form.getEditor('dkFile')
                        if (editor){
                            editor.option('value', [])
                        }
    
                        notify({
                        message: 'Запись успешно обновлена',
                        position: 'top center',
                        }, 'success', 3000);
    
                    }catch (err: any) {
                    
                        notify({
                        message: `Упс... Произошла ошибка ${err.message}`,
                        position: 'top center',
                        }, 'error', 3000);
                    }
    
                }
            },
            history: {
                collapsible: true,
                multiple: true,
                onInitialized: (e: any) => e.component?.option('selectedIndex', -1),
                animationDuration: 300,
                dataSource: [{
                    title: 'История изменений',
                    id: fromReport ? id : Number(urlId)
                }],
                itemComponent: (e:any) => <ItemAccordionHistory id={e.data.id}/> ,
                onItemClick: ({event}:any)=>{
                    const el = document.getElementsByClassName( fromReport? 'popup__content' : 'internal-layout__content' )[0]
                    const observer = new ResizeObserver(function() {
                        el.scrollTo(0, el.scrollHeight)
                    });
    
                    
                    
                    observer.observe(el)
                    setTimeout(() => {
                        observer.unobserve(el)
                    }, 2000)
                }
            },
            additional: {
                collapsible: true,
                multiple: true,
                onInitialized: (e: any) => e.component?.option('selectedIndex', -1),
                animationDuration: 300,
                dataSource: [{
                    title: 'Дополнительно',
                    
                }],
                itemComponent: (e:any) => {
    
                    const isOperator = checkPermission(userData?.roles, [Common.Permissions.ROLE_STO_ADMINISTRATOR, Common.Permissions.ROLE_STO_OPERATOR])
                    
                    return(
                        <div style={{marginBottom: 20}}>
                        <Form 
                            id="form-additions" 
                            formData={data}
                            onContentReady={e => e.component.validate()}
                            colCountByScreen={colCountAditionalByScreen}
                            onInitialized={({component}) => {
                                if(data.individual){
                                    component?.itemOption("inn", "visible", false);
                                    component?.itemOption("companyName", "visible", false);
                                }
                            }}
                            >
                                
                                <Item 
                                    dataField="clientFio" 
                                    label={{text: t('clientFio')}} 
                                    visible={isOperator}
                                    editorOptions={EditorOptions.clientFio} 
                                    isRequired
                                    />
                                <Item 
                                    dataField="clientPhone" 
                                    visible={isOperator}
                                    label={{text: t('clientPhone')}} 
                                    editorOptions={EditorOptions.phone} 
                                    />
                               <Item 
                                    dataField="clientEmail" 
                                    label={{text: t('clientEmail')}} 
                                    editorOptions={EditorOptions.email}
                                    />
                                <Item 
                                    dataField="inn" 
                                    label={{text: t('inn')}} 
                                    editorOptions={EditorOptions.inn}
                                    />
                                <Item 
                                    dataField="companyName" 
                                    label={{text: t('companyName')}} 
                                    editorOptions={EditorOptions.companyName} 
                                    isRequired
                                    />
                                
                                <Item 
                                    dataField="vin" 
                                    label={{text: t('vin')}} 
                                    editorOptions={EditorOptions.vin} 
                                    />
                                <Item 
                                    dataField={"tireBrandId"}
                                    label={{text: t('tireBrand')}} 
                                    editorType="dxSelectBox"
                                    editorOptions={EditorOptions.tireBrand} 
                                    />
                                <Item 
                                    dataField="mileage" 
                                    label={{text: t('mileage')}} 
                                    editorOptions={EditorOptions.mileage}
                                    />
                        </Form>
                        </div>
                    )
                },
                onItemClick: ({event}:any)=>{
                    const el = document.getElementsByClassName(fromReport? 'popup__content' : 'internal-layout__content' )[0]
                    const observer = new ResizeObserver(function() {
                        el.scrollTo(0, el.scrollHeight)
                    });
                    
                    observer.observe(el)
                    setTimeout(() => {
                        observer.unobserve(el)
                    }, 2000)
                }
            }
        }
    }, [serviceVar, userData, data])

    const { t } = useTranslation('records');


    const handleAgreeDelete = () => {
        handleDelete()
        offerPopupClose()
      }

    const handleDisagreeDelete = () => {
        offerPopupClose()
    }

    const handleAgreeResign = () => {
        offerPopupAgree({
            dataRecord: data,
            updateData: updateData? () => updateData() : () => null
        })
        handleClose()
      }
    
    const handleDisagreeResign = () => {
        offerPopupClose()
        handleClose()
    }
    
    const handleOpenOfferPopup = (type: 'resign'|'delete') => {

        if(type === 'resign'){
            offerPopupOpen({
                handleAgreePopup: handleAgreeResign,
                handleDisagreePopup: handleDisagreeResign,
                title: "Записать клиента на другую дату?",
                type: 'resign'
            })
        } else if(type === 'delete'){
            offerPopupOpen({
                handleAgreePopup: handleAgreeDelete,
                handleDisagreePopup: handleDisagreeDelete,
                title: "Вы действительно хотите удалить запись?",
                type: 'delete'
            })
        }
      }

    const getData = async () => {
        try{
            const result = await Records.controller.getById(fromReport ? id : Number(urlId))
            if(result.agentId == userData?.agent.id){
                result.agentName = 'Наша запись'
                agentNameCssClass.current = cn('channel-green')
            } else if (result.agentId == 25) {
                result.agentName = 'Сайт МРЭО.СПб'
                agentNameCssClass.current = cn('channel-yellow')
            } else {
                result.agentName = 'Партнер'
                agentNameCssClass.current = cn('channel-green')
            }
            serviceVar.form.updateData(result)
            manageRef?.current?.setIsLoading(false)

            afterGetData()

            if(result.individual){
                serviceVar.form.itemOption("groupClient.inn", "visible", false);
                serviceVar.form.itemOption("groupClient.companyName", "visible", false);
            }

            const setRightElement = manageRef?.current?.setRightElementTitle
            if(setRightElement){
                setRightElement((
                    <span className={cn('title-status')}>
                        Статус - <span style={{color: result.approvedStatusColor}}>{result.approvedStatusName}</span>
                    </span>
                ))
            }
        } catch(err:any){
            manageRef?.current?.setError(err.message)
            manageRef?.current?.setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        
        try{
            await Records.controller.remove(data.id)

            notify({
                message: 'Запись успешно удалена',
                position: 'top center',
            }, 'success', 3000);
            if(updateData){
                updateData()
            } 
            handleClose()
            

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        } 

    }

    const handleSubmit = async () => {
        try{
            const values = {
                id: data.id,
                stoId: data.stoId,
                dateReceiptStart: data.dateReceiptStart,
                priceClient: data.priceClient,
                tireBrandId: data.tireBrandId,
                lineNumber: data.lineNumber,
                mileage: data.mileage,
                comment: data.comment ,
            }
            await Records.controller.update(values)
            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
            }, 'success', 3000);

            if(updateData){
                updateData()
            }

            handleClose()

        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        } 
    }

    const handleChangeDate = (value: Date) => {
        serviceVar.form.updateData({
            dateReceiptStart: value
        })
    }

    const handleCancelChangeDate = (previousStoId?: number) => { 
        serviceVar.form.updateData({
            stoId: previousStoId
        })
        const editor = serviceVar.form.getEditor('dateReceiptStart')
        editor.option('disabled', false)
    }

    const handleDataChange = (e: any) => {
        if(serviceVar.form){
            const button = serviceVar.form.getButton('submit')

            if (button){
                if(e.component.validate().isValid && data.model && data.dateReceiptStart){
                    button.option('disabled', false)
                } else{
                    button.option('disabled', true)
                }
            }
        }
    }

    const initial = async () => {
        if(serviceVar.form && serviceVar.first && !serviceVar.load){
            if (id || urlId){
                getData()
                serviceVar.load = true
            }
        }
    }

    const afterGetData = async () => {
        
        const isStoAgent = userData?.roles.includes(Common.Permissions.ROLE_AGENT)
        const newServiceVar = {
            ...serviceVar,
            first: false,
            load: false,
            isStoAgent: isStoAgent,
            readonly: checkPermission(userData?.roles, Common.Permissions.ROLE_STO_OPERATOR) || (activeRecord ? (false) : fromReport),
            isClosed: !isStoAgent ? ![1,2,5].includes(data.approvedStatusId): ![1].includes(data.approvedStatusId) ,
            onlyEditTimeAndAddress: isStoAgent ? [2].includes(data.approvedStatusId) : false ,
            deleteButtonVisible: isStoAgent ? data.approvedStatusId === 1 : (
                    userData?.staff.agentId[0] === data.agentId &&
                    data.approvedStatusId === 2
                )
        }
        setServiceVar(newServiceVar)
        if (Object.keys(data).length){
                const visibleSubmit = (!newServiceVar.isClosed || newServiceVar.onlyEditTimeAndAddress) && !newServiceVar.readonly

                manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.options.onClick`, handleSubmit)
                manageRef?.current?.toolbar?.option(`${ToolbarButton.SUBMIT}.visible`, visibleSubmit)
                manageRef?.current?.toolbar?.option(`${ToolbarButton.OK}.visible`, !visibleSubmit)
                manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.visible`, newServiceVar.deleteButtonVisible)
                manageRef?.current?.toolbar?.option(`${ToolbarButton.DELETE}.options.onClick`, () => handleOpenOfferPopup('delete'))
        }
    }

    return (
        <>  
            <form>    
                <Form
                    onContentReady={e => e.component.validate()}
                    formData={data}
                    onFieldDataChanged={handleDataChange}
                    onInitialized={(e:any ) => {serviceVar.form = e.component; initial()}}
                    id="form">

                    <GroupItem 
                        colCountByScreen={colGroupFirst}
                        cssClass='form-group form-group-empty-caption'
                        >

                        <Item
                            dataField="dateReceiptStart" 
                            label={{text: t('dateReceiptStart')}} 
                            editorOptions={EditorOptions.dateReceiptStart}
                            editorType="dxDateBox"
                            isRequired
                            />

                        <Item 
                            dataField="lineNumber" 
                            label={{text: t('lineNumber')}} 
                            editorType="dxSelectBox" 
                            editorOptions={EditorOptions.lineNumber}
                            visible={!serviceVar.isStoAgent && userData?.sto.tsLimit > 1}
                            isRequired
                            />
                        <Item 
                            dataField="stoId"
                            colSpan={2}
                            editorType="dxSelectBox" 
                            label={{text: t('stoAddress')}} 
                            editorOptions={EditorOptions.stoAddress}
                            />
                        <Item 
                            dataField="agentName" 
                            label={{text: t('chanel')}} 
                            editorOptions={EditorOptions.channel}
                            cssClass={agentNameCssClass.current}
                            visible={checkPermission(userData?.roles, [Common.Permissions.ROLE_STO_OPERATOR, Common.Permissions.ROLE_STO_ADMINISTRATOR])}
                            />

                    </GroupItem>

                    <GroupItem 
                        caption="Информация о ТС" 
                        colCountByScreen={colCountAutoGroup} 
                        cssClass={'form-group' + " form-group-button-edit-top"}>
                        {(!serviceVar.readonly) && (
                            <ButtonItem
                                colSpan={colSpanItem(size, colCountAutoGroup)}
                                cssClass={cn('button-edit')}
                                buttonOptions={{
                                    type: 'default',
                                    onClick: () => {
                                        history.push(`/app/vehicle/${serviceVar.form.option('formData').vehicleId}`, {
                                            isBack: true,
                                            pathname: location.pathname,
                                            periodValues: location.state?.periodValues,
                                            popupParams: {
                                                type: Common.TypePopup.RECORD,
                                                id: fromReport ? id : Number(urlId)
                                            }
                                        })
                                        handleClose()
                                    },
                                    width: isMobile ? '100%' : null,
                                    text: 'Редактировать',
                                }}
                                />
                        )}
                        <Item 
                            dataField="regNumber" 
                            label={{text: t('regNumber')}} 
                            editorOptions={EditorOptions.regNumber}
                            />
                        <Item 
                            dataField="brand" 
                            label={{text: t('brand')}} 
                            editorOptions={EditorOptions.brand} 
                            isRequired
                            />
                        <Item 
                            dataField="model" 
                            label={{text: t('model')}} 
                            editorOptions={EditorOptions.model} 
                            isRequired
                            />
                        <Item 
                            dataField="dkAutoTypeName" 
                            editorOptions={EditorOptions.dkAutoType} 
                            isRequired
                            >
                                <Label render={() => <LabelAutoTypeTemplate dataField={'dkAutoType'}/>}/>
                        </Item>
                    </GroupItem>

                    <GroupItem 
                        colCountByScreen={colCountClientGroup}
                        caption={"Информация о клиенте"} 
                        visible={checkPermission(userData?.roles, [Common.Permissions.ROLE_STO_ADMINISTRATOR, Common.Permissions.ROLE_STO_OPERATOR])}
                        name={'groupClient'}
                        cssClass='form-group form-group-button-edit-top'>
                        {(!serviceVar.readonly) && (
                            <ButtonItem
                                colSpan={colSpanItem(size, colCountClientGroup)}
                                cssClass={cn('button-edit')}
                                buttonOptions={{
                                    type: 'default',
                                    onClick: () => {
                                        if (data?.individual){
                                            history.push(`/app/client/individual/${serviceVar.form.option('formData').clientId}`,{
                                                isBack: true,
                                                pathname: location.pathname,
                                                popupParams: {
                                                    type: Common.TypePopup.RECORD,
                                                    id: fromReport ? id : Number(urlId)
                                                },
                                                periodValues: location.state?.periodValues
                                            })
                                        } else {
                                            history.push(`/app/client/entity/${serviceVar.form.option('formData').clientId}`,{
                                                isBack: true,
                                                pathname: location.pathname,
                                                periodValues: location.state?.periodValues,
                                                popupParams: {
                                                    type: Common.TypePopup.RECORD,
                                                    id: fromReport ? id : Number(urlId)
                                                }
                                            })
                                        }
                                        handleClose()
                                    },
                                    width: isMobile ? '100%' : null,
                                    text: 'Редактировать',
                                }}
                                />
                        )}
                        <Item 
                            dataField="clientFio" 
                            label={{text: t('clientFio')}} 
                            editorOptions={EditorOptions.clientFio} 
                            isRequired
                            />
                        <Item 
                            dataField="clientPhone" 
                            label={{text: t('clientPhone')}} 
                            editorOptions={EditorOptions.phone} 
                            />
                        
                         <Item 
                            dataField="inn" 
                            label={{text: t('inn')}} 
                            editorOptions={EditorOptions.inn}
                            isRequired
                            />
                        <Item 
                            dataField="companyName" 
                            label={{text: t('companyName')}} 
                            editorOptions={EditorOptions.companyName} 
                            isRequired
                            />
                        
                    </GroupItem>
                

                    <GroupItem 
                        colCountByScreen={colCountByScreen}
                        cssClass='form-group form-group-empty-caption'
                    >

                        <Item
                            dataField="priceClient" 
                            label={{text: t('priceClient')}} 
                            editorOptions={EditorOptions.priceClient}
                            editorType="dxNumberBox"
                            />
                        <Item
                            dataField="priceSto" 
                            label={{text: t('priceSto')}} 
                            visible={!checkPermission(userData?.roles, Common.Permissions.ROLE_STO_OPERATOR)}
                            editorOptions={EditorOptions.priceSto}
                            editorType="dxNumberBox"
                            disabled={serviceVar.isStoAgent ? true : false}
                            />
                        
                        <Item 
                            dataField="comment" 
                            label={{text: t('comment')}} 
                            colSpan={colSpanItem(size, colCountComment)} 
                            editorOptions={EditorOptions.comment}
                            />
                            { !serviceVar.isStoAgent && (
                        <GroupItem
                            colSpan={2}
                            colCount={2}
                            >
                            <GroupItem
                                colSpan={2}
                                colCount={6}
                                >
                                <Item 
                                    dataField="dkFile" 
                                    label={{text: t('dk')}} 
                                    colSpan={2}
                                    editorType="dxFileUploader" 
                                    editorOptions={EditorOptions.dkFile}
                                    />
                                <ButtonItem
                                    name='downloadButton'
                                    verticalAlignment={'center'}
                                    cssClass={cn('button-upload')}
                                    horizontalAlignment={'left'}
                                    colSpan={2}
                                    buttonOptions={EditorOptions.downloadButton}
                                    />
                            </GroupItem>
                            <Item
                                label={{visible: false}} 
                                name='uploadedFile'
                                cssClass={data.uploadDk ? 'visible' : 'invisible'}
                                colSpan={2}
                                >
                                    <div className={cn('uploaded-file')} onClick={() => handleDownload(data.id)}>
                                    <span className={cn('uploaded-file-name')}>
                                        Бланк ДК №{fromReport ? id : Number(urlId)}.pdf
                                    </span>
                                    <span className={cn('uploaded-file-status')}>
                                        Загружено
                                    </span>
                                    </div>
                            </Item>
                        </GroupItem>
                        ) }
                    </GroupItem>

                    
                    <Item>
                        <Accordion
                            {...EditorOptions.additional}
                            />
                    </Item>

                    <Item 
                        cssClass={!fromReport ? cn('margin-top-30') : ''}
                        colSpan={colSpanItem(size, colCountByScreen)} 
                        
                        editorType="dxDropDownButton" 
                        visible={data.uploadDk || (!serviceVar.isStoAgent && !serviceVar.isClosed)}
                        editorOptions={EditorOptions.buttonAction}
                        />
                    

                    <Item
                        label={{visible: false}} 
                        dataField="history" 
                        >
                        <Accordion
                            {...EditorOptions.history}
                            />
                    </Item>
                    
                    {( !fromReport) && (
                        <GroupItem 
                            colCount={2}
                            cssClass={cn('group-button')}
                            >
                            <ButtonItem
                                colSpan={1}
                                name='delete'
                                horizontalAlignment={'left'}
                                visible={serviceVar.deleteButtonVisible}
                                buttonOptions={{
                                    type: 'danger',
                                    text: 'Удалить',
                                    onClick: ()=> handleOpenOfferPopup('delete')
                                }}
                                />
                            <ButtonItem
                                colSpan={serviceVar.deleteButtonVisible ? 1 : 2}
                                name='submit'
                                visible={!serviceVar.isClosed || serviceVar.onlyEditTimeAndAddress}
                                buttonOptions={{
                                    type: 'default',
                                    text: 'Сохранить',
                                    disabled: true,
                                    onClick: () => handleSubmit()
                                }}
                                />
                        </GroupItem>
                    )}

                </Form>
            </form>
            <ChangeDatePopup
                ref={popupChangeDataRef}
                onChangeDate={handleChangeDate}
                onCancel={handleCancelChangeDate}
                />
        </>
    )
})

EditRecordComponent.displayName = 'EditRecordComponent'

const EditRecord = () => {
    
    return (
        <EditBase Component={EditRecordComponent} title='Редактирование данных о записи'/>
    )
}


export default EditRecord