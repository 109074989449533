import { useContext } from "react";
import { PartnerModel } from "~/api/partner";
import { Model } from "~/api/records"
import { AuthContext } from "~/context";
import { Common } from "~/types";
import { checkPermission } from "~/utils/checkPermission";

const coeff = 24*60*60*1000


export const filterByStoOrStaff = (
    data: Model[], 
    allStaff: boolean, 
    allSto: boolean,  
    staffId: number | undefined, 
    stoId: number | undefined, 
    roles?: Array<Common.Permissions | Common.SubPermissions> ) => {

    if (checkPermission(roles, Common.Permissions.ROLE_STO_ADMINISTRATOR)){
        if (!allSto){
            return data.filter(i => i.stoId === stoId)
        } 
    } else {
        if (!allStaff){
            return data.filter(i => i.staffId === staffId)
        }
    }

    return data
}

export const filterByPartner = (
    data: Model[],
    partner?: PartnerModel
) => {

    if(!partner){
        return data
    }

    if(partner.id === 0){
        return data
    }

    if(partner.stoId?.length){
        return data.filter(i => partner.stoId?.includes(i.stoId))
    }

    return data.filter(i => partner.agentsId?.includes(i.agentId))
}


export const getMount = () => {
    var date = new Date();
    var firstday = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastday = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return { firstday, lastday }
}

export const getPreviousMount = () => {
    var date = new Date();
    var firstday = new Date(date.getFullYear(), date.getMonth()-1, 1);
    var lastday = new Date(date.getFullYear(), date.getMonth(), 0);

    return { firstday, lastday }
}

export const getPreviousWeek = () => {
    var currDate = new Date(); 

    if(currDate.getDay() === 0){
        var firstDay = currDate.getDate() - 6 - 7; 
    } else {
        var firstDay = currDate.getDate() - currDate.getDay()+1-7; 
        
    }

    var firstday = new Date(currDate.setDate(firstDay));
    var lastday = new Date(currDate.getTime() + 6*coeff)

    return { firstday, lastday }
}

export const getWeek = () => {
    var currDate = new Date(); 

    if(currDate.getDay() === 0){
        var firstDay = currDate.getDate() - 6; 
    } else {
        var firstDay = currDate.getDate() - currDate.getDay()+1; 
        
    }

    var firstday = new Date(currDate.setDate(firstDay));
    var lastday = new Date(currDate.getTime() + 6*coeff)

    return { firstday, lastday }
}

export const getNextWeek = () => {
    var currDate = new Date(); 

    if(currDate.getDay() === 0){
        var firstDay = currDate.getDate() - 6 + 7; 
    } else {
        var firstDay = currDate.getDate() - currDate.getDay()+1 + 7; 
    }

    var firstday = new Date(currDate.setDate(firstDay));
    var lastday = new Date(firstday.getTime() + 6*coeff)

    return { firstday, lastday }
}
