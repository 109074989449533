import React, { useContext, useLayoutEffect, useState, useEffect } from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { PopupContext } from '~/context';


type Props = {

}

type PropsColorButton = {
  agree: "danger" | 'success',
  disagree: "danger" | 'success'
}

const PopupOffer: React.FC<Props> = ({ }) => {


  const { popupState: {
    offerPopup: {
      visible,
      handleAgreePopup, 
      handleDisagreePopup, 
      title, 
      type
    }
  }
 } = useContext(PopupContext);

  const [ colorButton, setColorButton ] = useState<PropsColorButton>(type === 'resign' ? {
    agree: 'success',
    disagree: "danger"
  } : {
    agree: "danger",
    disagree: 'success'
  })

  useEffect(() => {
    if (visible){
      setColorButton(type === 'resign' ? {
        agree: 'success',
        disagree: "danger"
      } : {
        agree: "danger",
        disagree: 'success'
      })
    } else {
      setTimeout(() => {
        setColorButton(type === 'resign' ? {
          agree: 'success',
          disagree: "danger"
        } : {
          agree: "danger",
          disagree: 'success'
        })
      }, 500)
    }
    
  }, [type])


  return (
    <Popup
          visible={visible}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          title={title}
          width={500}
          height={150}
        >
        <Position
            at="center"
            my="center"
        />

        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Button
                width={120}
                text="Нет"
                type={colorButton.disagree}
                stylingMode="contained"
                onClick={() => handleDisagreePopup()}
                />

            <Button
                width={120}
                text="Да"
                type={colorButton.agree}
                stylingMode="contained"
                onClick={() => handleAgreePopup()}
                />
            
        </div>
    </Popup>
  );
}

export default PopupOffer