import { SignIn } from './SignIn';
import { SelectProfile } from './SelectProfile';

export const ROUTES = [
  {
    Component: SignIn,
    exact: true,
    path: '/auth',
  },

  {
    Component: SelectProfile,
    exact: true,
    path: '/auth/selectprofile',
  },

];
