import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { bemHelper } from '~/libs';
import { AuthContext } from '~/context';
import './styles.scss';
import { Notifications } from '~/api';
import { NotificationContext } from '../../../../../../../context/NotificationContext/index';
import { clearStoreCache, partnersDataSource } from '~/custom-data-sources';

const cn = bemHelper('dropdown');

type Props = {

    toggleProfileMenu(): void;
  };
  

export const Dropdown: React.FC<Props> = (props) => {

    const { toggleProfileMenu } = props
    const {
        logOut,
      } = useContext(AuthContext);

    const handleLogout = async () => {
        clearStoreCache()
        await logOut();
        Notifications.controller.disconnect()
    }
      
    return (
        <>
        <div className={cn('layout')} onClick={() => toggleProfileMenu()}></div>
        <div className={cn()}>
            <ul>
                <Link to='/app/profile' onClick={() => toggleProfileMenu()}>
                    <li className={cn('button-profile')}>
                        Профиль
                    </li>
                </Link>
                <Link to='/app/instructions' onClick={() => toggleProfileMenu()}>
                    <li className={cn('button-profile')}>
                        Справка
                    </li>
                </Link>
                <Link 
                    to='/app/logout' 
                    onClick={handleLogout}
                    >
                    <li className={cn('button-logout')}>
                        Выйти
                    </li>
                </Link>
            </ul>
        </div>
        </>
    );
};
