import React, { memo, useContext } from 'react';
import {formatDate} from 'devextreme/localization';

import './styles.scss'
import { Model } from '~/api/records';
import { bemHelper } from '~/libs';
import { AuthContext } from '~/context';


type Props = {
  data: {
    targetedAppointmentData: Model,
  }
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const cn = bemHelper('Appointment');


const Appointment: React.FC<Props> = memo(({data}) => {

  const {targetedAppointmentData} = data

  const { authState } = useContext(AuthContext);


  const startTime = new Date(targetedAppointmentData.dateReceiptStart)
  const endTime = new Date(targetedAppointmentData.dateReceiptFinish)

  return (
    <div className={cn()}>
      <div className={cn('flex')}>

          <ul className={cn('ul')}>
            <li><span >{targetedAppointmentData.regNumber}</span></li>
            <li><span>{targetedAppointmentData.dkAutoTypeName}</span></li>
          </ul>
      </div>
    </div>
  );
})

export default Appointment