import React, { FC, forwardRef, useMemo, useRef } from 'react';
import Form, { Item, ButtonItem, GroupItem, Label, EmptyItem } from 'devextreme-react/form';
import { ActDetailTable } from '../ActDetailTable';
import { Common } from '~/types';
import { bemHelper } from '~/libs';

import './styles.scss'
import { ActDetail, ActRecord } from '~/api/acts';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { ActDetailFormData, ChangedData } from '../../ActDetail';
import ItemAccordionHistory from '../ItemAccordionHistory/ItemAccordionHistory';
import { Accordion } from 'devextreme-react';
import { onExporting } from '~/routes/Reports/service';
import { ExportingEvent } from 'devextreme/ui/data_grid';

type Props = {
    data: ActDetailFormData,
    changedData: ChangedData,
    handleApprove: () => void,
    handleSentToFix: () => void,
    handleReApprove: () => void,
}

export const ActDetailForm: FC<Props> =
({
    data,
    changedData,
    handleApprove,
    handleSentToFix,
    handleReApprove
}) => {
    const formRef = useRef<Common.FormRef>(null)
    const cn = bemHelper('act-detail-form')

    const allowEditPartner = useMemo(()=>{
        return (!data.owner && data.statusId == 2)
    }, [data])

    const allowEditOwner = useMemo(()=>{
        return (data.owner && [2,6].includes(data.statusId))
    }, [data])

    const allowReApprove = useMemo(()=>{
        return data.owner && data.statusId==6
    }, [data])

    const editorOptions = useMemo(() => {
        return {
            comment: {
                autoResizeEnabled: true,
                readOnly: !allowEditOwner && !allowEditPartner,
            },
            history: {
                collapsible: true,
                multiple: true,
                onInitialized: (e: any) => e.component?.option('selectedIndex', -1),
                animationDuration: 300,
                dataSource: [{
                    title: 'История изменений',
                    id: 1
                }],
                itemComponent: (e:any) => <ItemAccordionHistory defaultData={data.dkAktHistories}/> ,
                onItemClick: ({event}:any)=>{
                    const el = document.getElementsByClassName('internal-layout__content')[0]
                    const observer = new ResizeObserver(function() {
                        el.scrollTo(0, el.scrollHeight)
                    });
    
                    observer.observe(el)
                    setTimeout(() => {
                        observer.unobserve(el)
                    }, 2000)
                }
            },
        }
    }, [allowEditPartner])

    const buttonOptions = useMemo(() => {
        return {
            sentToFix: {
                type: 'normal',
                text: 'Отправить на доработку',
                disabled: true,
                visible: allowEditPartner,
                onClick: handleSentToFix
            },
            sentToReApprove: {
                type: 'default',
                text: 'Отправить на повторное согласование',
                visible: allowReApprove ,
                onClick: handleReApprove
            },
            approve: {
                type: 'default',
                text: 'Согласовано',
                visible: allowEditPartner,
                useSubmitBehavior: true,
                onClick: handleApprove
            }
        }
    }, [allowEditPartner, handleApprove, handleSentToFix, allowReApprove])

    const handleDataChanged = () => {
        let flag = false
        const buttonSendToFix = formRef.current?.instance.getButton('sendToFix')
        const formData = formRef.current?.instance.option('formData')

        formData.dkList.map((i: ActRecord) => {
            if(!i.comment){
                flag = true
            }
        })

        buttonSendToFix?.option('disabled', (
            !formRef.current?.instance.validate().isValid || 
            flag
            ))
    }

    const onExport = (e: ExportingEvent<ActRecord, any>) => {
        const fileName = `Aкт №${data.aktId}`
        onExporting(e, fileName)
    }

    return (
        <Form 
            ref={formRef}
            formData={data}
            onFieldDataChanged={handleDataChanged}
            >
            <Item
                dataField="dkList"
                label={{visible: false}}
                render={({component, dataField})=> (
                    <ActDetailTable
                        allowEditPartner={allowEditPartner}
                        allowEditOwner={allowEditOwner}
                        data={component.option('formData')[dataField]}
                        changedData={changedData}
                        onDataChanged={handleDataChanged}
                        onExport={onExport}
                        />
                )}
                />
            <GroupItem
                cssClass={cn('content')}
                >
                <Item 
                    dataField="comment"
                    editorType={'dxTextArea'}
                    label={{text: 'Комментарий'}}
                    editorOptions={editorOptions.comment}
                    />

                <Item
                    label={{visible: false}} 
                    dataField="history" 
                    >
                    <Accordion
                        {...editorOptions.history}
                        />
                </Item>

                <GroupItem
                    colCount={2}
                    cssClass={cn('button-group')}
                    >
                    <ButtonItem
                        name='sendToFix'
                        horizontalAlignment={'left'}
                        buttonOptions={buttonOptions.sentToFix}
                        />
                    <ButtonItem
                        name='approve'
                        buttonOptions={buttonOptions.approve}
                        />
                    <EmptyItem
                        visible={allowReApprove}
                        />
                    <ButtonItem
                        name='submitReApprove'
                        buttonOptions={buttonOptions.sentToReApprove}
                        />
                </GroupItem>
            </GroupItem>
        </Form>
    )
}

