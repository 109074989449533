import React, { useContext } from 'react';
import {formatDate} from 'devextreme/localization';
import './styles.scss'

import { Model } from '~/api/records';
import { bemHelper } from '~/libs';
import { AuthContext } from '~/context';


type Props = {
  data: {
    targetedAppointmentData: Model,
  }
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const cn = bemHelper('AppointmentTooltip');

const AppointmentTooltip: React.FC<Props> = ({data}) => {

  const {targetedAppointmentData} = data

  const { authState } = useContext(AuthContext);

  const startTime = new Date(targetedAppointmentData.dateReceiptStart)
  const endTime = new Date(targetedAppointmentData.dateReceiptFinish)

  return (
    <div className={cn()}>
      <div className={cn('flex')}>
        <div className={cn('circle')} style={{backgroundColor: targetedAppointmentData.approvedStatusColor}}></div>
        <div>
          
          {authState.userData?.agent.id !== targetedAppointmentData.agentId && (
            <div className={cn('title')}> {targetedAppointmentData.agentName}</div>
          )}
          <div className={cn('flex-time')}>
            <div className={cn('month')}>{monthNames[startTime.getMonth()]}</div>
            <div className={cn('day')}>{startTime.getDate()}</div>
            <div>{formatDate(startTime, 'shortTime')}</div>
            <div>{'-'}</div>
            <div>{formatDate(endTime, 'shortTime')}</div>
          </div>

          

          <ul className={cn('ul')}>
            <li><span>Марка:</span><span>{targetedAppointmentData.brand}</span></li>
            <li><span>Модель:</span><span>{targetedAppointmentData.model}</span></li>
            <li><span>Гос номер:</span><span>{targetedAppointmentData.regNumber}</span></li>
          </ul>

        </div>

        

      </div>
    </div>
  );
}

export default AppointmentTooltip