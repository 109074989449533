import React, { forwardRef, useContext, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Form } from 'devextreme-react';
import { Item } from 'devextreme-react/form';
import { AddTimeBlockData, AddTimeBlockManagerForm, AddTimeBlockManagerFormRef } from './atoms/AddTimeBlockManagerForm';
import { Records } from '~/api';
import { AuthContext } from '~/context';
import notify from 'devextreme/ui/notify';
import { useWeekHours } from '../../hooks';

type Props = {

}

export type AddTimeBlockPopupManagerRef = {
    open: (currentDate: Date) => void,
}

export const AddTimeBlockPopup = forwardRef<AddTimeBlockPopupManagerRef, Props>(({}, ref) => {
    const refPopup = useRef<Popup>(null)
    const refForm = useRef<AddTimeBlockManagerFormRef>(null)

    const {
        authState: { userData },
      } = useContext(AuthContext);

    const {getWorkTime} = useWeekHours()

    const workTime = getWorkTime(new Date())

    const [defaultValues, setDefaultValues] =  useState({
        timeStart: workTime[0],
        timeFinish: workTime[1]
    })
    
    const handleClose = () => {
        refPopup.current?.instance.hide()
    }

    const handleSubmit = async (formData: AddTimeBlockData) => {
        const values: any = {
            stoId: userData?.sto.id,
            allDay: formData.allDay || false,
            date: formData.date.toLocaleDateString('sv'),
            timeStart: formData.timeStart?.toISOString() || null,
            timeFinish: formData.timeFinish?.toISOString() || null,
        }

        try{
            await Records.controller.addBlock(values)
            refForm.current?.resetForm()
            handleClose()
        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
              }, 'error', 3000);
        }

    }

    useImperativeHandle(ref, ()=>({
        open: (currentDate) => {
            const workTime = getWorkTime(currentDate)
            refForm.current?.updateData({
                date: currentDate,
                timeStart: workTime[0],
                timeFinish: workTime[1]
            })
            refPopup.current?.instance.show()
        }
    }))

    
    return (
            <Popup
                ref={refPopup}
                hideOnOutsideClick={true}
                hideOnParentScroll={true}
                dragEnabled={false}
                showTitle={true}
                title='Добавление блока от записи'
                width={'100%'}
                maxWidth={800}
                height={'auto'}
                onHidden={handleClose}
                >

                <AddTimeBlockManagerForm
                    ref={refForm}
                    handleClose={handleClose}
                    defaultValues={defaultValues}
                    onSubmit={handleSubmit}
                    />

            </Popup>
    )
})