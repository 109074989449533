import { Partner } from "~/api";
import CustomStore from 'devextreme/data/custom_store';

export const partnersDataSource = new CustomStore({
    key: 'id',
    loadMode: 'raw',
    load: async () => {
      const data = await Partner.controller.getPartners()
      return data;
    },
})