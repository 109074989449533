import React from "react"
import CustomStore from 'devextreme/data/custom_store';
import { Acts, ActsQueryData } from "~/api/acts";
import { ActsBase } from "~/ui/atoms";
import { ActsTable } from "../atoms/ActsTable";

export const PaidActs = () => {
    const dataSource = new CustomStore({
        key: 'aktId',
        load: async ({searchValue}) => {
            const query: ActsQueryData = searchValue
            const result = await Acts.controller.getActs(query)
            return result.filter(i => [4].includes(i.statusId))
        }
    })

    return (
        <ActsBase
            title='Оплаченные акты'
            Table={ActsTable}
            dataSource={dataSource}
            incomingAndOutgoingTables
            excludeColumn={['statusName']}
        />
    )
}