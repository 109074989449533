import { SelectBox } from 'devextreme-react';
import React, { useContext, useState } from 'react'
import { Records } from '~/api';
import notify from 'devextreme/ui/notify';
import { AuthContext } from '~/context';
import { TypeUserData } from '~/context/AuthContext';
import { bemHelper } from '~/libs';
import './style.scss'


type EventProps = {
    previousValue: number,
    value: number
    
}
type dataSourceType = {
    visible?: boolean,
    name: string,
    id: number,
    
}

const cn = bemHelper('column-select-box');

const ColumnSelectBox = (cell: any, updateData: () => void, userData: TypeUserData | null ) => {

    const dataSource =  [{
            id: 6,
            name:'Техосмотр пройден ',
        },
        {
            id: 7,
            name:'Техосмотр не пройден',
        },
        {
            id: 5,
            name:'Клиент прибыл',
        },
        {
            id: 8,
            name:'Клиент не прибыл',
        },
        {
            id: 3,
            name:'Отклонена',
        },
        {
            id: 2,
            name:'Согласована',
            visible: userData?.staff.agentId !== cell.data.agentId && cell.data.approvedStatusId === 1
        }
    ]

    const onValueChanged = async (e  : EventProps) => {
        try{
            await Records.controller.changeStatus(cell.data.dkId, e.value)
        
            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
                }, 'success', 3000);
            
            updateData()

        }catch (err: any) {
        
            notify({
            message: `Упс... Произошла ошибка ${err.message}`,
            position: 'top center',
            }, 'error', 3000);
        }
    }  

    return <SelectBox
      defaultValue={cell.value}
      className={cn('select')}
      {...cell.column.lookup}
      dataSource={dataSource}
      onValueChanged={onValueChanged}
    //   inputAttr={statusLabel}
    //   itemRender={()}
    />;
}

export default ColumnSelectBox