import React, { memo, useContext, useEffect, useState } from 'react';
import { AuthContext, NotificationContext } from '~/context';
import { BurgerBtn } from '~/ui/atoms';
import { bemHelper } from '~/libs';
import './styles.scss';
import { Dropdown } from './atoms/DropDown';
import { useHistory, useLocation } from 'react-router';
import { Common } from '~/types';
import { Button } from 'devextreme-react/button';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import person from '~/static/data/person.svg'
import { Notifications } from './atoms/Notifications';
import { CSSTransition } from 'react-transition-group';
import { usePressKey } from '~/hooks/usePressKey';
import { checkPermission } from '~/utils/checkPermission';

const cn = bemHelper('header');

type Props = {
  menuActive: boolean;
  mix: string;
  toggleMenu(): void;
  toggleNotificationsMenu(): void;
  toggleProfileMenu(): void;
  menuProfile: boolean;
  menuNotifications:boolean;
};

export const Header: React.FC<Props> = memo((props) => {
  const { menuActive, mix, toggleMenu, menuProfile, toggleProfileMenu, toggleNotificationsMenu, menuNotifications} = props;

  const { authState: {userData, isManyProfiles} } = useContext(AuthContext);
  const {
    notificationState: {
        notifications,
        connection
    },
  } = useContext(NotificationContext);
  const history = useHistory()
  const size = useWindowSize()
  const location = useLocation()

  const unreadedCount = notifications.filter(_i => !_i.checked).length


  const handleLink = () => {
    if (checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR)){
      history.push(`/app/station/${userData?.sto?.id}`)
    } else if(userData?.roles.includes(Common.Permissions.ROLE_AGENT)){
      history.push(`/app/agent/${userData?.agent?.id}`)
    }
  }

  useEffect(() => {
    if (menuProfile){
      toggleProfileMenu()
    }
    if (menuNotifications){
      toggleNotificationsMenu()
    }
    
  }, [location])


  return (
    <header className={cn(undefined, undefined, mix)}>
      <div className={cn('left')}>
      
        <span 
          style={{cursor: 'pointer'}} onClick={() => handleLink()}
          title={checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR) ? 
            (userData?.sto?.nameShort) : 
            (userData?.agent?.name)
          }
          className={cn('company-name')}
          >
          { checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR) ? 
            (userData?.sto?.nameShort) : 
            (userData?.agent?.name)
          }
        </span>


        { isManyProfiles && (
          <Button
              width={40}
              type="normal"
              stylingMode="text"
              icon="fas fa-users"
              hint='Сменить профиль'
              className={cn('users-icon')}
              onClick={() => {history.push('/auth/selectprofile')}}
              />
        ) }

        <BurgerBtn
          active={menuActive}
          mix={cn('burger-btn')}
          onClick={toggleMenu}
          title={menuActive ? 'Скрыть меню' : 'Показать меню'}
        />
      </div>

      <div className={cn('right')}>
        <div
          className={cn('control', { profile: true })}
          title="Профиль"
          onClick={() => toggleProfileMenu()}
        >
          { size !== 'xs' ? (
            <span>{userData?.staff?.lastname} {userData?.staff?.firstname} {userData?.staff?.fathername}</span>
          ) : (
            <i className="fas fa-user"></i>
          )}
        </div>

        <div className={cn('button-notification')}>
          <Button
            width={40}
            type="normal"
            stylingMode="text"
            icon="fas fa-bell"
            style={{color: 'white'}}
            onClick={() => {
              toggleNotificationsMenu()
            }}
            />

          <CSSTransition classNames="notification-button" in={unreadedCount>0} timeout={150} unmountOnExit>
            <>
              { connection && (
                <span>
                  {unreadedCount <= 99 ? unreadedCount : '99+' }
                </span>
              )}
          </>
          </ CSSTransition>
          { !connection && (
            <span>
              <i className="fas fa-times"></i>
            </span>
          )}
        </div>

        { menuProfile && <Dropdown toggleProfileMenu={toggleProfileMenu}/>}
        { menuNotifications && <Notifications toggleNotificationsMenu={toggleNotificationsMenu}/>}
        
    

      </div>
    </header>
  );
})
