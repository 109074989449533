import { Common } from '~/types';
import FinantialReport from './FinantialReport';
import CommonReport from './CommonReport';
import AdminReport from './AdminReport';


export const ROUTES = [

  {
    Component: CommonReport,
    permissionComponent: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_ADMINISTRATOR],
    exact: true,
    path: '/app/report/common',
  },
  {
    Component: FinantialReport,
    permissionComponent: [Common.Permissions.ROLE_AGENT, Common.Permissions.ROLE_STO_ADMINISTRATOR],
    exact: true,
    path: '/app/report/financial',
  },
  {
    Component: AdminReport,
    permissionComponent: Common.SubPermissions.ROLE_ADMIN,
    exact: true,
    path: '/app/report/admin',
  },

];
