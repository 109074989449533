import React from 'react'
import { useParams } from 'react-router'
import { EditRecordComponent } from '~/ui/atoms/Edit/EditRecord'
import EditBase from '~/ui/atoms/EditBase'

type ParamsProps = {
  urlId: string
}

const EditRecord = () => {

  const { urlId } = useParams<ParamsProps>()
  return (
    <EditBase Component={EditRecordComponent} title={`Запись №${urlId}.`}/>
  )
}
export default EditRecord
