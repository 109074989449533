import React, { useEffect, useMemo, useState } from "react";
import { ElementRef, forwardRef } from "react"
import Form, { Item, ButtonItem, GroupItem, Label, EmptyItem } from 'devextreme-react/form';
import { bemHelper } from "~/libs";
import './styles.scss'
import { ActsQueryData } from "~/api/acts";

export type FilterFormRef = ElementRef<typeof Form>

type Props = {}

export const FilterForm = forwardRef<
    Form,
    Props
>(({}, ref) => {
    const cn = bemHelper('acts-filter-form')

    const data: ActsQueryData = useMemo(() => ({}),[])

    const buttonOptions = useMemo(() => {
        return {
            type: 'default',
            text: 'Поиск',
            useSubmitBehavior: true
        }
    }, [])

    const editorOptions = {
        fio: {
            placeholder: 'Иванов Иван Иванович',
        },
        regNumber: {
            mask: 'XXXXXXXXK',
            maskRules: {
                X: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9]/,
                K: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9\s]/
            },
            maskChar: ' ',
            placeholder: 'А123МН78',
            useMaskedValue: true,
            showMaskMode: 'onFocus',
            maskInvalidMessage: "8 или 9 символов. Можно вводить только русские буквы (А,В,Е,К,М,Н,О,Р,С,Т,У,X) и цифры.",
        },
        dkId:{
            value: data.dkId || null,
        }
    }

    return (
        <Form 
            ref={ref}
            formData={data}
            colCount={4}
            width={'100%'}
            >
            <Item 
                dataField="regNumber"
                label={{text: 'Гос.номер'}}
                editorOptions={editorOptions.regNumber}
                />

            <Item 
                dataField="dkId"
                editorType={'dxNumberBox'}
                label={{text: 'Номер записи'}}
                editorOptions={editorOptions.dkId}
                />
            <EmptyItem/>
            <ButtonItem
                name='submit'
                cssClass={cn('button')}
                buttonOptions={buttonOptions}
                />
        </Form >
    )
})