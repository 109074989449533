import React, { FC, forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import { CreateActTable } from './atoms/CreateActTable';
import { Act } from '~/api/acts';
import { Button } from 'devextreme-react';
import { Common } from "~/types";

type Props = {  
}

export type CreateActFormRef = {
    getFormData: () => any,
    setFormData: (value: any) => void,
    setDisabledButton: (value: boolean) => void,
}

const CreateActForm = forwardRef<
CreateActFormRef,
    Props
>(({

}, ref) => { 

    const [ disabled, setDisabled ] = useState(true)
    const tableRef = useRef<Common.DataGridRef>(null)

    useImperativeHandle(ref, () => {
        return {
            getFormData: () => {
                return tableRef.current?.instance.option('dataSource')
            },
            setFormData: (value: any) => {
                return tableRef.current?.instance.option('dataSource', value)
            },
            setDisabledButton: (value: boolean) => {
                setDisabled(value)
            }
        }
    })

    return (
        <div>
            <CreateActTable
                ref={tableRef}
                />
            <div
                style={{
                    width:' 100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: 20,
                    paddingRight: 30,
                }}
                >
                <Button
                    type='default'
                    text='Создать'
                    disabled={disabled}
                    useSubmitBehavior
                    />
            </div>
        </div>
    )
})
export {CreateActForm};