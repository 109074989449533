import { Partner, Records, Sto } from "~/api";
import CustomStore from 'devextreme/data/custom_store';
import { formatDate } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';

export const dkDataSource = {
    store: new CustomStore({
        key: 'dkId',
        load: async (loadOptions) => {
          const startDate = new Date(loadOptions.filter[0][0][0][2]);
          const endDate = new Date(loadOptions.filter[0][0][1][2]);
          
          const days = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
          const date = startDate
          date.setDate(date.getDate() + 1);
    
          const data = await Sto.controller.getOrderInfo({ 
            days,
            date: date
              .toISOString()
              .split('T')[0],
          });
    
            data.map(i => {
              i.textExpr = formatDate(new Date(i.dateReceiptStart), 'shortTime')
              return i
            })
          
          return data
        },
        remove: (key) => {
          return Records.controller.create(key);
        },
        errorHandler: (error: any) => {
          notify({
            message: `Упс... Произошла ошибка ${error.message}`,
            position: 'top center',
          }, 'error', 3000);
        },
    
        onInserted: function (values, key) {
          if(Number(values.code) === 200){
            notify({
              message: 'Запись успешно создана',
              position: 'top center',
            }, 'success', 3000);
          } else {
            notify({
              message: `Упс... Произошла ошибка ${values.msg} ${values.code}`,
              position: 'top center',
            }, 'error', 3000);
          }
        },
    
    })
}