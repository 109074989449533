import { CheckBox, TextBox, Form, SelectBox, Accordion } from "devextreme-react";
import { ButtonItem, GroupItem, Item, Label } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Records } from "~/api";
import { Model as ModelAutoTypes} from "~/api/autotype";
import { Sto, ModelSto } from '~/api/sto'
import { TireBrand } from "~/api/tirebrand";
import { Vehicle } from "~/api/vehicle";
import { AuthContext } from "~/context";
import { useWindowSize } from "~/hooks/useWindowSize";
import { bemHelper } from "~/libs";
import { mileageAdorment, priceAdorment, validationRules } from "~/routes/Records/services";
import { colSpanItem } from "~/ui/atoms/Edit/service";

import './styles.scss'
import { SelectBoxAutoTypeItem } from "~/ui/atoms/SelectBoxAutoTypeItem";
import { LabelAutoTypeTemplate } from "~/ui/atoms/LabelAutoTypeTemplate";
import { dataSource } from "~/ui/atoms/Edit/EditStation/dataSource";
import { tireBrandsDataSource } from "~/custom-data-sources";

type Props = {
    stationsInit: ModelSto[] | null,
    selectedStation: number | boolean,
    selectedDkAutoType: React.MutableRefObject<number | null>,
    autoTypesInit: ModelAutoTypes[],

}

const cn = bemHelper('form-sign-up');


export const FormSignUp: React.FC<Props> = memo(({
    stationsInit,
    selectedStation,
    selectedDkAutoType,
    autoTypesInit
}) => {

    const [form, setForm] = useState<any>()

    const size = useWindowSize()
    const firstLoad = useRef(true)
    const history = useHistory();
    const { authState } = useContext(AuthContext);
    const { t } = useTranslation('records');

    const [ data, setData ] = useState<any>({
        lineNumber: 0,
        dkAutoType: 1,
        noRegNumber: false,
    })
    const [autoTypes, setAutoTypes] = useState<ModelAutoTypes[]>([])
    const brandList = useRef<string[]>([])
    const modelList = useRef<string[]>([])

    const getBrands = async () => {
        const result = await Vehicle.controller.getAllBrands();

        const brandEditor = form.getEditor('brand')
        
        if(brandEditor){
            brandEditor.option('dataSource', result)
        }
        brandList.current = result
    }

    const getAutoTypes = async () => {
        setAutoTypes(autoTypesInit.filter((_i: any) => stationsInit?.find(item => item.id === data.stoId)?.autoType.includes(_i.id)))
    }


    const getModels = async (brand: string) => {
        const modelEditor = form.getEditor('model')
        
        const result = await Vehicle.controller.getModelsByBrand(brand);

        
        if(modelEditor){
            modelEditor.option('dataSource', result)
        }
        modelList.current = result
    }

    const getPrice = async (dkAutoTypeId: number, ) => {
        if (dkAutoTypeId){
            const result: {
                dkAutoTypeId: number,
                dkAutoTypeName: string,
                price: number
            } = await Records.controller.getPriceSto({
                stoId: data.stoId,
                dkAutoTypeId,
                agentId: authState.userData?.agent.id
                
            })
        
            form.updateData('priceSto', result.price);
            form.updateData('priceClient', result.price);
        }
    }
    
    const getAvailableTimes = async (params: any) => {
        let result = await Sto.controller.getOrderTimes(params)

        const now = new Date()
        
        if (params.date === now.toISOString().split('T')[0]){
            result = result.filter((i) => {
                const time = new Date(i.timeStamp)
                if (now.getTime() < time.getTime()){
                    return true
                }
                return false
            })
        }
        const timeEditor = form.getEditor('dateReceiptStart')

        timeEditor.option('dataSource', result.filter((_i: any) => _i.free === true))

        if(timeEditor.option("disabled") === true){
            timeEditor.option("disabled", false);
        }

        handleDataChange({component: form})
    }

    
    const handleDataChange = (e: any) => {
        const button = form.getButton('submit')
            if(button){
                if(e.component.validate().isValid){
                    button.option('disabled', false)
                } else{
                    button.option('disabled', true)
                }
            }
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault()
        try{
             const values = {
                ...data
            }

            if(values.other){
                const brandResult = await Vehicle.controller.createBrandAndModel({
                    brand: values.brand,
                    model: values.model
                })
            }
    
            if(values.noRegNumber){
                values.regNumber = ''
            }

            if(!values.clientFio){
                values.clientFio = 'Клиент'
            }

            delete values.noRegNumber
            delete values.date
            delete values.other
            values.individual = Number(!values.individual)

            const editor = form.getEditor('clientPhone')

            values.clientPhone = data.clientPhone ? editor.option('text').replace(/\s/g, ""): ''

            const result = await Records.controller.create(values)
            
            notify({
                message: 'Запись успешно обновлена',
                position: 'top center',
              }, 'success', 3000);

            history.push('/app/records')

        } catch(err: any) {
            const status = Number(err.message.replace('(','').split(')')[0])
            let message = `Упс... Произошла ошибка ${err.message}`

            if(status === 409){
                message = `Время занято или указанно некорректно`
            }

            notify({
                message: message,
                position: 'top center',
              }, 'error', 3000);

            data.clientPhone = data.clientPhone.slice(1)
        } 


        
    }
    
    const colCountByScreen =  {
        lg: 5,
        md: 3,
        sm: 3,
        xs: 1,
    }

    const colCountClient =  {
        lg: 3,
        md: 2,
        sm: 2,
        xs: 1,
    }

    const colSpanComment =  {
        lg: 1,
        md: 4,
        sm: 2,
        xs: 1,
    }

    const EditorOptions = {

        priceClient:{
            with: '100%',
            min: 0,
            placeholder: "Стоимость, рубли",
            buttons: [priceAdorment]
        },
        priceSto:{
            with: '100%',
            min: 0,
            placeholder: "Стоимость, рубли",
            buttons: [priceAdorment]
        },
        clientFio:{
            with: '100%',
            placeholder: 'Иванов Иван Иванович',
        },
        other:{
            text:"Иное",
            iconSize: 15,
            onValueChanged: (e: any) => {

                const sourceDataBrand = form.getEditor('brand').option('dataSource')
                const sourceDataModel = form.getEditor('model').option('dataSource')
                const sourceDataTime = form.getEditor('dateReceiptStart').option('dataSource')
                const disabledRegNumber = form.getEditor('regNumber').option('disabled')
                

                if(data.noRegNumber){
                    form.getEditor('regNumber').option("disabled", true);
                }
                form.itemOption("groupAuto.brandOther", "visible", e.value);
                form.itemOption("groupAuto.modelOther", "visible", e.value);

                form.itemOption("groupAuto.brand", "visible", !e.value);
                form.itemOption("groupAuto.model", "visible", !e.value);

                form.itemOption("groupAuto.brand", "disabled", e.value);
                form.itemOption("groupAuto.model", "disabled", e.value);

                
                // После обновления восстанавливаем данные
                form.getEditor('regNumber').option('disabled', disabledRegNumber)

                if(data.brand){
                    form.getEditor('model').option("disabled", false);
                }

                if(data.date){
                    form.getEditor('dateReceiptStart').option("disabled", false);
                }

                form.getEditor('dateReceiptStart').option('dataSource', sourceDataTime)
                form.getEditor('brand').option('dataSource', sourceDataBrand)
                form.getEditor('model').option('dataSource', sourceDataModel)

                
                handleDataChange({component: form})
                
            },
            
        },
        brand: {
            with: '100%',
            searchEnabled: true,
            dataSource: brandList.current,
            onValueChanged: ({value}:any) => {
                const modelEditor = form.getEditor('model')
                if(modelEditor.option("disabled") === true){
                    modelEditor.option("disabled", false);
                }
                handleDataChange({component: form})
                getModels(value)
            }
        },
        model: {
            disabled: !data.brand,
            with: '100%',
            searchEnabled: true,
            dataSource: modelList.current,
        },
        dkAutoTypeSto: {
            with: '100%',
            displayExpr: 'displayName',
            valueExpr: 'id',
            dataSource: autoTypes,
            defaultValue: data.dkAutoType,
            itemRender: SelectBoxAutoTypeItem,
            dropDownOptions: {
                wrapperAttr :{
                    class: 'select-auto-type'
                }
            },
            onValueChanged: async (e: any) => {
                
                form.updateData('dkAutoType', e.value)
                getPrice(data.dkAutoType)
                selectedDkAutoType.current = e.value
            }
            
        },
        email: {
            placeholder: 'ivanov@mail.ru',
            with: '100%'
        },
        vin: {
            with: '100%',
            mask: 'XXXXXXXXXXXXXXXXX', 
            maskChar: '_',
            maskRules: { X: /[0-9A-Za-z]/ },
            valueChangeEvent: 'input',
            onInput: (e:any) => {
                const string: string = e.component.option('value')
                e.component.option('value', string.toLocaleUpperCase())  
            }
        },
        regNumber: {
            minLength: 8,
            maxLength: 9,
            mask: 'XXXXXXXXK',
            maskRules: {
                X: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9]/,
                K: /[АаВвЕеКкМмНнОоРрСсТтУуХх0-9\s]/
            },
            maskChar: '_',
            placeholder: 'А123МН78',
            useMaskedValue: true,
            maskInvalidMessage: "8 или 9 символов. Можно вводить только русские буквы (А,В,Е,К,М,Н,О,Р,С,Т,У,X) и цифры.",
            with: '100%',
            
        },
        noRegNumber:{
            text:"без номера",
            iconSize: 15,

            onValueChanged: (e: any) => {
                const sourceDataBrand = form.getEditor('brand').option('dataSource')
                const sourceDataModel = form.getEditor('model').option('dataSource')
                const sourceDataTime = form.getEditor('dateReceiptStart').option('dataSource')
                
                form.itemOption('groupAuto.brand', 'isRequired', e.value)
                form.itemOption('groupAuto.model', 'isRequired', e.value)
                form.itemOption('groupAuto.brandOther', 'isRequired', e.value)
                form.itemOption('groupAuto.modelOther', 'isRequired', e.value)
                
                const editorRegNumber = form.getEditor('regNumber')
                editorRegNumber.option('disabled', e.value)
                
                
                handleDataChange({component: form})
                
                if(data.brand){
                    form.getEditor('model').option("disabled", false);
                }

                // После обновления восстанавливаем данные

                form.getEditor('dateReceiptStart').option('dataSource', sourceDataTime)
                form.getEditor('brand').option('dataSource', sourceDataBrand)   
                form.getEditor('model').option('dataSource', sourceDataModel)
                
            },
        },
        phone: {
            with: '100%',
            mask: '+7 (X00) 000-00-00', 
            maskChar: '_',
            maskRules: { X: /[02-9]/ },
        },
        tireBrand: {
            with: '100%',
            displayExpr: 'name',
            valueExpr: 'id',
            dataSource: tireBrandsDataSource,
            searchEnabled: true,
        },
        mileage: {
            with: '100%',
            min: 0,
            max: 10000000,
            placeholder: "Пробег, км",
            buttons: [mileageAdorment]
        },
        date: {
            with: '100%',
            min: new Date(),
            useMaskBehavior: true,
            onValueChanged: ({value}: {value: Date}) => {
                getAvailableTimes({
                    date: value.toLocaleDateString('sv'),
                    stoId: data.stoId,
                    dkAutoTypeId: data.dkAutoType
                })
            }
        },
        comment: {
            with: '100%',
        },

        time: {
            with: '100%',
            displayExpr: 'name',
            valueExpr: 'timeStamp',
            dataSource: form?.getEditor('dateReceiptStart').option('dataSource'),
            disabled: data.date ? false: true,
        },
        inn: {
            with: '100%',
        },
        companyName: {
            with: '100%',
        },
        individual: {
            onValueChanged: ({value}:any) => {
                form.itemOption("groupClient.companyName", "visible", value);
                form.itemOption("groupClient.inn", "visible", value);
                handleDataChange({component: form})
            }
        },
        
        additional: {
            collapsible: true,
            multiple: true,
            onInitialized: (e: any) => e.component?.option('selectedIndex', -1),
            animationDuration: 300,
            dataSource: [{
                title: 'Дополнительно',
                
            }],
            itemComponent: (e:any) => {
                
                return(
                    <div style={{marginBottom: 20}}>
                    <Form 
                        id="form-stations-additions" 
                        formData={data}
                        onContentReady={e => e.component.validate()}
                        colCountByScreen={colCountByScreen}>
                            <Item
                                dataField="vin" 
                                label={{text: t('vin')}} 
                                editorOptions={EditorOptions.vin} 
                                />
                            <Item
                                dataField="tireBrand" 
                                label={{text: t('tireBrand')}} 
                                editorOptions={EditorOptions.tireBrand} 
                                editorType="dxSelectBox"
                                />
                            <Item
                                dataField="mileage" 
                                label={{text: t('mileage')}}
                                editorOptions={EditorOptions.mileage}
                                editorType="dxNumberBox"
                                />
                            <Item 
                                dataField="clientEmail" 
                                label={{text: t('clientEmail')}} 
                                editorOptions={EditorOptions.email}
                                validationRules={validationRules.email}
                                />
                            <Item 
                                dataField="comment" 
                                label={{text: t('comment')}} 
                                colSpan={colSpanItem(size, colSpanComment)} 
                                editorOptions={EditorOptions.comment}
                                />
                    </Form>
                    </div>
                )
            },
            onItemClick: ({event}:any)=>{
                const el = document.getElementsByClassName('fade-in-route')[0]
                const elScroll = document.getElementsByClassName('internal-layout__content')[0]
                
                const observer = new ResizeObserver(function() {
                    elScroll.scrollTo(0, el.scrollHeight)
                });

                observer.observe(el)
                
                setTimeout(() => {
                    observer.unobserve(el)
                }, 2000)
            }
        }
    }

    useEffect(() => {

        if (selectedDkAutoType.current) {
            data.dkAutoType = selectedDkAutoType.current
        } else {
            selectedDkAutoType.current = data.dkAutoType
        }

    }, [])

    useEffect(() => {
        data.stoId = selectedStation
        if(form){
            getAutoTypes()
        }
        
    }, [selectedStation, form])
    
    useEffect(() => {
        if(form){
            getBrands()
            
            
            getPrice(data.dkAutoType)
        }
    }, [form])

    useEffect(() => {
        firstLoad.current = false
    })
    
    
    return (
        <div className={cn('fade-in') }> 

                <form onSubmit={handleSubmit}>
                    <Form
                        className={cn('form')}
                        onContentReady={e => e.component.validate()}
                        formData={data}
                        onInitialized={(e:any ) => {setForm(e.component)}}
                        onFieldDataChanged={handleDataChange}
                        id="form-stations">

                        <GroupItem 
                            caption="Информация о ТС" 
                            name={'groupAuto'}
                            colCountByScreen={colCountByScreen}
                            cssClass={'form-group'}>
                            
                            <Item
                                dataField="dkAutoType" 
                                isRequired
                            >
                                <Label render={() => <LabelAutoTypeTemplate dataField={'dkAutoType'} autoTypes={autoTypesInit}/>}/>
                                <SelectBox
                                    {...EditorOptions.dkAutoTypeSto}
                                    />
                            </Item>
                            
                            <GroupItem
                                cssClass={cn('group-regNumber')}
                                >
                                <Item
                                    dataField="noRegNumber" 
                                    label={{visible: false}} 
                                    editorType="dxCheckBox"
                                    cssClass={cn('no-regNumber')}
                                    editorOptions={EditorOptions.noRegNumber}
                                />
                                <Item
                                    dataField="regNumber" 
                                    label={{text: t('regNumber')}} 
                                    editorOptions={EditorOptions.regNumber}
                                    isRequired
                                />
                            </GroupItem>

                            <Item 
                                dataField="brand" 
                                colSpan={1}
                                label={{text: t('brand')}} 
                                editorOptions={EditorOptions.brand} 
                                editorType="dxSelectBox"
                            />

                            <Item
                                dataField="model" 
                                label={{text: t('model')}} 
                                editorOptions={EditorOptions.model} 
                                editorType="dxSelectBox"
                            />
                            
                            <Item 
                                dataField="brand" 
                                visible={false}
                                name="brandOther"
                                label={{text: t('brand')}}
                                />
                            <Item
                                dataField="model" 
                                visible={false}
                                label={{text: t('model')}} 
                                name="modelOther"
                            />
                            <Item 
                                    dataField="other" 
                                    colSpan={1}
                                    cssClass={cn('checkbox-other')}
                                    label={{visible: false}} 
                                    editorOptions={EditorOptions.other} 
                                    editorType="dxCheckBox"
                                />
                        </GroupItem>

                        <GroupItem 
                            colCountByScreen={colCountByScreen}
                            cssClass={'form-group'}
                        >
                        
                        <Item 
                            dataField="date" 
                            label={{text: t('date')}}  
                            editorType="dxDateBox" 
                            editorOptions={EditorOptions.date}
                            isRequired
                            />

                        <Item 
                            dataField="dateReceiptStart" 
                            label={{text: t('time')}} 
                            editorOptions={EditorOptions.time} 
                            editorType="dxSelectBox"
                            isRequired
                            />
                        <Item
                            dataField="priceClient" 
                            label={{text: t('priceClient')}} 
                            editorOptions={EditorOptions.priceClient}
                            editorType="dxNumberBox"
                            />
                        <Item
                            dataField="priceSto" 
                            label={{text: t('priceSto')}} 
                            editorOptions={EditorOptions.priceSto}
                            editorType="dxNumberBox"
                            disabled={true}
                            />
                        
                        </GroupItem>

                        <GroupItem 
                            name={'groupClient'}
                            colCountByScreen={colCountClient}
                            caption={"Клиент (никуда не передается)"}
                            cssClass={'form-group form-group-individual-top'}>

                            <Item 
                                dataField="individual" 
                                label={{
                                    text: `${t('entity')}:`,
                                    location: 'right'
                                }} 
                                colSpan={colSpanItem(size, colCountClient)}
                                cssClass={" individual-switch"}
                                editorOptions={EditorOptions.individual}
                                editorType="dxCheckBox"
                                />  
                            <Item 
                                dataField="clientFio" 
                                label={{text: t('clientFio')+' (для печати в направлении)'}} 
                                editorOptions={EditorOptions.clientFio} 
                                validationRules={[
                                    {
                                        type: 'pattern',
                                        pattern: /^[\sа-яА-ЯёЁ]*$/,
                                        message: 'Можно использовать только русские буквы'
                                    },
                                ]}
                                />
                            
                            <Item 
                                dataField="clientPhone" 
                                label={{text: t('clientPhone')}} 
                                editorOptions={EditorOptions.phone} 
                                />
                        
                            <Item 
                                dataField="companyName" 
                                label={{text: t('companyName')}} 
                                visible={false}
                                editorOptions={EditorOptions.companyName} 
                                />
                         
                            <Item 
                                dataField="inn" 
                                label={{text: t('inn')}} 
                                validationRules={validationRules.inn}
                                visible={false}
                                editorOptions={EditorOptions.inn}
                                />
                            
                        </GroupItem>

                        <Item>
                            <Accordion
                                {...EditorOptions.additional}
                                />
                        </Item>

                        <ButtonItem
                            colSpan={colSpanItem(size, colCountByScreen)}
                            name='submit'
                            buttonOptions={{
                                type: 'default',
                                text: 'Записать',
                                disabled: true,
                                useSubmitBehavior: true,
                            }}
                            />
                    </Form>
                </form>
            </div>
    )
})