import React, { FC } from "react"
import { bemHelper } from "~/libs"


type Props = {
    error: string
}

export const PageError: FC<Props> = ({
    error
}) => {

    const cn = bemHelper('page-error')

    return (
        <div className={cn('error')}>
            <h1>Произошла ошибка {error}</h1>
        </div>
    )
}