import { Common } from '~/types';
import { ky } from '~/api/kyInstance';
import { Addressing } from '~/api';


export namespace Auth {
  export type LogInParams = {
    credentials: { login: string; password: string };
  };

  export type UserPermissionDto = {
    description: string;
    id: Common.Permissions;
    shortId: string;
  };

  export type UserAgent = {
    id: number,
    name: string,
    address: string,
    email: string,
    phone: string
  };

  export type UserStaff = {
    id: number
    firstname: string,
    lastname: string,
    fathername: string | null,
    position: string,
    login: string,
    email: string | null,
    phoneMobile: string | null,
    waNumber: string | null,
    phoneJob: string | null,
    agentId: number[],
  };

  export type Model = {
    expiresAt: number;
    subject: string;
    token: string;
  };

  export type companyList = {
    name: string,
    address: string,
    agentId: number,
    stoId: number
  }
  
  export type ModelAuth = {
    companyShortDescriptionList: companyList[],
    token: string,
    roles: Array<Common.Permissions | Common.SubPermissions>
  };

  class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getUserStaff(): Promise<UserStaff> {

        return ky.get('api/staff').json();
    }

    public getUserAgent(): Promise<UserAgent> {
      
      return ky.get('api/agent').json();
    }

    public changeStaffData(values: Partial<UserStaff>): Promise<UserAgent> {
      
      return ky.patch('api/staff', {json: values}).json();
    }

    public changePass(password: string) {
      
      return ky.post('api/user/changePassword', {json: {password}});
    }

    public logIn(): Promise<ModelAuth> {
      return ky.post('api/auth').json();
    }

    public logOut(login: string): Promise<string> {

      return ky.post('api/logout',{
        searchParams: [
          ['login', login],
        ],
      }).json();
    }
    
  }

  export const controller = new Controller('');
}
