import { DataGrid, Form } from "devextreme-react";
import { ElementRef } from "react";

export namespace Common {

  export type FormRef = ElementRef<typeof Form>
  export type DataGridRef = ElementRef<typeof DataGrid>

  export type itemLink = {
    id: number;
    to: string;
    label: string;
    icon: string;
    permissions?: (Common.Permissions | Common.SubPermissions)[];
  }

  export type NavGroupLink = {
    disabled?: boolean;
    label: string;
    to?: string;
    addButton?: boolean;
    onClickAddButton?: () => void;
    icon?: string;
    permissions: (Common.Permissions | Common.SubPermissions)[] | 'All';
    items?: itemLink[]
  };

  export enum Permissions {
    ROLE_STO_ADMINISTRATOR = "ROLE_STO_ADMINISTRATOR",
    ROLE_STO_OPERATOR = 'ROLE_STO_OPERATOR',
    ROLE_AGENT = 'ROLE_AGENT',
  }

  export enum SubPermissions {
    ROLE_BROKER_DIRECTOR = "BROKER_DIRECTOR",
    ROLE_STO_DIRECTOR = "ROLE_STO_DIRECTOR",
    ROLE_ADMIN = "ROLE_ADMIN",
  }
 
  export enum TypePopup {
    CLIENT_INDIVIDUAL = 'CLIENT_INDIVIDUAL',
    CLIENT_ENTITY = 'CLIENT_ENTITY',
    RECORD = 'RECORD',
    VEHICLE = 'VEHICLE',
    STATION = 'STATION',
    AGENT = 'AGENT'
  }
  export enum TypeWsMessage {
    FOR_ALL = 'FOR_ALL',
    PRIVATE = "PRIVATE"
  }
}
