// import { Common } from '~/types';
import EditRecord from './EditRecord';
import { EditVehicle } from './EditVehicle';
import { EditClientIndividual } from './EditClientIndividual/index';
import { EditClientEntity } from './EditClientEntity';
import EditStation from './EditStation';
import { EditAgent } from './EditAgent';

export const ROUTES = [

  {
    Component: EditClientIndividual,
    permissionComponent: 'All',
    exact: true,
    path: '/app/client/individual/:urlId',
  },
  {
    Component: EditClientEntity,
    permissionComponent: 'All',
    exact: true,
    path: '/app/client/entity/:urlId',
  },
  {
    Component: EditVehicle,
    permissionComponent: 'All',
    exact: true,
    path: '/app/vehicle/:urlId',
  },
  {
    Component: EditRecord,
    permissionComponent: 'All',
    exact: true,
    path: '/app/record/:urlId',
  },
  {
    Component: EditStation,
    permissionComponent: 'All',
    exact: true,
    path: '/app/station/:urlId',
  },
  {
    Component: EditAgent,
    permissionComponent: 'All',
    exact: true,
    path: '/app/agent/:urlId',
  },
];
