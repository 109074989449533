import { Common } from '~/types';
import { ky } from '~/api/kyInstance';
import { AuthContext } from '~/context';
import { useContext } from 'react';


export type AgentModel = {
  id?: number,
  name?: string,
  address?: string,
  email?: string,
  phone?: string,
  comment?: string,
};

export namespace Agent {

  class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }


    public getAgentById(id:number): Promise<AgentModel> {
      
      return ky.get(`api/agent/${id}`).json();
    }

    public updateAgent(values: Partial<AgentModel>): Promise<AgentModel> {
      
      return ky.patch(`api/agent`, {json: values}).json();
    }
  }

  export const controller = new Controller('');
}
