import React, { memo, useContext, useEffect, useState } from 'react';
import { authAgentIdStorage, authStoIdStorage, bemHelper, initialLocationStorage } from '~/libs';
import './styles.scss'
import { useTranslation } from 'react-i18next';
import { AuthContext } from '~/context';
import { useHistory } from 'react-router';
import { Agent, Auth, Sto } from '~/api';
import { Common } from '~/types';
import { Loader } from '~/ui/atoms';
import { LoadIndicator } from 'devextreme-react';

type PropsItem = {
    item: Auth.companyList,
    onClick: (item: Auth.companyList) => void
};

const cn = bemHelper('profile-list');

const Item: React.FC<PropsItem> = memo(({item, onClick}) => {

    return (
        <div className={cn('item')} onClick={() => onClick(item)}>
            <h4 className={cn('item-title')} title={item.name}>
                {item.name}
            </h4>
            <p className={cn('item-subtitle')} title={item.address}>
                {item.address}
            </p>
        </div>
    )
})

export const ProfilesList: React.FC = memo((props) => {

    const { t, ready: i18nReady } = useTranslation('select-profile', {
        useSuspense: false,
    });

    const {
        authState: { 
          userData,
        },
        renewToken,
      } = useContext(AuthContext);
    
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ profiles, setProfiles ] = useState<Auth.companyList[]>([])
    const [ firstLoad, setFirstLoad ] = useState<boolean>(false)

    const onClick = async (item: Auth.companyList) => {
        setIsLoading(true)
        authStoIdStorage.store(String(item.stoId))
        authAgentIdStorage.store(String(item.agentId))
        const isAuthorized = await renewToken()
        setIsLoading(false)
        if(isAuthorized){
            const initialLocation = initialLocationStorage.get();
            initialLocationStorage.remove();
            history.push(initialLocation || '/app/records')
        }
        
    }   

    useEffect(() => {
        if(userData && !firstLoad){
            setProfiles(userData.companyShortDescriptionList)
            setFirstLoad(true)
        }
    }, [userData])

    return (
        <div className={cn('layout')}>
            { isLoading && (
                <div className={cn('loader-box')}>
                    <div className={cn('loader')}>
                        <LoadIndicator />
                    </div>
                </div>
            )}
            <div className={cn('box')}>
                <h3 className={cn('title')}>{t('selectProfile')}</h3>
                <div className={cn('box-list')}>
                    {profiles.length > 0 ? (
                        <>
                            {profiles.map((i)=>(
                                <Item key={`agentId${i.agentId}stoId${i.stoId}`} item={i} onClick={onClick}/>
                            ))}
                        </>
                    ) : (
                        <Loader centered/>
                    ) }
                </div>
            </div>
        </div>
    )
})