import { ky } from '~/api/kyInstance';


export type Model = {
  dkId: number;
  uid: string;
  vehicleId: number;
  brand: string;
  model: string;
  regNumber: string;
  dkAutoTypeId: number;
  dkAutoTypeName: string;
  dkAutoTypeDescription: string;
  dkAutoTypeDuration: number;
  stoNameShort: string;
  clientId: number;
  clientFio: string;
  clientPhone: string;
  clientEmail: string;
  clientCategoryId: number;
  clientCategory: string;
  stoId: number;
  stoName: string;
  mileage: number;
  dateCreated: string;
  dateReceiptStart: string;
  dateReceiptFinish: string;
  dateCheck: string;
  priceSto: number;
  priceClient: number;
  approvedStatusId: number;
  approvedStatusName: string;
  approvedStatusColor: string;
  validity: number;
  agentId: number;
  agentName: string;
  staffId: number;
  staffFio: string;
  lineNumber: number;
  comment: string;
  individual: boolean;
  textExpr?: string
};

export type ModelPrice = {
  sto_id: number ,
  agent_id: number,
  m1: number | null,
  m2: number | null,
  m3: number | null,
  n1: number | null,
  n2: number | null,
  n3: number | null,
  o1: number | null,
  o2: number | null,
  o3: number | null,
  o4: number | null,
  l: number | null
}

export type ModelById = {
  id: number;
  uid: string;
  vehicleId: number;
  brand: string;
  model: string;
  regNumber: string;
  dkAutoTypeId: number;
  dkAutoTypeName: string;
  dkAutoTypeDescription: string;
  dkAutoTypeDuration: number;
  clientId: number;
  clientFio: string;
  clientPhone: string;
  clientEmail: string;
  clientCategoryId: number;
  clientCategory: string;
  stoId: number;
  stoName: string;
  stoNameShort: string;
  individual: boolean;
  mileage: number;
  dateCreated: string;
  tireBrandId:number;
  dateReceiptStart: string;
  dateReceiptFinish: string;
  dateCheck: string;
  priceSto: number;
  priceClient: number;
  approvedStatusId: number;
  approvedStatusName: string;
  approvedStatusColor: string;
  validity: number;
  agentId: number;
  agentName: string;
  staffId: number;
  staffFio: string;
  lineNumber: number;
  comment: string;
};

export type Status = {
  id: number,
  name: string,
  comment: string,
  color?: string
};

export type History = {
  actionTimestamp: string,
  action: string,
  staffFio: string,
}

export namespace Records {
  

  class Controller {
    readonly url: string;

    constructor(url: string) {
      this.url = url;
    }

    public getAll(): Promise<Model[]> {
      return ky.get(`${this.url}/getAll`).json();
    }

    public getById(id: number|undefined): Promise<ModelById> {
      return ky.get(`${this.url}/${id}`).json();
    }

    public create(values: Partial<Model>): Promise<any> {
      return ky.post(`${this.url}/addByAllParams`, { json: values }).json();
    }

    public update(values: Partial<Model>): Promise<Model> {
      return ky.patch(`${this.url}`, { json: values }).json();
    }

    public remove(id: any): Promise<any>{
      return ky.delete(`${this.url}/${id}`);
    }

    public changeStatus(dkId: number, approvedStatusId: number) {
      return ky.post(`${this.url}/changeApprovedStatus`, { json: {dkId, approvedStatusId} });
    }

    public getAllStatus(): Promise<Status[]> {
      return ky.get(`${this.url}/approvedStatus`).json();
    }

    public getHistoryById(id: number): Promise<History[]> {
      return ky.get(`${this.url}/history/${id}`).json();
    }

    public downloadDkPdf(dkId: number): Promise<Blob> {
      return ky.get(`${this.url}/downloadDkPdf/${dkId}`).blob();
    }

    public downloadReferralToSto(dkId: number): Promise<Blob> {
      return ky.get(`${this.url}/getReferralToSto/${dkId}`).blob();
    }

    public uploadDkPdf(file: File, dkId: number): Promise<any> {
      var formData = new FormData()

      formData.append('file', file)
      formData.append('dkId', String(dkId))

      return ky.post(`${this.url}/uploadDkPdf`, { 

        body: formData,

        
       });
    }

    public getPriceSto(values: any): Promise<any> {
      return ky.get(`${this.url}/stoPrice`, {
        searchParams: [
          ['stoId', values.stoId],
          ['agentId', values.agentId],
          ['dkAutoTypeId', values.dkAutoTypeId],
        ],
      }).json();
    }

    public getPriceAll(
      values: {
        stoId: number,
        agentId: number
      }
      ): Promise<any> {
      return ky.get(`${this.url}/stoPriceAll`, {
        searchParams: [
          ['stoId', values.stoId],
          ['agentId', values.agentId],
        ],
      }).json();
    }

    public getPricesAllSto(
      listStoId: number[]
      ): Promise<ModelPrice[]> {
      return ky.post(`${this.url}/stoPriceAll`, {
        json: {stoIdList: listStoId}
      }).json();
    }

    public patchPriceSto(values: any): Promise<any> {
      return ky.patch(`${this.url}/stoPrice`, { json: values }).json();
    }

    public addBlock(values: any): Promise<any> {
      return ky.post(`${this.url}/addBlock`, { json: values });
    }

  }

  export const controller = new Controller('api/dk');
}
