import { Common } from "~/types";

type RequiredPerm = (Common.Permissions | Common.SubPermissions)[] | Common.Permissions | Common.SubPermissions | string

export const checkPermission = (roles: string[] | undefined, requiredPerm: RequiredPerm | undefined): boolean => {
    if(roles && requiredPerm){
        if(typeof requiredPerm == 'string'){
            if(requiredPerm.toLocaleLowerCase() == "all"){
                return true
            }

            return roles?.includes(requiredPerm)
        } else {
            for(let i = 0; i < requiredPerm.length; i++){
                if(roles.includes(requiredPerm[i])){
                    return true
                }
            }

        }
    }

    return false
}