import CustomStore from 'devextreme/data/custom_store';
import React, { memo, useContext, useEffect, useState } from 'react'
import { Sto } from '~/api';
import ReportBase, { PropsComponet } from '~/ui/atoms/ReportBase';
import { AuthContext } from '~/context';
import './styles.scss'
import { bemHelper } from '~/libs';
import ProfitChart from './atoms/ProfitChart';
import { getCountSources, getPassedByAutoType, getPassedByStatus, getPassedBySto, getRatioWorkTime } from './services';
import CustomPieChart from '~/ui/atoms/CustomPieChart';
import { Common } from '~/types';
import CustomChart from '~/ui/atoms/CustomChart';
import notify from 'devextreme/ui/notify';
import TodayPassedPieChart from './atoms/TodayPassedPieChart';
import { checkPermission } from '~/utils/checkPermission';




const OperatorComponent: React.FC<PropsComponet> = memo(({data, date, days}) => {

    const { authState } = useContext(AuthContext);
    const cn = bemHelper('statistics');
    
    const [countSources, setCountSources ] = useState<any[]>([])

    const [ratioWorkTime,  setRatioWorkTime] = useState<any[]>([])

    const [passedByAutoType, setPassedByAutoType] = useState<any[]>([])

    useEffect(() => {
        setCountSources(getCountSources(data))
        setRatioWorkTime(getRatioWorkTime(data, authState.userData?.sto, date, days))
        setPassedByAutoType(getPassedByAutoType(data))

        
    }, [data])
    
    
    return (
        <div className={cn('')} >
            <CustomPieChart
                type="doughnut"
                palette="Material"
                data={countSources}
                title="Соотношение источников записей"
                />
        
            <CustomPieChart  
                    palette="Bright"
                    data={ratioWorkTime}
                    сustomColor
                    percentages
                    title="Соотношение времени"
                    />

            

            <CustomPieChart
                    palette="Dark Violet"
                    data={passedByAutoType}
                    type="doughnut"
                    title="Соотношение категорий ТС прошедших ТО"
                    />

            <ProfitChart
                data={data}
                days={days}
                date={date}
            />
            
            
        </div>
    )
})


const AgentComponent: React.FC<PropsComponet> = memo(({data, date, days}) => {

    const { authState } = useContext(AuthContext);
    const cn = bemHelper('statistics');
    
    const [passedByStatus, setPassedByStatus ] = useState<any[]>([])
    const [passedBySto, setPassedBySto ] = useState<any[]>([[],[]])
    const [passedByAutoType, setPassedByAutoType] = useState<any[]>([])


    useEffect(() => {
        setPassedByStatus(getPassedByStatus(data))
        setPassedBySto(getPassedBySto(data))
        setPassedByAutoType(getPassedByAutoType(data))
    }, [data])

    
    
    return (
        <div className={cn('')} >
            <CustomPieChart
                type="doughnut"
                palette="Material"
                data={passedByStatus}
                title="Соотношение по статусу"
                сustomColor
                />

            <CustomPieChart
                palette="Dark Violet"
                data={passedByAutoType}
                type="doughnut"
                title="Соотношение категорий ТС прошедших ТО"
                />

            <TodayPassedPieChart/>

            <CustomChart
                data={passedBySto[0]}
                title='Соотношение кол-ва записей по СТО'
                manySeries
                argumentField='name'
                listSeries={passedBySto[1]}
                legend
                colors={passedBySto[2]}
            />

            
        </div>
    )
})




export const MainStatistics: React.FC = () => {
    const dataSource = new CustomStore({
        key: 'dkId',
        load: async (loadOptions) => {

            const data = await Sto.controller.getOrderInfo({
                date: loadOptions.searchValue.date,
                days: loadOptions.searchValue.days
            }); 

            return data;
        },
        errorHandler: (error: any) => {
            notify({
              message: `Упс... Произошла ошибка ${error.message}`,
              position: 'top center',
            }, 'error', 3000);
          },
        
    })

    const { authState: {
        userData
    } } = useContext(AuthContext);

    let Component = AgentComponent

    if (userData?.roles.includes(Common.Permissions.ROLE_AGENT)){
        Component = AgentComponent
    }else{
        Component = OperatorComponent
    }

    
    return (
    <ReportBase 
        Component={Component} 
        title='Статистика' 
        dataSource={dataSource}
        startFromMonday
        isStatistics
        />
    )

}
                

                
           