import React, { memo, useRef } from 'react'

import {
    Chart as Chart, 
    Series as SeriesChart, 
    CommonSeriesSettings as CommonSeriesSettingsChart, 
    Label as LabelChart, 
    Format as FormatChart, 
    Legend as LegendChart, 
    Export as ExportChart,
    Size as SizeChart,
} from 'devextreme-react/chart';

import './styles.scss'
import { bemHelper } from '~/libs';

type PropsChart = {
    data: any[]
    title: string,
    seriesName?: string,
    manySeries?: boolean,
    valueField?: string,
    argumentField: string,
    listSeries?: string[],
    isRuble?: boolean,
    legend?: boolean,
    colors?: any,
}

const CustomChart: React.FC<PropsChart> = memo(({data, title, seriesName, manySeries=false, listSeries, valueField, argumentField, colors,isRuble=false, legend=false}) => {

    const cn = bemHelper('chart');

    const formatPrice = (value: number) => {
        return new Intl.NumberFormat('ru-RU').format(value)
    }

    const customizeText = (arg: any) => {
        return `${formatPrice(arg.valueText)} ₽`
    }

    const propsLabelChart: any ={}


    if(isRuble){
        propsLabelChart.customizeText = customizeText
    }

    return (
        <div style={{width: '100%', position:'relative'}}>
            {data.length === 0 && (
                <div className={cn('no-data')}>
                    <h4>Нет данных</h4>
                </div>
            )}
            <Chart
                title={title}
                dataSource={data}
                >
                <CommonSeriesSettingsChart
                    argumentField={argumentField}
                    type="bar"
                    ignoreEmptyPoints={true}
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                    >
                    <LabelChart visible={true} {...propsLabelChart}>
                    </LabelChart>
                </CommonSeriesSettingsChart>

                {manySeries ? listSeries?.map((_i: any) => (
                        <SeriesChart
                            key={_i.valueField}
                            valueField={_i.valueField}
                            name={_i.seriesName}
                            color={colors[_i.valueField]}
                            />
                )
                ) : (
                    <SeriesChart
                        valueField={valueField}
                        name={seriesName}
                        />
                )}
                
                

                
                <LegendChart visible={legend} verticalAlignment="bottom" horizontalAlignment="center" />
            </Chart>
            
        </div>
    )
})

export default CustomChart
