import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';
import './styles.scss'
import { bemHelper } from '../../../libs/bem-helper/index';
import CustomStore from 'devextreme/data/custom_store';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { Model } from '~/api/records';
import e from 'express';
import PopupEdit from '../PopupEdit';
import { AuthContext, PopupContext } from '~/context';
import { CheckBox } from 'devextreme-react/check-box';
import { Common } from '~/types';
import { filterByPartner, filterByStoOrStaff, getMount, getWeek, getNextWeek, getPreviousWeek, getPreviousMount } from './services';
import { useLocation } from 'react-router';
import { Partner, PartnerModel } from '~/api/partner';
import { checkPermission } from '~/utils/checkPermission';
import { partnersDataSource } from '~/custom-data-sources';


export type PropsComponet = {
    data: Model[],
    days: number,
    date: Date,
    title?: string,
    activeRecord?: boolean,
    allSto?: boolean,
    updateData: () => void
    valuesPeriod: {
        dateStart: Date,
        dateFinish: Date,
        period: number
    }
}

type Props = {
    Component: React.FC<PropsComponet>,
    title: string,
    dataSource: CustomStore,
    startFromMonday?: boolean,
    onlyDayAndWeek?: boolean,
    activeRecord?: boolean,
    isFinantial?: boolean, 
    isStatistics?: boolean,
}


const ReportBase: React.FC<Props> = memo(({Component, title, dataSource, startFromMonday=false, onlyDayAndWeek=false, isFinantial=false, activeRecord=false, isStatistics=false}) => {

    const cn = bemHelper('report-base');
    const { authState: {userData} } = useContext(AuthContext);
    const location = useLocation()
    const { 
        editPopupOpen
     } = useContext(PopupContext);

    const [ openPeriod, setOpenPeriod ] = useState(false)
    const [ allStaff, setAllStaff ] = useState<boolean>(true)
    const [ partners, setPartners ] = useState<PartnerModel[]>([])
    const [ partnerValue, setPartnerValue ] = useState<number>(0)
    const [ withSubAgent, setWithSubAgent ] = useState<boolean>(false)
    const [ allSto, setAllSto ] = useState<boolean>(
        checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR) && 
        (userData?.companyShortDescriptionList.length || 0) > 1 ? 
        true : false)

    const [ data, setData ] = useState<Model[]>([])
    const [ initialData, setInitialData ] = useState<Model[]>([])
    const [ dateValid, setDateValid ] = useState(true)
    const [ isLoading, setIsLoading ] = useState(true)

    const { firstday, lastday } = useMemo(() => {
        if(startFromMonday){
            return getWeek()
        }
        return {
            firstday: new Date(), 
            lastday: new Date()
        }
    }, [startFromMonday])

    const [ values, setValues ] = useState<any>({
        dateStart: firstday,
        dateFinish: lastday,
        period: startFromMonday ? 7 : 1
    })

    const partnersDict = useMemo(() => {
        const dict: Record<number, PartnerModel> = {}
        partners.map(partner => {
            dict[partner.id] = partner
        })
        return dict
    }, [partners])

    const exportTitle = useMemo(() => {
        if(partnerValue != 0){
            return [title, partnersDict[partnerValue].name].join(' ')
        }

        return title
    }, [title, partnerValue])

    const items = useMemo(() => {
        const temp = [
            {
                title: 'Сегодня',
                value: 1
            },
            {
                title: 'Текущая неделя',
                value: 7
            },
            {
                title: 'Следующая неделя',
                value: 14
            },
            {
                title: 'Прошлая неделя',
                value: -7
            },
            
            {
                title: 'Текущий месяц',
                value: 30
            },
            {
                title: 'Прошлый месяц',
                value: -30
            },
        ]

        if(userData?.roles.includes(Common.Permissions.ROLE_AGENT)){
            temp.push({
                title: 'Только активные',
                value: 2
            })
        }
        temp.push({
            title: 'За период',
            value: 0
        },)

        return temp
    }, [])
    
    const handleChangeSelectBox = (e: any) => {  
        if (e.value === 0){
            setOpenPeriod(true)
        } else {
            if (openPeriod){
                setOpenPeriod(false)
            }

            switch (e.value){
                case 2:
                    values.dateStart = new Date()
                    values.dateFinish = new Date()
                    values.dateFinish.setDate(values.dateFinish.getDate() - 1)
                    break
                case 1:
                    values.dateStart = new Date()
                    values.dateFinish = new Date()
                    break
                case 0:
                    values.dateStart = new Date()
                    values.dateFinish = new Date()
                    break
                case 7:
                    var {firstday, lastday} = getWeek()
                    values.dateStart = firstday
                    values.dateFinish = lastday
                    break
                case -7:
                    var {firstday, lastday} = getPreviousWeek()
                    values.dateStart = firstday
                    values.dateFinish = lastday
                    break
                case 14:
                    var {firstday, lastday} = getNextWeek()
                    values.dateStart = firstday
                    values.dateFinish = lastday
                    break
                case 30:
                    var {firstday, lastday} = getMount()
                    values.dateStart = firstday
                    values.dateFinish = lastday
                    break
                case -30:
                    var {firstday, lastday} = getPreviousMount()
                    values.dateStart = firstday
                    values.dateFinish = lastday
                    break
                default:
                    break
        }
            getData({
                days: (values.dateFinish.getTime() - values.dateStart.getTime())/ (1000 * 60 * 60 * 24) + 1,
                date: values.dateStart.toLocaleDateString('sv')
                    
            })
        }

        setValues({
            ...values,
            [e.component.option('name')]: e.value
        })
        location.state = {
            ...location.state,
            periodValues: {
                ...values,
                [e.component.option('name')]: e.value
            }
        }
        
    }       

    const handleChangeDateBox = (e: any) => {  
        const newValues ={
            ...values,
            [e.component.option('name')]: e.value
        }
        const days = Math.floor((newValues.dateFinish.getTime() - newValues.dateStart.getTime())/ (1000 * 60 * 60 * 24) + 1)

        if (days <= 0) {
            setDateValid(false)
        } else {
            setDateValid(true)
        }

        setValues(newValues)
    }       

    const handleClick = () => {
        const days = Math.floor((values.dateFinish.getTime() - values.dateStart.getTime())/ (1000 * 60 * 60 * 24) + 1)
        
    
        if (days >= 0) {
            getData({
                days: days,
                date: values.dateStart.toLocaleDateString('sv'),
                })
        } else {
            notify({
                message: `Дата "От" должна быть меньше Даты "До"`,
                position: 'top center',
            }, 'error', 3000);
        }

    }

    const getPartners = async () => {
        try {
            const result: any = await partnersDataSource.load()

            setPartners([
                {
                    id: 0,
                    name: "Все",
                    agentsId: [],
                    comment: ''
                },
                ...result
            ])
        } catch(err: any) {
            notify({
                message: `Упс... Произошла ошибка ${err.message}`,
                position: 'top center',
            }, 'error', 3000);
        } 
    }

    const getData = ({days, date} : {date: string, days: number}) => {
        
        values.days =  ~~(days)
        setIsLoading(true)
        dataSource.load({
            searchValue: { days, date,  withSubAgent}
        })
            .then((result: any) => {
                setInitialData(result)
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
                notify({
                    message: `Упс... Произошла ошибка ${error.message}`,
                    position: 'top center',
                  }, 'error', 3000);
            })
    }

    useEffect(() => {
        getData({
            days: (values.dateFinish.getTime() - values.dateStart.getTime())/ (1000 * 60 * 60 * 24) + 1,
            date: values.dateStart
                .toISOString()
                .split('T')[0],
        })
    }, [ withSubAgent])
    
    useEffect(() => {
        const staffId = userData?.staff.id
        let stoId

        if(checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR)){
            stoId = userData?.sto.id
        }

        let filterData = filterByStoOrStaff(
            initialData, 
            allStaff, 
            allSto, 
            staffId, 
            stoId, 
            userData?.roles
        )

        filterData = filterByPartner(
            filterData,
            partners.find(i => i.id === partnerValue)
        )
        
        setData(filterData)

        
    }, [allStaff, allSto, initialData, partnerValue])


    useEffect(() => {
        if(location.state){
          if(location.state.isBacked){

            editPopupOpen({
              id: location.state.popupParams.id, 
              type: location.state.popupParams.type,
              activeRecord: true,
              updateData: handleClick
            })
            if(location.state.periodValues){
                setValues(location.state.periodValues)
            }
          }
        }

        if (
            checkPermission(
                userData?.roles, 
                [Common.SubPermissions.ROLE_ADMIN, Common.SubPermissions.ROLE_BROKER_DIRECTOR]
            ) 
            && !isStatistics
        ){
            getPartners()
        }
      }, [])

  return (
    <div className='paper-tab'>
        <h1 className={cn('title')}>{title}</h1>
            <div className={cn('period')}>
                <div className={cn('period-item')}>
                    <SelectBox 
                        items={items} 
                        displayExpr="title"
                        valueExpr="value"
                        name='period'
                        width={'100%'}
    
                        validationError={[{
                            type: 'required', 
                            message: 'Введите номер телефона правильно'
                        }]}
                        value={values.period}
                        onValueChanged={handleChangeSelectBox} 
                        />
                </div>
                { openPeriod && (
                    <div className={cn('custom-period')}>
                            <div className={cn('period-item-date')}>
                                <DateBox 
                                    value={values.dateStart}
                                    type="date"
                                    useMaskBehavior={true}
                                    isValid={dateValid}
                                    labelMode='static'
                                    label='От'
                                    name='dateStart'
                                    onValueChanged={handleChangeDateBox}
                                    width='100%'
                                    />  
                            </div>
                            <div className={cn('period-item-date')}>
                                <DateBox 
                                    value={values.dateFinish}
                                    useMaskBehavior={true}
                                    type="date"
                                    isValid={dateValid}
                                    labelMode='static'
                                    label='До'
                                    name='dateFinish'
                                    onValueChanged={handleChangeDateBox}
                                    width='100%'
                                    />
                            </div>
                    </div>
                )}
                
                {(
                    checkPermission(
                        userData?.roles, 
                        [Common.SubPermissions.ROLE_ADMIN, Common.SubPermissions.ROLE_BROKER_DIRECTOR]
                    ) 
                    && !isStatistics
                ) && (
                    <div className={cn('partners')}>
                        <SelectBox 
                            dataSource={partnersDataSource}
                            labelMode='static'
                            label='Партнеры'
                            displayExpr={'name'}
                            valueExpr={'id'}
                            value={partnerValue}
                            onValueChange={(value: number) => setPartnerValue(value)}
                            />
                    </div>

                )}

                {userData?.roles.includes(Common.SubPermissions.ROLE_BROKER_DIRECTOR) && (
                        <CheckBox 
                            defaultValue={true} 
                            text="Все сотрудники"
                            value={allStaff}
                            onValueChanged={() => {setAllStaff(!allStaff)}}
                            />
                )}    
                {(userData?.roles.includes(Common.SubPermissions.ROLE_BROKER_DIRECTOR) && isFinantial) && (
                        <CheckBox 
                            text="C продажами СубАгентов"
                            value={withSubAgent}
                            onValueChanged={() => {
                                setWithSubAgent(!withSubAgent)
                            }}
                            />
                )}

                {(checkPermission(userData?.roles, Common.Permissions.ROLE_STO_ADMINISTRATOR) && (userData?.companyShortDescriptionList.length || 0) > 1) && (
                        <CheckBox 
                            text="Все СТО"
                            value={allSto}
                            onValueChanged={() => {
                                setAllSto(!allSto)
                            }}
                            />
                )}
                
                { openPeriod && (
                    <div className={cn('button-item')}>
                        <Button
                            text={startFromMonday && !activeRecord ? 'Рассчитать' :"Получить"}
                            type="default"
                            style={{alignSelf: 'flex-end'}}
                            stylingMode="contained"
                            onClick={handleClick}
                            />
                    </div>
                )}
                
            </div>
                    <Component 
                        title={exportTitle} 
                        data={data} 
                        date={values.dateStart} 
                        days={values.days} 
                        updateData={handleClick}
                        valuesPeriod={values}
                        allSto={allSto}
                        />
                    { isLoading && (
                        <div className={cn('loader-box')}>
                            <div className={cn('loader')}>
                                <LoadIndicator />
                            </div>
                        </div>
                    )}

            </div>
  )
})

export default ReportBase