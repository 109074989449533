import React, { FC, useEffect } from 'react'
import {
    DataGrid,
    Column,
    Sorting,
    Scrolling,
    Paging,
    FilterRow,
  } from 'devextreme-react/data-grid';
import { cellRenderIndex } from '~/utils/reportCellLink';
import { ActHistory } from '~/api/acts';


type Props = {
    defaultData: ActHistory[]
}

const ItemAccordionHistory: FC<Props> = ({defaultData = []}) => {

    useEffect(() => {
        window.scrollBy(0,3000);
    }, [])

  return (
    <div style={{position: 'relative'}}>
        <DataGrid
            dataSource={defaultData}
            allowColumnResizing={true}
            columnAutoWidth={true}
            showBorders
            hoverStateEnabled={true}
            showColumnLines
            allowColumnReordering={true}
            noDataText="Нет записей"
        >
            <Sorting mode="multiple" />
            <Scrolling mode="virtual" columnRenderingMode="virtual" showScrollbar="always"/>
            <Paging enabled={false} />
            <FilterRow visible={false}/>

            <Column
                caption={'№'}
                width={50}
                cellRender={(data:any) => cellRenderIndex({cellData: data})}
                alignment="left"
                allowEditing={false}
                name="number"
                />

            <Column
                caption="Дата, Время"
                dataType="datetime"
                dataField='date'
                alignment="center"
                allowEditing={false}
                defaultSortOrder='desc'
                />
            <Column
                dataField="action"
                caption='Действие'
                alignment="center"
                allowEditing={false}
                />
            <Column
                dataField="staffName"
                caption='Инициатор'
                alignment="center"
                allowEditing={false}
                />
            <Column
                dataField="comment"
                caption='Комментарий'
                alignment="center"
                allowEditing={false}
                />
        </DataGrid>
    </div>
  )
}

export default ItemAccordionHistory;