import React, { useCallback, useContext, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext, PopupContext } from '~/context';
import { Common } from '~/types';
import { checkPermission } from '~/utils/checkPermission';
import { CustomScheduler } from './atoms/CustomScheduler';


export const RecordsScheduler = memo(() => {
  const { t } = useTranslation('records');

  const { authState: {userData} } = useContext(AuthContext)

  const { 
    addPopupOpen,
  } = useContext(PopupContext);

  const { 
    editPopupOpen
  } = useContext(PopupContext);

  let selectedCell ={ 
    startDate: new Date(),
    endDate: new Date(),
    groups: {lineNumber: 0}
  }

  const onClickCell = useCallback((e: any) => {

    if (e.cellData.startDate.getTime() === selectedCell.startDate.getTime() && 
          e.cellData.endDate.getTime() === selectedCell.endDate.getTime() && 
            e.cellData.groups.lineNumber === selectedCell.groups.lineNumber && !checkPermission(userData?.roles, Common.Permissions.ROLE_STO_OPERATOR)
    ){
      const dataSource = e.component.getDataSource()
      addPopupOpen({
        cellData: e.cellData,
        updateData: () => dataSource.load(),
      })

    } else {
      selectedCell.startDate  = e.cellData.startDate
      selectedCell.endDate  = e.cellData.endDate
      selectedCell.groups.lineNumber = e.cellData.groups.lineNumber
    }
  
  },[])


  const appointmentFormOpeningHandle = useCallback((evt) => {
        evt.cancel = true
        const dataSource = evt.component.getDataSource()

        editPopupOpen({
          id: evt.appointmentData.dkId,
          type: Common.TypePopup.RECORD,
          activeRecord: true,
          updateData: () => dataSource.load()
        })

      },
      [t]
  );

  return (
    <CustomScheduler
      appointmentFormOpeningHandle={appointmentFormOpeningHandle} 
      onClickCell={onClickCell}
      />
  )
})
