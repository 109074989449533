import React from 'react';
import notify from 'devextreme/ui/notify';
import { Link } from 'react-router-dom';
import { Records } from '~/api';
import { PropsEditProps } from '~/context/PopupContext';
import { Common } from '~/types';
import { bemHelper } from '~/libs';

const cn = bemHelper('active-record');

type PropsRenderLink = {
    cellData: any, 
    editPopupOpen?: (values: PropsEditProps) => void,
    isOperator?: boolean,
    activeRecord?: boolean,
    updateData?: () => void,
    agentId?: number,
  }
  

  
  
  export const CellRenderLink = ({
    cellData, 
    editPopupOpen,
    isOperator = false,
    activeRecord = false,
    agentId,
    updateData
    }: PropsRenderLink) => {
  
    let type: Common.TypePopup
    let id: number
  
    const handleClick = () => {
      if (editPopupOpen){
        editPopupOpen({
          id, 
          type,
          activeRecord,
          updateData: updateData ? updateData : () => null
        })
      }
      
    }
  
    switch(cellData.column.name){
      case 'clientFio':
          if(cellData.data.individual){
            type = Common.TypePopup.CLIENT_INDIVIDUAL
          } else {
            type = Common.TypePopup.CLIENT_ENTITY
          } 
          id = cellData.data.clientId
          break
      case 'brand': 
        type = Common.TypePopup.VEHICLE
        id = cellData.data.vehicleId
        break
      case 'model': 
        type = Common.TypePopup.VEHICLE
        id = cellData.data.vehicleId
          break 
      case 'regNumber': 
        type = Common.TypePopup.VEHICLE
        id = cellData.data.vehicleId
          break 
      case 'number': 
  
        return (
          <span>
                {cellData.value}
          </span> 
        )
      case 'orderNumber': 
        type = Common.TypePopup.RECORD
        id = cellData.data.dkId
        break
      case 'agentName': 
        type = Common.TypePopup.AGENT
        id = cellData.data.agentId
        break
      case 'stoNameShort': 
        
        type = Common.TypePopup.STATION
        id = cellData.data.stoId
        
        if (isOperator){
          const href = `/app/station/${cellData.data.stoId}`
          return (
            <Link to={href} className={cn('link')}>
                {cellData.value}
            </Link> 
          )
        }
        break
      
      case 'stoAddress':
        type = Common.TypePopup.STATION
        id = cellData.data.stoId
        
        if (isOperator){
          const href = `/app/station/${cellData.data.stoId}`
          return (
            <Link
             to={href} className={cn('link')}>
                {cellData.value}
            </Link> 
          )
        }
        break
        
    }
  
  
    return (
            <a className={cn('link')} onClick={handleClick}>
                {cellData.value}
            </a>
            )
  }
  
  export const cellRenderIndex = ({
    cellData,
    editPopupOpen,
    activeRecord,
    updateData
  }: PropsRenderLink) => {
  
    cellData.value = cellData.row.loadIndex + 1
  
    return CellRenderLink({cellData, editPopupOpen, activeRecord, updateData})
  }
  