import CustomStore from 'devextreme/data/custom_store';
import { Addressing, AutoType } from '~/api';

export const dataSource = {

    autoTypes: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const data = await AutoType.controller.getAll()
        return data;
      },
    }),

    regions: new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          const data = await Addressing.controller.getRegions()
          return data;
        },
      }),

}

export type DataSourceEditStation = typeof dataSource