import React, { useContext, useRef, useEffect, memo, useMemo, useState, useCallback } from 'react';
import {
  Resource,
  Scheduler,
  View,
  Editing
} from 'devextreme-react/scheduler';
import dxScheduler from "devextreme/ui/scheduler";
import { EventInfo, ChangedOptionInfo, InitializedEventInfo } from "devextreme/events"
import { CellClickEvent } from 'devextreme/ui/scheduler'


import AppointmentTooltip from './atoms/AppointmentTooltip'
import { useTranslation } from 'react-i18next';
import { AuthContext, PopupContext } from '~/context';
import Appointment from './atoms/Appointment';
import { dkDataSource, dkStatusDataSource } from '~/custom-data-sources';
import { AddTimeBlockPopup, AddTimeBlockPopupManagerRef } from './atoms/AddTimeBlockPopup';
import { DataCell } from './atoms/DataCell';
import { DateCell } from './atoms/DateCell';
import { useWeekHours } from './hooks';
import notify from 'devextreme/ui/notify';


type Props = {
  appointmentFormOpeningHandle: (e: any) => void,
  onClickCell: (a: any) => void
}


let currentDate = new Date();
const coeff = 1000 * 60 * 10;
currentDate = new Date(Math.round(currentDate.getTime() / coeff) * coeff)

export const CustomScheduler: React.FC<Props> = memo(({ appointmentFormOpeningHandle, onClickCell }) => {

  const { t } = useTranslation('records');

  const { authState } = useContext(AuthContext);
  const {
    addPopupUpdateFunc
  } = useContext(PopupContext);

  const addTimeBlockPopupRef = useRef<AddTimeBlockPopupManagerRef>(null)

  const {checkIsWorkingTime, getMaxHour, getMinHour } = useWeekHours()

  const lines = useMemo(() => {
    const tsLimit = authState.userData?.sto ? authState.userData?.sto.tsLimit : 4

    const arr = []
    for (let i = 1; i <= tsLimit; i++) {
      arr.push({
        id: i,
        title: `Линия ${i}`,
      })
    }

    return arr
  }, [])

  const initAddPopupUpdateFunc = (scheduler: dxScheduler) => {
    const dataSource = scheduler.getDataSource()
    addPopupUpdateFunc(() => dataSource?.load())
  }

  const initAddButtonToolbar = (scheduler: dxScheduler) => {
    const toolbar = scheduler.option('toolbar') as any[]

    

    if (toolbar.length == 2) {
      scheduler.option('toolbar', [
        ...toolbar,
        {
          widget: "dxButton",
          location: "after",
          options: {
            text: 'Добавление блока от записи',
            onClick: () => {
              const selectedDate = scheduler.option('currentDate') as Date
              addTimeBlockPopupRef.current?.open(selectedDate)
            },
          }

        }
      ])
    }
  }


  const handleInit = (e: InitializedEventInfo<dxScheduler>) => {
    if (e.component) {
      initAddButtonToolbar(e.component)
      // initAddPopupUpdateFunc(e.component)
    }
  }

  const renderDateCell = useCallback((itemData) => (
    <DateCell itemData={itemData} />
  ), []);


  const handleCellClick = (e: CellClickEvent) => {
    if(checkIsWorkingTime(e.cellData.startDate, e.cellData.endDate)){
      onClickCell(e)
    } else {
        notify({
          message: `Нельзя добавить записи в нерабочее время`,
          position: 'top center',
        }, 'error', 3000);
    }
  }

  return (
    <div>
      <Scheduler
        id="scheduler"
        style={{ position: 'absolute' }}
        cellDuration={10}
        shadeUntilCurrentTime={true}
        onInitialized={handleInit}
        crossScrollingEnabled
        showAllDayPanel={false}
        firstDayOfWeek={1}
        dataSource={dkDataSource}
        defaultCurrentDate={currentDate}
        groups={['stoId', 'lineNumber']}
        height="100%"
        width="100%"
        onAppointmentFormOpening={appointmentFormOpeningHandle}
        remoteFiltering
        dateCellRender={renderDateCell}
        dataCellComponent={DataCell}
        textExpr="textExpr"
        appointmentComponent={Appointment}
        appointmentTooltipComponent={AppointmentTooltip}
        startDateExpr="dateReceiptStart"
        endDateExpr="dateReceiptFinish"
        onCellClick={handleCellClick}
        startDayHour={getMinHour()}
        endDayHour={getMaxHour()}

      >
        <Resource
          allowMultiple="false"
          dataSource={lines}
          displayExpr="title"
          fieldExpr="lineNumber"
          label={t('lineNumber')}
        />

        <Resource
          allowMultiple="false"
          dataSource={dkStatusDataSource}
          fieldExpr="approvedStatusId"
          useColorAsDefault={true}
        />

        <View
          type="week"
          cellDuration={30}
          key="hhh"
          name={'Неделя 30 минут'}
        />
        <View
          type="week"
          cellDuration={10}
          key="hhh"
          name={'Неделя 10 минут'}
        />
        <View
          cellDuration={30}
          type="day"
          key="hhh"
          name={'День 30 минут'}
        />
        <View
          cellDuration={10}
          type="day"
          key="hhh"
          name={'День 10 минут'}
        />
        <Editing allowDeleting={false} allowAdding={false} allowDragging={false} allowUpdating={true} allowResizing={false} />
      </Scheduler>

      <AddTimeBlockPopup
        ref={addTimeBlockPopupRef}
      />
    </div>
  )
})