import React, { Children, FC, ReactNode } from "react"

import './styles.scss'
import { bemHelper } from "~/libs"
import { Button } from "devextreme-react"
import { useHistory, useLocation } from "react-router"

type Props = {
    children: ReactNode,
    title: string
}

export const PageBase: FC<Props> = ({
    children,
    title
}) => {
    const cn = bemHelper('page-base')

    const location = useLocation();
    const history = useHistory()

    const handleBack = () => {
        history.push(location.state.pathname ,{
            ...location.state?.backOptions
        })
    }


    return (
        <div className={'paper-tab ' + cn()}>
            <Button
                width={30}
                icon="back"
                className={cn('button-back')}
                visible={Boolean(location.state?.isBack)}
                type="normal"
                onClick={handleBack}
            />
            <h1 className={cn('title')}>{title}</h1>
            {children}
        </div>
    )
}