import React from "react"
import { ActsBase } from "~/ui/atoms/ActsBase"
import { ActsTable } from "../atoms/ActsTable/ActsTable"
import { createCustomStore } from "~/custom-data-sources"
import { Acts } from "~/api"
import CustomStore from 'devextreme/data/custom_store';
import { ActsQueryData } from "~/api/acts"



export const AllActs = () => {

    const dataSource = new CustomStore({
        key: 'aktId',
        load: async ({searchValue}) => {
            const query: ActsQueryData = searchValue
            return await Acts.controller.getActs(query)
        },
    })

    return (
        <ActsBase
            title='Все акты'
            Table={ActsTable}
            dataSource={dataSource}
        />
    )
}